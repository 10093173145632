import { TimeInterval } from 'root-constants'
import supplesImage1 from 'assets/images/supplies/supplies-image-1.jpg'
import supplesImage2 from 'assets/images/supplies/supplies-image-2.jpg'
import supplesImage3 from 'assets/images/supplies/supplies-image-3.jpg'
import supplesImage4 from 'assets/images/supplies/supplies-image-4.jpg'
import supplesImage5 from 'assets/images/supplies/supplies-image-5.jpg'
import supplesImage6 from 'assets/images/supplies/supplies-image-6.jpg'
import supplesImage7 from 'assets/images/supplies/supplies-image-7.jpg'
import supplesImage8 from 'assets/images/supplies/supplies-image-8.jpg'
import supplesImage9 from 'assets/images/supplies/supplies-image-9.jpg'
import supplesImage10 from 'assets/images/supplies/supplies-image-10.jpg'
import supplesImage11 from 'assets/images/supplies/supplies-image-11.jpg'
import supplesImage12 from 'assets/images/supplies/supplies-image-12.jpg'
import supplesImage13 from 'assets/images/supplies/supplies-image-13.jpg'
import supplesImage14 from 'assets/images/supplies/supplies-image-14.jpg'
import supplesImage15 from 'assets/images/supplies/supplies-image-15.jpg'
import supplesImage16 from 'assets/images/supplies/supplies-image-16.jpg'
import supplesImage17 from 'assets/images/supplies/supplies-image-17.jpg'
import supplesImage18 from 'assets/images/supplies/supplies-image-18.jpg'
import rate5Stars from 'assets/images/sprite/supply-5-stars.svg'
import rateStars from 'assets/images/sprite/supply-stars.svg'
import {
  AGE_OPTIONS_VALUES,
  BEHAVIOR_ISSUES_OPTIONS_VALUES,
  GOALS_OPTIONS_VALUES,
  SEX_OPTIONS,
  TIME_OPTIONS_VALUES,
} from '../../constants/options-constants'

export const SUBSCRIPTION_SECTION_IDS = {
  planBlock: 'plan-block',
  chooseMyPlan: 'choose-my-plan',
  chooseMyPlanSecond: 'choose-my-plan-second',
  firstSection: 'first_section',
  dogsCard: 'dogs_card',
  obtaining: 'whats_inside',
  cynologists: 'program_by_cynologists',
  guarantee: 'money_back',
  comments: 'users_love_woofz',
  getMyPlanButton: 'get-my-plan-button',
  startMyPlanButton: 'start-my-plan-button',
  planBlockButton: 'plan-block-button',
  interestingFact: 'interesting-fact',
  ourMission: 'our-mission',
}

export const enum PLAN_TAGS {
  CANCEL = 'cancel',
  NO_TAX = 'no_tax',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  TUTORIAL = 'tutorial',
  SUBSCRIPTIONS = 'subscriptions',
}

export const BREED_OPTIONS = {
  MIXED_BREED: 'Mixed Breed',
  EXOTIC: 'Exotic Shorthair',
  RAGDOLL: 'Ragdoll',
  PERSIAN: 'Persian',
  ABYSSINIAN: 'Abyssinian',
  MAINE_COON: 'Maine Coon',
  SPHYNX: 'Sphynx',
  DEVON_REX: 'Devon Rex',
  SCOTTISH: 'Scottish Fold',
  AMERICAN_SHORTHAIR: 'American Shorthair',
  BRITISH_SHORTHAIR: 'British Shorthair',
  SIAMESE: 'Siamese',
  OTHER: 'Other',
}

export const enum AnswerKey {
  CAT_BREED = 'cat_breed',
  GOALS_QUESTION = 'cat_main_goal',
  BEHAVIOUR_ISSUES = 'cat_behavior_issues',
  AGE = 'cat_age',
  TIME = 'cat_time',
  SEX = 'cat_sex',
}

export const BREED_OPTIONS_VALUES = {
  MIXED_BREED: 'mixedBreed',
  EXOTIC: 'exoticShorthair',
  RAGDOLL: 'ragdoll',
  PERSIAN: 'persian',
  ABYSSINIAN: 'abyssinian',
  MAINE_COON: 'maineCoon',
  SPHYNX: 'sphynx',
  DEVON_REX: 'devonRex',
  SCOTTISH: 'scottishFold',
  AMERICAN_SHORTHAIR: 'americanShorthair',
  BRITISH_SHORTHAIR: 'britishShorthair',
  SIAMESE: 'siamese',
}

export const USER_ANSWER_TO_MARKUP_MAP: { [key: string]: string } = {
  [TIME_OPTIONS_VALUES.TEN_FIFTEEN_MINUTES]: '10-15 minutes',
  [TIME_OPTIONS_VALUES.FIFTEEN_TWENTY_MINUTES]: '15-20 minutes',
  [TIME_OPTIONS_VALUES.TWENTY_THIRTY_MINUTES]: '20-30 minutes',
  [TIME_OPTIONS_VALUES.MORE_THAN_THIRTY_MINUTES]: 'more than 30 minutes',
  [GOALS_OPTIONS_VALUES.BEHAVIOUR_PROBLEMS]: 'Solve behavior problems',
  [GOALS_OPTIONS_VALUES.CAT_PSYCHOLOGY]: 'Understand cat psychology',
  [GOALS_OPTIONS_VALUES.NEW_TRICKS]: 'Learn new tricks and cues',
  [GOALS_OPTIONS_VALUES.OTHER]: 'Other',
  [GOALS_OPTIONS_VALUES.HOME_ADAPTATION]: 'Make home adaptation easier',
  [GOALS_OPTIONS_VALUES.SEPARATION_ANXIETY]: 'Solve separation anxiety',
  [GOALS_OPTIONS_VALUES.BECOME_BEST_PARTNER]: 'Become the best cat parent ever',
  [BREED_OPTIONS_VALUES.MIXED_BREED]: 'Mixed Breed',
  [BREED_OPTIONS_VALUES.EXOTIC]: 'Exotic Shorthair',
  [BREED_OPTIONS_VALUES.RAGDOLL]: 'Ragdoll',
  [BREED_OPTIONS_VALUES.PERSIAN]: 'Persian',
  [BREED_OPTIONS_VALUES.ABYSSINIAN]: 'Abyssinian',
  [BREED_OPTIONS_VALUES.MAINE_COON]: 'Maine Coon',
  [BREED_OPTIONS_VALUES.SPHYNX]: 'Sphynx',
  [BREED_OPTIONS_VALUES.DEVON_REX]: 'Devon Rex',
  [BREED_OPTIONS_VALUES.SCOTTISH]: 'Scottish Fold',
  [BREED_OPTIONS_VALUES.AMERICAN_SHORTHAIR]: 'American Shorthair',
  [BREED_OPTIONS_VALUES.BRITISH_SHORTHAIR]: 'British Shorthair',
  [BREED_OPTIONS_VALUES.SIAMESE]: 'Siamese',
  [AGE_OPTIONS_VALUES.KITTEN]: 'Younger then 6 month',
  [AGE_OPTIONS_VALUES.ADOLESCENT]: '6-12 month',
  [AGE_OPTIONS_VALUES.ADULT]: '1-7 years',
  [AGE_OPTIONS_VALUES.SENIOR]: 'More than 7 years',
  [AGE_OPTIONS_VALUES.KITTEN_GROUP]: 'Kitten',
  [AGE_OPTIONS_VALUES.ADOLESCENT_GROUP]: 'Young Adult',
  [AGE_OPTIONS_VALUES.ADULT_GROUP]: 'Mature Adult',
  [AGE_OPTIONS_VALUES.SENIOR_GROUP]: 'Senior Cat',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.NO_ISSUES]: 'No behavioral issues',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.SCRATCHING]: 'Scratching furniture',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.LITTER_BOX_ISSUES]: 'Litter box issues',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.AGGRESSION]: 'Aggression',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.EXCESSIVE_MEOWING]: 'Excessive meowing',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.NIGHTTIME_ACTIVITY]: 'Nighttime activity',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.SEPARATION_ANXIETY]: 'Separation anxiety',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.SOCIALIZATION]: 'Socialization issues',
  [BEHAVIOR_ISSUES_OPTIONS_VALUES.STEALING_FOOD]: 'Stealing food',
  [SEX_OPTIONS.GIRL]: 'girl',
  [SEX_OPTIONS.BOY]: 'boy',
}

export const STICKY_BUTTON = 'sticky-button'
export const STICKY_HEADER = 'sticky-header'

export const PERIODS_MAP = {
  1: 'monthly',
  3: 'quarterly',
  6: 'halfyearly',
}

export const DAYS_IN_PERIOD: { [key: string]: number } = {
  [TimeInterval.WEEK]: 7,
  [TimeInterval.MONTH]: 30,
}

export const enum SubscriptionDurationDays {
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const PeriodCountByDays = {
  [SubscriptionDurationDays.ONE_MONTH]: 1,
  [SubscriptionDurationDays.THREE_MONTHS]: 3,
  [SubscriptionDurationDays.SIX_MONTHS]: 6,
}

export const availableSupplies = [
  {
    blockTitle: 'Trees, scratchers, beds & more kitty comforts',
    background: '#7879f1',
    data: [
      {
        title: 'PetAg PetLac Kitten Milk',
        subtitle: 'Replacement Powder for Kittens, 10.5-oz can',
        rate: '4.5',
        rateCount: '(71)',
        rateImage: rateStars,
        image: supplesImage1,
        product: 'lowest_price_item',
        isBestseller: true,
        isDeal: false,
      },
      {
        title: 'Frisco 32-in Real Carpet',
        subtitle: 'Solid Wood Cat Tree with Toy',
        rate: '4.7',
        rateCount: '(208)',
        rateImage: rateStars,
        image: supplesImage2,
        product: 'high_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'Frisco Self-Warming Bolster',
        subtitle: 'Kitten Bed, Gray',
        rate: '5.0',
        rateCount: '(22)',
        rateImage: rate5Stars,
        image: supplesImage3,
        product: 'middles_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'Frisco Senior and Kitten Cat',
        subtitle: 'Litter Box, 24-in',
        rate: '4.7',
        rateCount: '(168)',
        rateImage: rateStars,
        image: supplesImage4,
        product: 'low_price_item',
        isBestseller: false,
        isDeal: true,
      },
      {
        title: 'Frisco Collapsible Wire Cat',
        subtitle: 'Cage Playpen',
        rate: '4.5',
        rateCount: '(403)',
        rateImage: rateStars,
        image: supplesImage5,
        product: 'middle_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'Carlson Pet Products Maxi',
        subtitle: 'Extra Tall Walk-Thru Dog Gate with Pet Door',
        rate: '5.0',
        rateCount: '(12)',
        rateImage: rate5Stars,
        image: supplesImage6,
        product: 'highest_price_item',
        isBestseller: false,
        isDeal: false,
      },
    ],
  },
  {
    blockTitle: 'Food, treats, bowls and key kitten necessities',
    background: '#abba00',
    data: [
      {
        title: 'Goody Box Kitten Toys & Treats',
        subtitle: '',
        rate: '4.9',
        rateCount: '(301)',
        rateImage: rate5Stars,
        image: supplesImage7,
        product: 'high_price_item',
        isBestseller: false,
        isDeal: true,
      },
      {
        title: 'Purina Pro Plan Kitten',
        subtitle: 'Shredded Blend Chicken & Rice Formula Dry Cat Food',
        rate: '4.6',
        rateCount: '(305)',
        rateImage: rateStars,
        image: supplesImage8,
        product: 'lowest_price_item',
        isBestseller: true,
        isDeal: false,
      },
      {
        title: 'Gamma2 Vittles Vault Pet',
        subtitle: 'Food Storage, 50-lb',
        rate: '4.9',
        rateCount: '(22)',
        rateImage: rate5Stars,
        image: supplesImage9,
        product: 'middle_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'PATPET Timed Automatic',
        subtitle: 'Dog & Cat Feeder with 10s Voice Recorder, 4-L',
        rate: '5.0',
        rateCount: '(10)',
        rateImage: rate5Stars,
        image: supplesImage10,
        product: 'highest_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'Advantage II Flea Spot',
        subtitle: 'Treatment for Cats, 2-5 lbs, 2 Doses (2-mos. supply)',
        rate: '4.3',
        rateCount: '(471)',
        rateImage: rateStars,
        image: supplesImage11,
        product: 'low_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'PetNF Transparent Cat',
        subtitle: 'Water Drinking Fountain',
        rate: '4.3',
        rateCount: '(237)',
        rateImage: rateStars,
        image: supplesImage12,
        product: 'middles_price_item',
        isBestseller: false,
        isDeal: false,
      },
    ],
  },
  {
    blockTitle: 'Toys, collars, carriers, grooming gear & more',
    background: '#ba00b2',
    data: [
      {
        title: 'SunGrow Ferret & Bunny',
        subtitle:
          'Collapsible Play Tunnel, Indoor Rabbit & Small Pet Crinkle Tube Hideout Toy',
        rate: '4.3',
        rateCount: '(319)',
        rateImage: rateStars,
        image: supplesImage13,
        product: 'lowest_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'Bueteka Deodorizer Odor',
        subtitle: 'Eliminator Cat Litter Box',
        rate: '4.5',
        rateCount: '(184)',
        rateImage: rateStars,
        image: supplesImage14,
        product: 'highest_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'Buckle-Down Personalized',
        subtitle: 'Breakaway Cat Collar with Bell, Tie Dye',
        rate: '4.3',
        rateCount: '(5)',
        rateImage: rateStars,
        image: supplesImage15,
        product: 'low_price_item',
        isBestseller: false,
        isDeal: false,
      },
      {
        title: 'Espree Puppy & Kitten',
        subtitle: 'Shampoo, 1-gallon',
        rate: '4.8',
        rateCount: '(1,312)',
        rateImage: rate5Stars,
        image: supplesImage16,
        product: 'middle_price_item',
        isBestseller: true,
        isDeal: false,
      },
      {
        title: 'PetLuv Premium Soft-Sided',
        subtitle: 'Cat & Dog Carrier',
        rate: '4.7',
        rateCount: '(100)',
        rateImage: rateStars,
        image: supplesImage17,
        product: 'high_price_item',
        isBestseller: false,
        isDeal: true,
      },
      {
        title: 'Sentry Stop That! Noise &',
        subtitle: 'Pheromone Cat Spray, 1-fl oz',
        rate: '4.1',
        rateCount: '(19)',
        rateImage: rateStars,
        image: supplesImage18,
        product: 'middles_price_item',
        isBestseller: false,
        isDeal: false,
      },
    ],
  },
]
