import { AppWithInAppBrowser } from 'root-constants'

export const getAppNameFromInAppBrowser = (): AppWithInAppBrowser | null => {
  const { userAgent } = window.navigator

  if (userAgent.includes('FBAN') || userAgent.includes('FBAV')) {
    return AppWithInAppBrowser.FACEBOOK
  }

  if (userAgent.includes('Instagram')) {
    return AppWithInAppBrowser.INSTAGRAM
  }

  return null
}
