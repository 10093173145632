import React from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { COMMENTS_V2 } from 'pages/prepare-flow/components/constants'
import { StyledComments as S } from './Comments.styles'
import { Comment } from './comment/Comment'

export const Comments: React.FC = () => (
  <S.CommentsWrapper id={SUBSCRIPTION_SECTION_IDS.comments}>
    <S.Wrapper>
      <S.Header>
        <S.Title>
          Get inspired
          <br />
          by <strong>our users</strong>
        </S.Title>
        <S.HeaderImage />
      </S.Header>
      <Swiper
        slidesPerView="auto"
        spaceBetween={8}
        centeredSlides
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
        loop
      >
        {COMMENTS_V2.map(({ title, avatar, text, data }) => (
          <SwiperSlide key={title}>
            <Comment image={avatar} title={title} data={data}>
              {text}
            </Comment>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  </S.CommentsWrapper>
)
