import paymentLockIcon from 'assets/images/payment-lock.svg'
import { CloseButton } from 'components/buttons/CloseButton'
import { SvgImage } from 'components/SvgImage'
import { Color } from 'root-constants'
import styled, { css } from 'styled-components'
import checkIcon from 'assets/images/check-icon-white.svg'
import { BackButton } from 'components/buttons/back-button/BackButton'

const commonTitleWrapperStyles = css`
  width: 100%;
  padding-top: 25px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
`
const commonAdditionalTextStyles = css`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(74, 53, 50, 0.4);
  text-align: center;
`

export const StyledPaymentFlows = {
  TitleWrapper: styled.div`
    ${commonTitleWrapperStyles};
    flex-direction: column;
  `,
  TitleWrapper2: styled.div`
    ${commonTitleWrapperStyles};
    justify-content: space-between;
  `,
  Stepper: styled(SvgImage)`
    height: 40px;
    margin-bottom: 16px;
  `,
  TabsWrapper: styled.div`
    display: flex;
    gap: 10px;
    padding: 8px 0 16px;
  `,
  PaymentButton: styled.button`
    padding: 0;
    margin: 0;
    border: none;
    width: 139px;
    background-color: #fff;
    height: 56px;
  `,
  PaymentButton2: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 62px;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    padding-left: 62px;

    &::before {
      content: '';
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #b0a099;
    }

    input:checked + &::before {
      background-color: ${Color.MEDIUM_GREEN};
      border: none;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  `,
  CloseButton: styled(CloseButton)`
    position: absolute;
    top: 0;
    left: 0;
  `,
  BackButton: styled(BackButton)`
    position: absolute;
    top: 0;
    left: 0;
  `,
  BackButton2: styled(BackButton)`
    position: absolute;
    top: 24px;
    left: 0;
  `,
  CloseButton2: styled(CloseButton)`
    position: absolute;
    right: 0;
    bottom: 0;
  `,
  Title: styled.h3`
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    text-align: center;
  `,
  Divider: styled.div`
    width: inherit;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    overflow: hidden;
  `,
  LineDivider: styled.div`
    width: 100%;
    border-bottom: 1px dotted #d9d9d9;
  `,
  DividerText: styled.div`
    position: relative;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    padding: 0 12px;
    color: rgba(74, 53, 50, 0.4);

    ::before,
    ::after {
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: 160px;
      top: 8px;
      background-color: rgba(74, 53, 50, 0.1);
    }

    ::before {
      right: 100%;
    }

    ::after {
      left: 100%;
    }
  `,
  PaymentTitle: styled.div`
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  `,
  PaymentTitleProtection: styled.div`
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.4);

    &:before {
      height: 16px;
      margin-right: 3px;
      content: url(${paymentLockIcon});
    }
  `,
  Form: styled.div`
    position: relative;
  `,
  PaymentServiceImage: styled.img`
    max-width: 256px;
    height: 28px;
    object-fit: contain;
    margin: 24px 0 20px;
  `,
  Guarantee: styled.div`
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: ${Color.PRIMARY_TEXT};
    margin-bottom: 24px;
  `,
  Disclaimer: styled.div`
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: rgb(176, 160, 153);
    margin-bottom: 24px;

    a {
      color: inherit;
    }
  `,
  PaymentMethodWrapper: styled.div`
    padding: 0 16px 16px;
  `,
  PaymentMethodWrapperVariant2: styled.div`
    padding: 0 0 16px;
    width: 100%;
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddccc5;
    border-radius: 8px;

    & > label {
      margin-bottom: 0 !important;
    }
  `,
  AdditionalText: styled.span`
    ${commonAdditionalTextStyles};
    margin-top: -10px;
  `,
  AdditionalTextVariant2: styled.span`
    ${commonAdditionalTextStyles};
    margin-top: 10px;
  `,
  CustomPaymentButton: styled.button`
    height: 50px;
    width: 100%;
    background-color: ${Color.BLACK};
    border-radius: 40px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
