import React from 'react'
import { StyledComment as S } from './Comment.styles'

interface ICpmmentProps {
  title: string
  data: string
  image: string
  children: React.ReactNode
}

export const Comment: React.FC<React.PropsWithChildren<ICpmmentProps>> = ({
  image,
  title,
  data,
  children,
}) => (
  <S.CommentWrapper>
    <S.HeaderWrapper>
      <S.FlagWrapper>
        <S.Avatar src={image} />
      </S.FlagWrapper>
      <S.TitleWrapper>
        <S.Title>{title}</S.Title>
        <S.DetailsWrapper>
          <S.Date>{data}</S.Date>
          <S.Rating />
        </S.DetailsWrapper>
      </S.TitleWrapper>
    </S.HeaderWrapper>
    <S.Text>{children}</S.Text>
  </S.CommentWrapper>
)
