import React from 'react'
import lockImg from 'assets/images/sprite/lock.svg'
import { ISvg } from 'components/SvgImage'
import { StyledComponents as S } from './styles'

export const PrivacyNote: React.FC = () => (
  <S.Note>
    <S.LockImg svg={lockImg as ISvg} />
    <S.Description>
      We safeguard your privacy and are committed to protecting your personal
      data. We never send spam emails, only relevant information.
    </S.Description>
  </S.Note>
)
