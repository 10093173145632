import { FlowRouter } from 'components/FlowRouter'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'components/spinner/Spinner'
import {
  selectConfig,
  selectCurrentVariant,
} from 'root-redux/common/common-selects'
import { selectUserStatus, selectUUID } from 'root-redux/user/user-selects'
import { getUserStatusAction } from 'root-redux/user/user-actions'
import { TAppDispatch } from 'root-redux/store'
import { useLanguage } from 'hooks/useLanguage'
import { useSetVariant } from 'hooks/useSetVariant'
import { useInitUserUuid } from 'hooks/useInitUserUuid'
import { PrivacyLinksRouter } from 'components/PrivacyLinksRouter'

const FunnelFlow: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig)
  const isPolicyLinks =
    window.location.href.includes('privacy_policy') ||
    window.location.href.includes('terms_of_use')

  useSetVariant()
  useLanguage()
  useInitUserUuid()

  useEffect(() => {
    if (uuid) {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  if (isPolicyLinks) {
    return <PrivacyLinksRouter />
  }

  return userStatus && variant && config ? (
    <FlowRouter userStatus={userStatus} variant={variant} />
  ) : (
    <Spinner />
  )
}

// eslint-disable-next-line import/no-default-export
export default FunnelFlow
