import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { IAction, TAppDispatchThunk } from 'models/store.model'
import { FirebaseSignUpErrorCode, ValidationText } from 'root-constants'
import i18n from 'i18next'
import { startFetching, stopFetching } from 'root-redux/common/common-actions'

const MODULE_NAME = 'ACCOUNT'
const REGISTER_WITH_EMAIL_FIREBASE = `${MODULE_NAME}/REGISTER_WITH_EMAIL_FIREBASE`

export const SET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/SET_EMAIL_ERROR_MESSAGE`
export const RESET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/RESET_EMAIL_ERROR_MESSAGE`

export const SET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/SET_PASSWORD_ERROR_MESSAGE`
export const RESET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/RESET_PASSWORD_ERROR_MESSAGE`

export const resetEmailErrorMessageAction = (): IAction<never> => ({
  type: RESET_EMAIL_ERROR_MESSAGE,
})

export const setEmailErrorMessageAction = (
  message: ValidationText,
): IAction<ValidationText> => ({
  type: SET_EMAIL_ERROR_MESSAGE,
  payload: message,
})

export const resetPasswordErrorMessageAction = (): IAction<never> => ({
  type: RESET_PASSWORD_ERROR_MESSAGE,
})

export const setPasswordErrorMessageAction = (
  message: ValidationText,
): IAction<ValidationText> => ({
  type: SET_PASSWORD_ERROR_MESSAGE,
  payload: message,
})

export function registerWithEmailFirebaseAction(
  email: string,
  password: string,
): TAppDispatchThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(REGISTER_WITH_EMAIL_FIREBASE))

    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password)
    } catch (error: any) {
      if (error.code === FirebaseSignUpErrorCode.EMAIL_EXISTS) {
        dispatch(setEmailErrorMessageAction(ValidationText.EMAIL_EXISTS))
      }
    }

    dispatch(stopFetching(REGISTER_WITH_EMAIL_FIREBASE))
  }
}
