import styled, { css } from 'styled-components'
import { Color } from 'root-constants'

type TDescriptionProps = {
  isInProgress?: boolean
  isFinished?: boolean
}

const progressValueStyles = css`
  background-color: currentColor;
  transition: all 0.2s ease-out;
`

export const StyledProgressBar = {
  Wrapper: styled.div`
    margin-bottom: 0;
    width: 266px;
  `,
  ProgressWrapper: styled.div`
    margin-bottom: 24px;
    overflow: hidden;
    height: 8px;
    border-radius: 10px;
  `,
  Description: styled.div<TDescriptionProps>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 2px;
    font-weight: ${({ isInProgress }) => (isInProgress ? 700 : 400)};
    font-weight: ${({ isFinished }) => isFinished && 700};
    color: ${({ isInProgress }) =>
      isInProgress ? Color.PRIMARY_TEXT : Color.BROWNISH_GRAY};
    color: ${({ isFinished }) => isFinished && Color.PRIMARY_TEXT};
    transition: 0.25s;
  `,
  Text: styled.div`
    text-align: left;
  `,
  Value: styled.span`
    min-width: 40px;
    flex-shrink: 0;
    text-align: right;
  `,
  CheckIcon: styled.img`
    width: 16px;
    height: 16px;
  `,
  Progress: styled.progress`
    appearance: none;
    border: none;
    display: block;
    width: 100%;
    min-width: 266px;
    height: 100%;
    background: rgba(176, 160, 153, 0.2);
    color: ${Color.MEDIUM_GREEN};

    &::-webkit-progress-bar {
      background: rgba(176, 160, 153, 0.2);
      color: ${Color.MEDIUM_GREEN};
    }

    &::-webkit-progress-value {
      ${progressValueStyles}
    }

    &::-moz-progress-bar {
      ${progressValueStyles}
    }

    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
