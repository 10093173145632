import { useCallback, useEffect, useState } from 'react'
import { EMAIL_REGEXP, ValidationText } from 'root-constants'
import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'
import { useSelector } from 'react-redux'
import { selectEmail } from 'root-redux/user/user-selects'

export const useEmailInputField = (
  errorMessage: ValidationText | null = null,
  resetErrorMessage: () => void = () => undefined,
  validationText: ValidationText = ValidationText.INVALID_EMAIL,
): TInputFieldHook => {
  const userEmail = useSelector(selectEmail)
  const [email, setEmail] = useState<IInputFieldState>({
    value: userEmail || '',
    isValid: true,
    validationText,
  })

  const validateEmail = useCallback(
    (emailValue: string) => {
      if (!EMAIL_REGEXP.test(emailValue) && emailValue !== '') {
        setEmail((prevState) => ({
          ...prevState,
          value: emailValue,
          isValid: false,
          validationText,
        }))
        return
      }

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
        isValid: true,
        validationText: '',
      }))
    },
    [validationText],
  )

  useEffect(() => {
    if (errorMessage) {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: errorMessage,
      }))

      resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [email, setEmail, validateEmail]
}
