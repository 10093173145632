import discount from 'assets/images/sprite/discount-label.svg'
import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import React from 'react'
import { Trans } from 'react-i18next'
import { CURRENT_YEAR, PLAN_TYPES } from 'root-constants'
import { ScreenName } from 'services/eventLogger-service'
import { CatCard } from 'modules/subscriptions/components/cat-card/CatCard'
import { DidYouKnowBlock } from 'modules/subscriptions/components/did-you-know/DidYouKnowPage'
import { ObtainingList } from 'modules/subscriptions/components/obtaining-list/ObtainingList'
import { EducationJourney } from 'modules/subscriptions/components/education-journey/EducationalJourney'
import { Comments } from 'modules/subscriptions/components/comments/Comments'
import { Guarantee } from 'modules/subscriptions/components/guarantee/Guarantee'
import { StickyBanner } from 'modules/subscriptions/components/sticky-banner/StickyBanner'
import { SelectPlanBlock } from 'modules/subscriptions/components/plan-blocks/SelectPlanBlock'
import { PlanItem } from 'modules/subscriptions/components/plan-blocks/PlanItem'
import { StyledSubscriptions as S } from './Subscriptions.styles'
import { PageId } from '../../../constants/pages'

const CatSubscriptionsPage: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
  isNonPayerFlow,
}) => (
  <S.Wrapper>
    <S.Content>
      <CatCard />
      <DidYouKnowBlock />
      <ObtainingList />
      <EducationJourney />
      <StickyBanner />
      <SelectPlanBlock
        onButtonClick={onButtonClick}
        onSelect={onSubscriptionSelect}
        SelectPlanItem={PlanItem}
        availableSubscriptions={availableSubscriptions}
        isNonPayerFlow={isNonPayerFlow}
      />
      <Guarantee />
      <Comments />
      <S.FinalBlockWrapper>
        <S.PlanButtonWrapper>
          <S.DiscountImage svg={discount} />
          <GreenArrowButton onClick={onButtonClick} text="Start My Plan" />
        </S.PlanButtonWrapper>
        <Trans
          values={{ year: CURRENT_YEAR }}
          i18nKey="footer.allRightsReserved"
        />
      </S.FinalBlockWrapper>
    </S.Content>
  </S.Wrapper>
)

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.SALES}
    planType={PLAN_TYPES.PURCHASE}
  >
    {(props: ISubscriptionsPageProps) => <CatSubscriptionsPage {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.CAT_SUBSCRIPTIONS

export const Subscriptions = Component
