import lastOfferImg from 'assets/images/cat-65-offer.jpg'
import close from 'assets/images/sprite/close-icon.svg'
import arrow from 'assets/images/sprite/dark-arrow.svg'
import { FlowContext } from 'components/FlowContext'
import { SvgImage } from 'components/SvgImage'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useContext } from 'react'
import { PageId } from 'src/constants/pages'
import { StyledLastChanceOfferPage as S } from './LastChanceOfferPage.styles'
import { Trans, useTranslation } from 'react-i18next'

const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const { onNextStep } = useContext(FlowContext)

  return (
    <S.UrgeWrapper2>
      <S.CloseIcon svg={close} width={16} onClick={onNextStep} />
      <S.UrgeContent2>
        <S.UrgeImage src={lastOfferImg} alt="funny cat" />
        <S.Content>
          <S.UrgeTitle>{t`lastChanceOffer.lastChanceOffer`}</S.UrgeTitle>
          <S.Subtitle2>
            <Trans
              i18nKey="lastChanceOffer.description"
              components={[<br />, <strong />]}
              values={{ percent: 65 }}
            />
          </S.Subtitle2>
          <S.WhiteButton onClick={onNextStep}>
            <S.ButtonText>
              <Trans
                i18nKey="lastChanceOffer.action"
                values={{ percent: 65 }}
              />
            </S.ButtonText>
            <SvgImage svg={arrow} width={24} />
          </S.WhiteButton>
        </S.Content>
      </S.UrgeContent2>
    </S.UrgeWrapper2>
  )
}

Component.id = PageId.LAST_CHANCE_OFFER_2

export const LastChanceOfferVariant2 = Component
