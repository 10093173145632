import React, { InputHTMLAttributes, useMemo } from 'react'
import { OptionType } from 'components/option/Option'
import { OptionContainerVariant2 } from './Option.styles'

export type TProps = {
  type: OptionType
  value: string
  name?: string
  disabled?: boolean
  checked?: boolean
  onChange?: (value: string, isChecked: boolean) => void
}

export const OptionVariant2: React.FC<React.PropsWithChildren<TProps>> = ({
  type,
  value,
  children,
  name,
  checked,
  disabled,
  onChange,
  ...rest
}) => {
  const inputProps = useMemo((): InputHTMLAttributes<HTMLInputElement> => {
    const props: InputHTMLAttributes<HTMLInputElement> = {
      value,
      type,
      onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(target.value, target.checked),
    }

    if (name) {
      props.name = name
    }

    if (checked !== undefined) {
      props.checked = checked
    }

    if (disabled) {
      props.disabled = disabled
    }

    return props
  }, [checked, disabled, name, onChange, type, value])

  return (
    <OptionContainerVariant2 disabled={disabled} {...rest}>
      <input {...inputProps} />
      {children}
    </OptionContainerVariant2>
  )
}
