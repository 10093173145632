import { FlowContext } from 'components/FlowContext'
import { StyledOption } from 'components/option/Option.styles'
import { StyledQuestionButton } from 'components/buttons/QuestionButtons.styles'
import { TDynamicPageComponent } from 'models/common.model'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PageId } from 'src/constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { OptionType } from 'components/option/Option'
import { selectAnswers } from 'root-redux/common/common-selects'
import { getSelectedAnswers } from 'helpers/getSelectedAnswers'
import { StyledQuestion as S } from './Questions.styles'
import { ACTIVITY_OPTIONS_VALUES } from '../../constants/options-constants'

const QUESTION = 'What type of activities does your cat enjoy?'

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)
  const savedAnswers = useSelector(selectAnswers)
  const selectedAnswers = useMemo(
    () => getSelectedAnswers(savedAnswers, currentPageId),
    [savedAnswers, currentPageId],
  )
  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, currentPageId],
  )

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: QUESTION,
        answers,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep, answers])

  useEffect(() => {
    setAnswers(selectedAnswers as string[])
  }, [selectedAnswers])

  return (
    <S.Wrapper2>
      <S.Content>
        <S.Title2>
          <Trans
            i18nKey="onboarding.catActivity.question"
            components={[<br />]}
          />
        </S.Title2>
        <S.SubtitleText>
          {t`onboarding.common.chooseAllThatApply`}:
        </S.SubtitleText>
        <StyledOption.OptionsContainer>
          <S.Option
            {...optionProps}
            value={ACTIVITY_OPTIONS_VALUES.CHASING_TOYS}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              {t`onboarding.catActivity.chasingToys`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option {...optionProps} value={ACTIVITY_OPTIONS_VALUES.CLIMBING}>
            <StyledQuestionButton.Wrapper data-has-check-icon>
              {t`onboarding.catActivity.climbing`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option {...optionProps} value={ACTIVITY_OPTIONS_VALUES.PUZZLES}>
            <StyledQuestionButton.Wrapper data-has-check-icon>
              {t`onboarding.catActivity.puzzlesToys`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            value={ACTIVITY_OPTIONS_VALUES.PLAY_WITH_HUMANS}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              {t`onboarding.catActivity.playWithHumans`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option {...optionProps} value={ACTIVITY_OPTIONS_VALUES.OTHER}>
            <StyledQuestionButton.Wrapper data-has-check-icon>
              {t`onboarding.common.other`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
        </StyledOption.OptionsContainer>
        <S.StickyBlockWrapper>
          <GreenArrowButton
            isButtonDisabled={!answers.length}
            onClick={handleContinue}
          />
        </S.StickyBlockWrapper>
      </S.Content>
    </S.Wrapper2>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.ACTIVITY

export const ActivityQuestion = Component
