import { StripeError } from '@stripe/stripe-js'
import { TProductId, TProductName } from 'models/variant.model'
import { IStripeAccount } from 'models/common.model'
import { eventLogger } from 'services/eventLogger-service'
import { PaymentMethod, StripeErrorCode } from '../constants'

export const logFailedPayment = ({
  productId,
  productName,
  price,
  isTrialActive,
  paymentResponse: { type, code, message, decline_code: declineCode },
  paymentMethod,
  optimizeExperimentId,
  optimizeVariantId,
  optimizeSegmentName,
  currency,
  stripeAccount,
  email,
}: {
  productId: TProductId
  productName?: TProductName
  price: number
  isTrialActive: boolean
  paymentResponse: StripeError
  paymentMethod: PaymentMethod
  optimizeExperimentId: string
  optimizeVariantId: string
  optimizeSegmentName: string
  currency?: string
  stripeAccount?: IStripeAccount
  email: string
}): void => {
  const errorCode =
    code === StripeErrorCode.CARD_DECLINED ? `${code}:${declineCode}` : code

  eventLogger.logPurchaseFailed({
    productId,
    productName,
    priceDetails: { price, trial: isTrialActive, currency },
    error: { type, code: errorCode, description: message, declineCode },
    paymentMethod,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    stripeAccount,
    email,
  })
}
