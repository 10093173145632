import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectEmail } from 'root-redux/user/user-selects'

export const useSnapPixelInitialization = (): void => {
  const email = useSelector(selectEmail)

  useLayoutEffect(() => {
    window.snaptr('init', '824b5eaa-c986-492d-b991-2e629f2e06f9', {
      user_email: email,
    })
    window.snaptr('track', 'PAGE_VIEW', { user_email: email })
  }, [email])
}
