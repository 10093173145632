import React, { useEffect } from 'react'
import shelterCat from 'assets/images/shelter-cat.jpg'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { StyledComponents as S } from './Modal.styles'

type TProps = {
  isShown: boolean
  onClose: () => void
}

export const ShelterModal: React.FC<React.PropsWithChildren<TProps>> = ({
  isShown,
  onClose,
}) => {
  useEffect(() => {
    document.body.style.overflow = isShown ? 'hidden' : 'unset'
  }, [isShown])

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset'
    },
    [],
  )

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        <S.ShelterCatImage src={shelterCat} />
        <S.Title>You are amazing! 😻</S.Title>
        <S.Subtitle>
          Adopting a cat from a shelter
          <br /> is a great way to give a homeless animal
          <br />a loving home!
        </S.Subtitle>
        <GreenArrowButton onClick={onClose} />
      </S.Content>
    </S.Wrapper>
  )
}
