import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createLeadAction } from 'root-redux/user/user-actions'
import { TAppDispatch } from 'root-redux/store'
import {
  selectGiaApiKeys,
  selectLanguage,
  selectVariantCohort,
} from 'root-redux/common/common-selects'

export const useInitUserUuid = () => {
  const dispatch: TAppDispatch = useDispatch()
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const cohort = useSelector(selectVariantCohort)
  const language = useSelector(selectLanguage)

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const uuidFromUrl = searchParams.get('uuid')

    if (giaApiKeys && cohort && language) {
      dispatch(
        createLeadAction({
          uuidFromUrl,
          giaApiKeys,
          cohort,
          language,
          utmTags: {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium'),
            utm_campaign: searchParams.get('utm_campaign'),
            utm_content: searchParams.get('utm_content'),
            utm_term: searchParams.get('utm_term'),
          },
        }),
      )
    }
  }, [dispatch, giaApiKeys, language, cohort])
}
