import styled, { keyframes } from 'styled-components'
import { SvgImage } from 'components/SvgImage'
import { fullViewHeight } from '../../pages/styles'

const spinKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

export const StyledSpinner = {
  Wrapper: styled.div`
    ${fullViewHeight};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ComplexAnimatedCircle: styled.div`
    width: 100px;
  `,
  SpinCircle: styled(SvgImage)`
    animation-name: ${spinKeyframes};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  `,
}
