import styled, { css } from 'styled-components'
import { Color } from 'root-constants'
import { IProps as IButtonProps } from './Button'

export const StyledButtonBase = css`
  background-color: ${Color.MEDIUM_GREEN};
  width: 100%;
  height: 50px;
  display: block;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  border-radius: 40px;
  outline: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 50px;
  text-decoration: none;
  text-align: center;
  color: white;
  box-shadow: 0 6px 20px rgba(53, 180, 63, 0.3);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  transition:
    background-color 0.2s ease-out,
    box-shadow 0.2s ease-out;

  &:disabled {
    background-color: #94ce8e;
    box-shadow: none;
  }
`

export const StyledButton = styled.button<IButtonProps>`
  ${StyledButtonBase};
`
