import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TAppDispatch } from 'root-redux/store'
import { getConfigAction } from 'root-redux/common/common-actions'

export const useInitAppConfig = (): void => {
  const dispatch: TAppDispatch = useDispatch()

  useEffect(() => {
    dispatch(getConfigAction())
  }, [dispatch])
}
