import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'src/constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledQuestion as S } from './Questions.styles'
import { TOYS_OPTIONS_VALUES } from '../../constants/options-constants'

const QUESTION = 'How does your cat interact with toys?'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          How does your cat interact
          <br />
          with toys?
        </S.Title>

        <StyledOption.OptionsContainer>
          <S.Option {...optionProps} value={TOYS_OPTIONS_VALUES.NO_INTEREST}>
            <QuestionButton>Shows no interest in toys</QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            value={TOYS_OPTIONS_VALUES.PLAYS_OCCASIONALLY}
          >
            <QuestionButton>Plays with toys occasionally</QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={TOYS_OPTIONS_VALUES.ENJOYS}>
            <QuestionButton>Enjoys a variety of toys</QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={TOYS_OPTIONS_VALUES.OBSESSED}>
            <QuestionButton>Obsessed with certain toys</QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={TOYS_OPTIONS_VALUES.OTHER}>
            <QuestionButton>Other</QuestionButton>
          </S.Option>
        </StyledOption.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.TOYS

export const ToysQuestion = Component
