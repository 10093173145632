import React, { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getPathFromPageId } from 'helpers/getPathFromPageId'
import { useGetAvailableRoute } from 'hooks/useGetAvailableRoute'
import {
  selectLanguage,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { selectUUID } from 'root-redux/user/user-selects'

interface IProps {
  isAvailable: boolean
  children: React.ReactElement
}

export const ProtectedRoute: React.FC<IProps> = ({ isAvailable, children }) => {
  const { search } = useLocation()
  const language = useSelector(selectLanguage)
  const cohort = useSelector(selectVariantCohort)
  const uuid = useSelector(selectUUID)
  const availableRoute = useGetAvailableRoute()

  const pathToRedirect = useMemo(
    () =>
      getPathFromPageId({
        pageId: availableRoute,
        cohort: cohort as string,
        uuid,
        language,
        currentSearch: search,
      }),
    [cohort, uuid, language, search, availableRoute],
  )

  return isAvailable ? children : <Navigate to={`/${pathToRedirect}`} />
}
