import {
  IRequestOptions,
  IRequestParams,
  IResponseResult,
  RequestMethod,
  TRequestOptionsBody,
  TResponseErrorData,
} from './api-types'

/**
 * API_PREFIX - declare in webpack.config.js as a global variable
 */
declare const API_PREFIX: string

export class ApiService {
  private readonly urlPrefix: string
  private readonly errorCallback: ((error: Response) => void) | null

  constructor(urlPrefix: string, errorCallback?: (error: Response) => void) {
    this.urlPrefix = urlPrefix
    this.errorCallback = errorCallback || null
  }

  public async makeRequest<T>(
    url: string,
    options: IRequestOptions,
    requestSettings?: {
      isUsedCommaToSeparateQueryParamsValues: boolean
    },
  ): Promise<IResponseResult<T>> {
    try {
      const requestURL = this.buildURL(
        url,
        options.params,
        requestSettings?.isUsedCommaToSeparateQueryParamsValues || false,
      )
      const headers = this.getHeaders(
        options.headers,
        options.body instanceof FormData,
      )
      const body = this.getBody(options.body)

      const initOptions = {
        method: options.method,
        headers,
        body,
        credentials: 'include',
        ...(options.signal ? { signal: options.signal } : {}),
      } as RequestInit

      const res = await fetch(requestURL, initOptions)

      if (!res.ok) {
        return this.processError(res)
      }

      return this.processResponse<T>(res)
    } catch (error) {
      return this.processError(error as Response)
    }
  }

  public get(url: string, payloads?: IRequestOptions) {
    return this.makeRequest(url, {
      method: RequestMethod.GET,
      ...payloads,
    })
  }

  public post(url: string, payloads: IRequestOptions) {
    return this.makeRequest(url, {
      method: RequestMethod.POST,
      ...payloads,
    })
  }

  public put(url: string, payloads?: IRequestOptions) {
    return this.makeRequest(url, {
      method: RequestMethod.PUT,
      ...payloads,
    })
  }

  public delete(url: string, payloads?: IRequestOptions) {
    return this.makeRequest(url, {
      method: RequestMethod.DELETE,
      ...payloads,
    })
  }

  protected buildQuery(
    params?: IRequestParams,
    isUsedCommaToSeparateQueryParamsValues?: boolean,
  ): string {
    if (!params) {
      return ''
    }

    if (isUsedCommaToSeparateQueryParamsValues) {
      // URLSearchParams argument type can't get hash with values as arrays
      // but work with it correctly
      const searchParams = new URLSearchParams(params as any)
      // Commas will be shielded

      return `?${searchParams.toString()}`
    }

    return this.getQueryParamsWithDuplicateKeys(params)
  }

  protected buildURL(
    url: string,
    params?: IRequestParams,
    isUsedCommaToSeparateQueryParamsValues?: boolean,
  ): string {
    const query: string = this.buildQuery(
      params,
      isUsedCommaToSeparateQueryParamsValues,
    )

    return `${this.urlPrefix}/${url}${query}`
  }

  protected getBody(body?: TRequestOptionsBody): FormData | string | null {
    if (!body) {
      return null
    }

    if (body instanceof FormData) {
      return body
    }

    return JSON.stringify(body)
  }

  protected getHeaders(
    headers: Headers | undefined,
    isBodyFormData: boolean,
  ): Headers {
    if (isBodyFormData && headers) {
      headers.has('Content-Type') && headers.delete('Content-Type')
      return headers
    }

    if (isBodyFormData && !headers) {
      return new Headers()
    }

    if (headers && headers.has('Content-Type')) {
      return headers
    }

    if (headers && !headers.has('Content-Type')) {
      headers.append('Content-Type', 'application/json')
      return headers
    }

    return new Headers({
      'Content-Type': 'application/json',
    })
  }

  protected async processResponse<T>(
    response: Response,
  ): Promise<IResponseResult<T>> {
    try {
      const result: any = response.headers
        .get('Content-Type')
        ?.includes('application/json')
        ? await response.json()
        : await response.text()

      return {
        success: true,
        status: response.status,
        data: result,
      }
    } catch {
      return {
        success: true,
        status: response.status,
      }
    }
  }

  protected async processError(
    error: Response,
  ): Promise<IResponseResult<TResponseErrorData>> {
    if (this.errorCallback) {
      this.errorCallback(error)
    }

    try {
      const data: TResponseErrorData = error.headers
        ?.get('Content-Type')
        ?.includes('application/json')
        ? await error.json()
        : await error.text()

      return Promise.resolve<IResponseResult<TResponseErrorData>>({
        success: false,
        status: error.status,
        data,
      })
    } catch (parseError) {
      return Promise.resolve<IResponseResult<TResponseErrorData>>({
        success: false,
        status: error.status,
      })
    }
  }

  private getQueryParamsWithDuplicateKeys(params: IRequestParams) {
    const items: string[] = []

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((valueItem) =>
          items.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(valueItem)}`,
          ),
        )
      } else {
        items.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    })

    return `?${items.join('&')}`
  }
}

// const API_PREFIX = '/admin'
const errorCallback = (error: Response) => {
  console.error(error)
  // if (error.status === '405') {
  // navigate('/login')
  // }
}

export const api = new ApiService(API_PREFIX, errorCallback)
