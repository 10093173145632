import { Color } from 'root-constants'
import styled from 'styled-components'

export const StyledEducationJourney = {
  Wrapper: styled.div`
    position: relative;
    background-color: ${Color.WHITE};
  `,
  Title: styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 40px;
    width: 288px;
    left: calc(50% - 144px);

    strong {
      color: #f14a31;
    }
  `,
  Image: styled.img`
    width: 100vw;
    border-radius: 50px 50px 0 0;
  `,
}
