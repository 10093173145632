import { Color, MediaBreakpoint } from 'root-constants'
import styled from 'styled-components'
import { SvgImage } from 'components/SvgImage'
import { Button } from 'components/buttons/Button'
import { desktopMediaQuery } from 'pages/styles'

export const StyledComponents = {
  Agreement: styled.p`
    position: fixed;
    bottom: 8px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.5);

    a {
      color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.5);
      text-decoration: underline;
    }

    &[data-is-virtual-keypad-active='true'] {
      display: none;
    }

    @media ${desktopMediaQuery} {
      margin-top: 8px;
      position: relative;
      inset: 0;

      &[data-is-virtual-keypad-active='true'] {
        display: block;
      }
    }
  `,
  Note: styled.div`
    width: 100%;
    padding-left: 7px;
    display: flex;
    justify-content: center;
    margin-bottom: 120px;

    @media ${desktopMediaQuery} {
      margin-bottom: 24px;
    }
  `,
  LockImg: styled(SvgImage)`
    width: 20px;
    opacity: 0.5;
    margin-right: 8px;
  `,
  Description: styled.p`
    text-align: start;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    color: #b0a099;
  `,
  Content: styled.div`
    background: ${Color.DARK_PURPLE};
    border-radius: 40px 40px 0 0;
    width: 100%;
    position: relative;
  `,
  DescriptionBlock: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      margin-top: 18px;
    }
  `,
  DescriptionText: styled.p`
    font-size: 15px;
    color: ${Color.WHITE};
    text-align: left;
    padding-right: 8px;
  `,
  Title: styled.h3`
    font-size: 24px;
    color: ${Color.YELLOW_LIGHT};
    text-align: left;
  `,
  Strong: styled.strong`
    font-weight: 900;
    color: ${Color.YELLOW_LIGHT};
  `,
  ButtonBlock: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
  `,
  NoteFooter: styled.div`
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: flex;
    align-items: self-start;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.5);
    margin-top: 10px;
  `,
  Button: styled(Button)`
    bottom: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 290px;

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 230px;
    }
  `,
  ButtonText: styled.span`
    width: 100%;
  `,
  NoteDescription: styled.p`
    font-size: 10px;
    color: ${Color.BROWNISH_GRAY};
  `,
  TooltipContainer: styled.div`
    padding: 0 18px;
    background: ${Color.ERROR};
    display: flex;
    align-items: center;
    justify-content: cetner;
    border-radius: 24px;
    position: absolute;
    bottom: 110px;
  `,
  Image: styled.img`
    width: 130px;
    height: 130px;
    margin-left: -20px;
  `,
  TooltipContent: styled.div`
    text-align: left;
  `,
  TooltipTitle: styled.h2`
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    color: ${Color.YELLOW_LIGHT};
  `,
  TooltipText: styled.p`
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${Color.WHITE};

    span {
      font-weight: 900;
      color: ${Color.YELLOW_LIGHT};
    }
  `,
  TooltipTale: styled(SvgImage)`
    width: 55px;
    height: 32px;
    position: absolute;
    bottom: -30px;
    right: 50px;
  `,
}
