/* eslint-disable camelcase */
import { IUserStatus, IUserStatusRaw } from 'models/user.model'
import { LOGIN_METHOD_RAW_MAP } from 'root-constants'

export const getUserStatusFromRawUserStatus = (
  userStatusRaw: IUserStatusRaw,
  stateRaw: {
    inapp: {
      is_inapp_purchased: boolean
    }
  },
): IUserStatus => ({
  hasEmail: userStatusRaw.has_email,
  hasSubscription: userStatusRaw.has_subscription,
  account: {
    hasAccount: !!userStatusRaw.account?.has_account,
    loginMethod: LOGIN_METHOD_RAW_MAP[userStatusRaw.account?.login_method],
  },
  email: userStatusRaw.email?.onboarding_email || '',
  onboarding: userStatusRaw.onboarding,
  upsell: {
    isPaid: stateRaw.inapp.is_inapp_purchased,
  },
})
