import { useEffect } from 'react'

import { useProgressAnimation } from 'hooks/useProgressAnimation'

type TUseProgressAnimationDataProps = {
  onNextStep: () => void
  stopCounterAt?: number
  durations: number[]
}

type TUseProgressAnimationDataReturnTypes = {
  firstCounter: number
  isFirstCounterFinished: boolean
  secondCounter: number
  isSecondCounterFinished: boolean
  secondProgressAnimationPlayback: () => void
  thirdCounter: number
  isThirdCounterFinished: boolean
  thirdProgressAnimationPlayback: () => void
  fourthCounter: number
  isFourthCounterFinished: boolean
}

export const useProgressAnimationData = ({
  onNextStep,
  stopCounterAt,
  durations,
}: TUseProgressAnimationDataProps): TUseProgressAnimationDataReturnTypes => {
  const {
    counter: firstCounter,
    isFinished: isFirstCounterFinished,
    progressAnimationPlayback: firstProgressAnimationPlayback,
  } = useProgressAnimation({
    duration: durations[0],
  })

  const {
    counter: secondCounter,
    isFinished: isSecondCounterFinished,
    progressAnimationPlayback: secondProgressAnimationPlayback,
  } = useProgressAnimation({
    duration: durations[1],
  })

  const {
    counter: thirdCounter,
    isFinished: isThirdCounterFinished,
    progressAnimationPlayback: thirdProgressAnimationPlayback,
  } = useProgressAnimation({
    duration: durations[2],
  })

  const {
    counter: fourthCounter,
    isFinished: isFourthCounterFinished,
    progressAnimationPlayback: fourthProgressAnimationPlayback,
  } = useProgressAnimation({
    duration: durations[3],
    callback: onNextStep,
  })

  useEffect(() => {
    firstProgressAnimationPlayback()
  }, [firstProgressAnimationPlayback])

  useEffect(() => {
    isFirstCounterFinished && secondProgressAnimationPlayback(stopCounterAt)
  }, [isFirstCounterFinished, secondProgressAnimationPlayback, stopCounterAt])

  useEffect(() => {
    isSecondCounterFinished && thirdProgressAnimationPlayback()
  }, [isSecondCounterFinished, thirdProgressAnimationPlayback])

  useEffect(() => {
    isThirdCounterFinished && fourthProgressAnimationPlayback()
  }, [isThirdCounterFinished, fourthProgressAnimationPlayback])

  return {
    firstCounter,
    isFirstCounterFinished,
    secondCounter,
    isSecondCounterFinished,
    secondProgressAnimationPlayback,
    thirdCounter,
    isThirdCounterFinished,
    thirdProgressAnimationPlayback,
    fourthCounter,
    isFourthCounterFinished,
  }
}
