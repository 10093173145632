import React from 'react'
import { PageId } from 'src/constants/pages'

interface IFlowContext {
  onNextStep: () => void
  onPreviousStep: () => void
  onSubscriptionsForNonPayerUsers: () => void
  onFirstAdditionalPageForNonPayerUsers: () => void
  onSkipSteps: () => void
  onSkipOneStepBack: () => void
  onUpsell: () => void
  onAccountStep: () => void
  currentPageId: PageId | null
  currentPageNumber: number | null
}

const defaultState = {
  onNextStep: () => undefined,
  onPreviousStep: () => undefined,
  onSubscriptionsForNonPayerUsers: () => undefined,
  onFirstAdditionalPageForNonPayerUsers: () => undefined,
  onSkipSteps: () => undefined,
  onSkipOneStepBack: () => undefined,
  onUpsell: () => undefined,
  onAccountStep: () => undefined,
  currentPageId: null,
  currentPageNumber: null,
}

export const FlowContext = React.createContext<IFlowContext>(defaultState)
