import { IAppState } from 'models/store.model'
import {
  IConfig,
  IFirebaseConfig,
  IRemoteConfigFile,
  TGiaApiKeys,
} from 'models/config.model'
import { IStep, IVariant } from 'models/variant.model'
import { TAnswers } from 'models/common.model'

export const selectVariantCohort = (state: IAppState): string =>
  state.common.variant?.cohort || ''

export const selectCurrentVariantSteps = (state: IAppState): IStep[] =>
  state.common.variant?.steps || []

export const selectLanguage = (state: IAppState): string =>
  state.common.language

export const selectGiaApiKeys = (state: IAppState): TGiaApiKeys | null =>
  state.common.config?.giaApiKeys || null

export const selectConfig = (state: IAppState): IConfig | null =>
  state.common.config

export const selectOptimizeExperimentId = (state: IAppState): string =>
  state.common.variant?.optimizeExperimentId || ''
export const selectOptimizeVariantId = (state: IAppState): string =>
  state.common.optimizeVariantId
export const selectOptimizeSegmentName = (state: IAppState): string =>
  state.common.optimizeSegmentName

export const selectIsLoggerInitialized = (state: IAppState): boolean =>
  state.common.isLoggerInitialized

// TODO describe initial value interface
export const selectFirebaseRemoteConfig = (state: IAppState): any =>
  state.common.firebaseRemoteConfig

export const selectCurrentVariant = (state: IAppState): IVariant | null =>
  state.common.variant || null

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching

export const selectError = (state: IAppState): any => state.common.error

export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.common.answers

export const selectIsCancelOfferApplied = (state: IAppState): boolean =>
  state.common.isCancelOfferApplied

export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList

export const selectFirebaseConfigFile = (state: IAppState): IRemoteConfigFile =>
  state.common.firebaseConfigFile

export const selectGoogleOptimizeId = (state: IAppState): string | null =>
  state.common.config?.googleOptimizeId || null

export const selectGoogleAnalyticsId = (state: IAppState): string | null =>
  state.common.config?.googleAnalyticsId || null

export const selectFirebaseConfig = (
  state: IAppState,
): IFirebaseConfig | null => state.common.config?.firebaseConfig || null
