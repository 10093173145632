import { useCallback, useEffect, useState } from 'react'
import { ValidationText } from 'root-constants'
import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'
import { useTranslation } from 'react-i18next'

export const useConfirmationPasswordInputField = (
  password: IInputFieldState,
): TInputFieldHook => {
  const [confirmationPassword, setConfirmationPassword] =
    useState<IInputFieldState>({
      value: '',
      isValid: true,
      validationText: '',
    })
  const { t } = useTranslation()

  useEffect(() => {
    if (
      password.value !== confirmationPassword.value &&
      password.value !== '' &&
      confirmationPassword.value !== ''
    ) {
      setConfirmationPassword((prevState) => ({
        ...prevState,
        isValid: false,
        validationText:
          'Confirm password doesn`t match password' as ValidationText,
      }))
      return
    }

    setConfirmationPassword((prevState) => ({
      ...prevState,
      isValid: true,
      validationText: '',
    }))
  }, [t, confirmationPassword.value, password.value])

  const validateConfirmationPassword = useCallback(
    (confirmationPasswordValue: string) => {
      if (
        confirmationPasswordValue.length < 6 &&
        confirmationPasswordValue !== ''
      ) {
        setConfirmationPassword((prevState) => ({
          ...prevState,
          value: confirmationPasswordValue,
          isValid: false,
          validationText: 'Please use at least 6 characters' as ValidationText,
        }))
        return
      }

      if (confirmationPasswordValue !== password.value) {
        setConfirmationPassword((prevState) => ({
          ...prevState,
          value: confirmationPasswordValue,
          isValid: false,
          validationText:
            'Confirm password doesn`t match password' as ValidationText,
        }))
        return
      }

      setConfirmationPassword((prevState) => ({
        ...prevState,
        value: confirmationPasswordValue,
        isValid: true,
        validationText: '',
      }))
    },
    [t, password.value],
  )

  return [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ]
}
