import { useMachine } from '@xstate/react'
import { HeaderContainer } from 'components/header/HeaderContainer'
import { RouteList } from 'components/RouteList'
import { getPathFromPageId } from 'helpers/getPathFromPageId'
import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { IUserStatus } from 'models/user.model'
import { IVariant } from 'models/variant.model'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  selectCurrentVariantSteps,
  selectLanguage,
} from 'root-redux/common/common-selects'
import { selectUUID } from 'root-redux/user/user-selects'
import { createUserFlowStateMachine } from 'services/userFlowState-service'
import { useStartSession } from 'hooks/useStartSession'
import { getXStateStepMachine } from 'helpers/getXStateStepMachine'
import { useTranslation } from 'react-i18next'
import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { selectSuccessfulPaypalPayment } from 'root-redux/payment/payment-selects'
import { PageId } from '../constants/pages'
import { goTo } from '../routerHistory'
import { FlowContext } from './FlowContext'
import { useTiktokPixelInitialization } from 'hooks/useTikTokPixelInitialization'

interface IProps {
  variant: IVariant
  userStatus: IUserStatus
}

export const FlowRouter: React.FC<IProps> = ({ variant, userStatus }) => {
  const { pathname, search } = useLocation()
  const uuid = useSelector(selectUUID)
  const steps = useSelector(selectCurrentVariantSteps)
  const successfulPaymentWithPaypal = useSelector(selectSuccessfulPaypalPayment)

  const { i18n } = useTranslation()
  const language = useSelector(selectLanguage)

  const { cohort } = variant
  const stateMachine = useMemo(
    () => createUserFlowStateMachine(userStatus, getXStateStepMachine(steps)),
    [steps, userStatus],
  )
  const [state, send] = useMachine(stateMachine)
  const currentPageId = useMemo(() => state.value as PageId, [state])
  const currentPageIndex = useMemo(
    () => steps.findIndex((page) => page.id === currentPageId),
    [steps, currentPageId],
  )

  const currentPageNumber = useMemo(
    () => (currentPageIndex >= 0 ? currentPageIndex + 1 : null),
    [currentPageIndex],
  )

  useFacebookPixelInitialization()
  useTiktokPixelInitialization()
  useEventLoggerInitialization({ cohort })
  useStartSession()

  useEffect(() => {
    const targetPath = getPathFromPageId({
      pageId: currentPageId,
      cohort,
      uuid,
      language,
      currentSearch: search,
    })

    const isPathNew = targetPath !== `${pathname}${search}`
    isPathNew && goTo(targetPath)
  }, [cohort, currentPageId, pathname, search, uuid, language, state.value])

  useEffect(() => {
    if (i18n) {
      i18n.changeLanguage(language)
    }
  }, [i18n, language])

  const goToNextStep = useCallback(() => {
    if (send) {
      send({ type: 'NEXT_FLOW_STEP' })
    }
  }, [send])

  const goToPreviousStep = useCallback(() => {
    if (send) {
      send({ type: 'PREVIOUS_FLOW_STEP' })
    }
  }, [send])

  const goToSubscriptionsForNonPayerUsers = useCallback(() => {
    if (send) {
      send({ type: 'SUBSCRIPTIONS_FOR_NON_PAYER_USERS' })
    }
  }, [send])

  const goToFirstAdditionalPageForNonPayerUsers = useCallback(() => {
    if (send) {
      send({ type: 'FIRST_ADDITIONAL_PAGE_FOR_NON_PAYER_USERS' })
    }
  }, [send])

  const goToUpsellPage = useCallback(() => {
    if (send) {
      send({ type: 'GO_TO_UPSELL_PAGE' })
    }
  }, [send])

  const skipSteps = useCallback(() => {
    if (send) {
      send({ type: 'SKIP_FLOW_STEPS' })
    }
  }, [send])

  const skipOneStepBack = useCallback(() => {
    if (send) {
      send({ type: 'SKIP_ONE_STEP_BACK' })
    }
  }, [send])

  const goToAccountPage = useCallback(() => {
    if (send) {
      send({ type: 'GO_TO_ACCOUNT_PAGE' })
    }
  }, [send])

  useEffect(() => {
    if (successfulPaymentWithPaypal && !userStatus.account.hasAccount) {
      goToAccountPage()
    }
  }, [
    successfulPaymentWithPaypal,
    userStatus.account.hasAccount,
    goToAccountPage,
  ])

  const providerValue = useMemo(
    () => ({
      onNextStep: goToNextStep,
      onPreviousStep: goToPreviousStep,
      onSubscriptionsForNonPayerUsers: goToSubscriptionsForNonPayerUsers,
      onFirstAdditionalPageForNonPayerUsers:
        goToFirstAdditionalPageForNonPayerUsers,
      onSkipSteps: skipSteps,
      onUpsell: goToUpsellPage,
      currentPageId,
      currentPageNumber,
      onSkipOneStepBack: skipOneStepBack,
      onAccountStep: goToAccountPage,
    }),
    [currentPageId, currentPageNumber],
  )

  return (
    <FlowContext.Provider value={providerValue}>
      <HeaderContainer />
      <RouteList />
    </FlowContext.Provider>
  )
}
