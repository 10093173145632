import moneyBag from 'assets/images/emoji/money-bag-emoji.png'
import lighting from 'assets/images/emoji/lighting-emoji.png'
import circleArrows from 'assets/images/emoji/circle-arrows-emoji.png'
import phone from 'assets/images/emoji/phone-emoji.png'
import shopppingCart from 'assets/images/emoji/shopping-cart-emoji.png'
import cat from 'assets/images/emoji/cat-emoji.png'

import React from 'react'
import { StyledBenefits as S } from './Benefits.styles'

const BENEFITS = [
  { bgColor: '#C4DCC9', emoji: moneyBag, title: 'High quality, low prices' },
  {
    bgColor: '#FFE0B2',
    emoji: lighting,
    title: 'Fast and easy repeat deliveries',
  },
  { bgColor: '#CFE2FF', emoji: circleArrows, title: 'Hassle-free returns' },
  { bgColor: '#DBDAFF', emoji: phone, title: '24/7 support' },
  {
    bgColor: '#FFD098',
    emoji: shopppingCart,
    title: '50% off for your first order',
  },
  { bgColor: '#C4DCC9', emoji: cat, title: 'Everyday essentials' },
]

export const Benefits: React.FC = () => (
  <S.Wrapper>
    <S.Title>Our benefits</S.Title>
    <S.BenefitsContainer>
      {BENEFITS.map(({ bgColor, emoji, title }) => (
        <S.BenefitItem key={title}>
          <S.Circle bgColor={bgColor}>
            <S.EmojiImage src={emoji} alt="emoji" />
          </S.Circle>
          <S.BenefitItemText>{title}</S.BenefitItemText>
        </S.BenefitItem>
      ))}
    </S.BenefitsContainer>
  </S.Wrapper>
)
