import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import { basePageStyles } from 'pages/styles'
import { baseTitle } from '../../pages/Subscriptions.styles'

export const StyledObtainingList = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding: 0 16px 80px;
    background-color: ${Color.BEIGE};
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  Title: styled.h3`
    ${baseTitle};
    margin-bottom: 24px;
    margin-top: 24px;
  `,
  ListsWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    gap: 24px;
  `,
  ListItem: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  `,
}
