import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'

import React from 'react'
import { PLAN_TYPES } from 'root-constants'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import { ScreenName } from 'services/eventLogger-service'
import { StickyHeader } from 'modules/subscriptions/components/sticky-header/StickyHeader'
import { DidYouKnowBlockCs } from '../components/did-you-know/DidYouKnowBlockCs'
import { CatCardVariantCs } from '../components/cat-card/CatCardVariantCs'
import { StyledSubscriptions as S } from './Subscriptions.styles'
import { PageId } from '../../../constants/pages'
import { Benefits } from '../components/benefits/Benefits'
import { GuaranteeCs } from '../components/guarantee/GuaranteeVariantCs'
import { SelectPlanItemsBlockCs } from '../components/plan-blocks/SelectPlanBlockCs'
import { PlanItemVariantCs } from '../components/plan-blocks/PlanItemVariantCs'

const CatSubscriptionsPage: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
}) => (
  <S.Wrapper>
    <S.ContentVariant2>
      <StickyHeader
        buttonText="MAKE AN ORDER"
        title="50% discount expires in"
      />
      <CatCardVariantCs />
      <DidYouKnowBlockCs />
      <Benefits />
      <SelectPlanItemsBlockCs
        onButtonClick={onButtonClick}
        onSelect={onSubscriptionSelect}
        SelectPlanItem={PlanItemVariantCs}
        availableSubscriptions={availableSubscriptions}
      />
      <GuaranteeCs />
    </S.ContentVariant2>
  </S.Wrapper>
)

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.SALES}
    planType={PLAN_TYPES.PURCHASE}
  >
    {(props: ISubscriptionsPageProps) => <CatSubscriptionsPage {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.SUBSCRIPTIONS_CS

export const SubscriptionsVariantCs = Component
