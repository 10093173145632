/* eslint-disable max-lines */
import { TIdToPageMap } from 'models/common.model'
import { BreedQuestion } from 'pages/questions/BreedQuestion'
import { SexQuestion } from 'pages/questions/SexQuestion'
import { AgeQuestion } from 'pages/questions/AgeQuestion'
import { ShelterQuestion } from 'pages/questions/ShelterQuestion'
import { CommandsQuestion } from 'pages/questions/CommandsQuestion'
import { Urge } from 'pages/urge/Urge'
import { ReactNewPeopleQuestion } from 'pages/questions/ReactNewPeopleQuestion'
import { NoiseResponseQuestion } from 'pages/questions/NoiseResponseQuestion'
import { ToysQuestion } from 'pages/questions/ToysQuestion'
import { GroomingQuestion } from 'pages/questions/GroomingQuestion'
import { TouchReactQuestion } from 'pages/questions/TouchReactQuestion'
import { BehaviorIssuesQuestion } from 'pages/questions/BehaviorIssuesQuestion'
import { ComeWhenCalledQuestion } from 'pages/questions/ComeWhenCalledQuestion'
import { UrgeImportant } from 'pages/urge/UrgeImportant'
import { ClickerTrainedQuestion } from 'pages/questions/ClickerTrainedQuestion'
import { MedicalConditionQuestion } from 'pages/questions/MedicalConditionQuestion'
import { ActivityQuestion } from 'pages/questions/ActivityQuestion'
import { MainGoalQuestion } from 'pages/questions/MainGoalQuestion'
import { TimeQuestion } from 'pages/questions/CatTimeQuestion'
import { PrepareFlow } from 'pages/prepare-flow/PrepareFlow'
import { EmailPage } from 'modules/email/pages/EmailPage'
import { Subscriptions } from 'modules/subscriptions/pages/Subscriptions'
import { CancelOfferPage } from 'modules/subscriptions/pages/cancel-offer/CancelOfferPage'
import { LastChanceOfferPage } from 'modules/subscriptions/pages/last-cahnce-offer/LastChanceOfferPage'
import { Payment } from 'modules/payment/pages/Payment'
import { SexQuestionVariant2 } from 'pages/questions/SexQuestionVariant2'
import { AgeQuestionVariant2 } from 'pages/questions/AgeQuestionVariant2'
import { BreedQuestionVariant2 } from 'pages/questions/BreedQuestionVariant2'
import { BodyLanguageQuestion } from 'pages/questions/BodyLanguageQuestion'
import { UrgeFantasticVariant2 } from 'pages/urge/UrgeFantasticVariant2'
import { MainGoalQuestionVariant2 } from 'pages/questions/MainGoalQuestionVariant2'
import { EmailVariant2 } from 'modules/email/pages/EmailVariant2'
import { SubscriptionsVariant2 } from 'modules/subscriptions/pages/SubscriptionsVariant2'
import { PaymentVariant2 } from 'modules/payment/pages/PaymentVariant2'
import { CancelOfferVariant2 } from 'modules/subscriptions/pages/cancel-offer/CancelOfferVariant2'
import { LastChanceOfferVariant2 } from 'modules/subscriptions/pages/last-cahnce-offer/LastChanceOfferVariant2'
import { WelcomePage } from 'pages/questions/WelcomePage'
import { Account } from 'modules/account/Account'
import { DownloadApp } from 'pages/download/DownloadApp'
import { Upsell } from 'modules/upsell/pages/Upsell'
import { DownloadAppWithPdf } from 'pages/download/DownloadAppWithPdf'
import { BehaviorIssuesQuestionVariant2 } from 'pages/questions/BehaviorIssuesQuestionVariant2'
import { EmailVariantCs } from 'modules/email/pages/EmailPageVariantCs'
import { SubscriptionsVariantCs } from 'modules/subscriptions/pages/SubscriptionsVariantCs'
import { PaymentVariantCs } from 'modules/payment/pages/PaymentVariantCs'
import { UpsellVariantCs } from 'modules/upsell/pages/UpsellVariantCs'

export const enum PageId {
  CAT_SEX = 'cat_sex',
  CAT_SEX_2 = 'cat_sex_2',
  CAT_AGE = 'cat_age',
  CAT_AGE_2 = 'cat_age_2',
  CAT_BREED = 'cat_breed',
  BREED_2 = 'cat_breed_2',
  SHELTER_CAT = 'shelter_cat',
  CAT_COMMANDS = 'cat_commands',
  URGE_CAT = 'urge_cat',
  REACT_NEW_PEOPLE = 'cat_react_new_people',
  NOISE_RESPONSE = 'noise_response',
  COME_WHEN_CALLED = 'cat_come_when_called',
  TOYS = 'cat_toys',
  GROOMING = 'cat_grooming',
  TOUCH_REACT = 'cat_touch_react',
  BEHAVIOR_ISSUES = 'cat_behavior_issues',
  BEHAVIOR_ISSUES_2 = 'cat_behavior_issues_2',
  URGE_IMPORTANT = 'urge_cat_important',
  CLICKER_TRAINED = 'cat_clicker_trained',
  MEDICAL_CONDITIONS = 'medical_conditions',
  ACTIVITY = 'cat_activity',
  MAIN_GOAL = 'cat_main_goal',
  TIME = 'cat_time',
  PREPARE_FLOW = 'cat_prepare_flow',
  EMAIL_MEOWZ = 'email_meowz',
  SUBSCRIPTIONS = 'cat_subscriptions',
  SUBSCRIPTIONS_2 = 'cat_subscriptions_2',
  SUBSCRIPTIONS_CS = 'cat_subscriptions_cs',
  UPSELL = 'upsell',
  UPSELL_CS = 'upsell_cs',
  CANCEL_OFFER = 'cat_cancel_offer',
  CANCEL_OFFER_2 = 'cat_cancel_offer_2',
  LAST_CHANCE_OFFER = 'last_chance_offer',
  LAST_CHANCE_OFFER_2 = 'last_chance_offer_cat_2',
  MEOWZ_PAYMENT = 'meowz_payment',
  BODY_LANGUAGE = 'body_language',
  URGE_2 = 'urge_cat_2',
  MAIN_GOAL_2 = 'cat_main_goal_2',
  EMAIL_2 = 'email_meowz_2',
  EMAIL_CS = 'email_meowz_cs',
  PAYMENT_2 = 'meowz_payment_2',
  PAYMENT_CS = 'meowz_payment_cs',
  WELCOME_1 = 'welcome_cat_1',
  ACCOUNT = 'account',
  DOWNLOAD = 'download',
  DOWNLOAD_PDF = 'downloadPdf',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  cat_sex: SexQuestion,
  cat_sex_2: SexQuestionVariant2,
  cat_age: AgeQuestion,
  cat_age_2: AgeQuestionVariant2,
  cat_breed: BreedQuestion,
  cat_breed_2: BreedQuestionVariant2,
  shelter_cat: ShelterQuestion,
  cat_commands: CommandsQuestion,
  urge_cat: Urge,
  cat_react_new_people: ReactNewPeopleQuestion,
  noise_response: NoiseResponseQuestion,
  cat_toys: ToysQuestion,
  cat_grooming: GroomingQuestion,
  cat_touch_react: TouchReactQuestion,
  cat_behavior_issues: BehaviorIssuesQuestion,
  cat_behavior_issues_2: BehaviorIssuesQuestionVariant2,
  urge_cat_important: UrgeImportant,
  urge_cat_2: UrgeFantasticVariant2,
  cat_come_when_called: ComeWhenCalledQuestion,
  cat_clicker_trained: ClickerTrainedQuestion,
  medical_conditions: MedicalConditionQuestion,
  cat_activity: ActivityQuestion,
  cat_main_goal: MainGoalQuestion,
  cat_main_goal_2: MainGoalQuestionVariant2,
  cat_time: TimeQuestion,
  body_language: BodyLanguageQuestion,
  cat_prepare_flow: PrepareFlow,
  email_meowz: EmailPage,
  email_meowz_2: EmailVariant2,
  email_meowz_cs: EmailVariantCs,
  cat_subscriptions: Subscriptions,
  cat_subscriptions_2: SubscriptionsVariant2,
  cat_subscriptions_cs: SubscriptionsVariantCs,
  upsell: Upsell,
  upsell_cs: UpsellVariantCs,
  cat_cancel_offer: CancelOfferPage,
  cat_cancel_offer_2: CancelOfferVariant2,
  last_chance_offer: LastChanceOfferPage,
  last_chance_offer_cat_2: LastChanceOfferVariant2,
  meowz_payment: Payment,
  meowz_payment_2: PaymentVariant2,
  meowz_payment_cs: PaymentVariantCs,
  welcome_cat_1: WelcomePage,
  account: Account,
  download: DownloadApp,
  downloadPdf: DownloadAppWithPdf,
}
