import firebase from 'firebase/compat/app'
import { useEffect } from 'react'
// import {useSelector} from 'react-redux'
// import {selectFirebaseConfig} from 'root-redux/common/common-selects'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import {
  firebaseConfigDEV,
  firebaseConfigPROD,
} from '../constants/firebase-config'

export const useAuthObserver = (callback: (token: string) => void): void => {
  const currentEnv = getCurrentEnv()
  const firebaseConfig =
    currentEnv === 'prod' ? firebaseConfigPROD : firebaseConfigDEV
  // const firebaseConfig = useSelector(selectFirebaseConfig)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (firebaseConfig) {
      const unregisterAuthObserver = firebase
        .auth()
        .onAuthStateChanged(async (user) => {
          if (!user) return

          const token = await user.getIdToken()
          callback(token)
        })

      return () => {
        unregisterAuthObserver()
      }
    }
  }, [callback, firebaseConfig])
}
