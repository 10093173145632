import user1 from 'assets/images/user-1.jpg'
import user2 from 'assets/images/user-2.jpg'
import user3 from 'assets/images/user-3.jpg'
import user4 from 'assets/images/user-4.jpg'
import user5 from 'assets/images/user-5.jpg'
import user6 from 'assets/images/user-6.jpg'
import user7 from 'assets/images/user-7.jpg'
import user8 from 'assets/images/user-8.jpg'
import user9 from 'assets/images/user-9.jpg'
import user10 from 'assets/images/user-10.jpg'
import user11 from 'assets/images/user-11.jpg'

export const COMMENTS = [
  {
    title: 'Life-changing! ',
    text: 'The training plan transformed my cat`s habits. Thank you for the guidance!',
    avatar: user1,
    data: 'Aug 4, 2023',
    autoplayDelay: 3000,
  },
  {
    title: 'Easy peasy',
    text: 'Efficient and easy-to-follow cat training plan. My cat`s behavior is more manageable now.',
    avatar: user2,
    data: 'Aug 17, 2023',
    autoplayDelay: 2000,
  },
  {
    title: 'Great results!',
    text: 'The cat training plan helped us address scratching and litter box issues.',
    avatar: user3,
    data: 'Aug 21, 2023',
    autoplayDelay: 5000,
  },
  {
    title: 'Highly satisfied!',
    text: 'The training plan enhanced my bond with my cat and curbed bad habits.',
    avatar: user4,
    data: 'Jul 3, 2023',
    autoplayDelay: 2000,
  },
  {
    title: 'Incredible transformation!',
    text: 'Our once shy cat is now more social and playful.',
    avatar: user5,
    data: 'Jul 27, 2023',
    autoplayDelay: 5000,
  },
]

export const COMMENTS_V2 = [
  {
    title: 'I’m so impressed!',
    text: 'I was skeptical at first, but this training plan really worked! My cat now knows how to sit, stay, and come when called.',
    avatar: user6,
    data: 'Jul 12, 2023',
  },
  {
    title: 'I’m so glad I found this meowz',
    text: 'I was struggling to get my cat to stop scratching the furniture, but this training plan helped me solve the problem. My cat is now much better behaved',
    avatar: user7,
    data: 'Jul 15, 2023',
  },
  {
    title: 'Amazing cat training plan!',
    text: 'My kitty’s behavior improved within weeks. Highly recommended!',
    avatar: user8,
    data: 'Jul 22, 2023',
  },
  {
    title: 'It’s easy to follow',
    text: 'I’m so glad I found this training plan. It’s easy to follow and my cat is learning so quickly!',
    avatar: user9,
    data: 'Aug 08, 2023',
  },
  {
    title: 'Wow effect!',
    text: 'This training plan helped me teach my cat to sit and stay! Wow effect!',
    avatar: user10,
    data: 'Aug 16, 2023',
  },
  {
    title: 'Would recommend',
    text: 'Training plan helped teach cat so many things. Enjoying bonding experience.',
    avatar: user11,
    data: 'Aug 30, 2023',
  },
]
