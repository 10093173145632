import styled from 'styled-components'
import { Color } from 'root-constants'

export const StyledInterestingFact = {
  Wrapper: styled.div`
    position: relative;
    background-color: #f4f7ff;
    border-radius: 40px 40px 0 0;
    border: 4px solid #8a8be4;
    margin-bottom: -40px;
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 16px;
  `,
  Description: styled.h2`
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 108px;
    width: calc(100% - 32px);
  `,
  Label: styled.div`
    background: #8a8be4;
    border-radius: 40px;

    color: ${Color.WHITE};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    padding: 4px 24px;
    margin-top: -20px;
  `,
  Image: styled.img`
    width: 107px;
    height: 88px;
    z-index: 3;
    margin-bottom: 10px;
    margin-top: -95px;
  `,
}
