import { Color } from 'root-constants'
import styled, { css } from 'styled-components'
import { SvgImage } from 'components/SvgImage'
import { Option } from 'components/option/Option'
import { OptionVariant2 } from 'components/option/OptionVariant2'
import {
  baseColumnStyles,
  basePageStyles,
  baseTextStyles,
  baseTitleStyles,
  desktopMediaQuery,
  fullViewHeight,
  fullViewMinHeight,
} from '../styles'

const breedButton = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  padding: 8px 15px;
  color: ${Color.PRIMARY_TEXT};
  background-color: transparent;
  border: 1px solid #b0a099;
  border-radius: 44px;
`

export const StyledQuestion = {
  WelcomeWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.BEIGE};
    padding-top: 75px;

    @media ${desktopMediaQuery} {
      padding-top: 112px;
    }
  `,
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.BEIGE};
    padding-top: 96px;

    @media ${desktopMediaQuery} {
      padding-top: 112px;
    }
  `,
  Wrapper2: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    background-color: ${Color.BEIGE};
    padding-top: 96px;
    height: 100%;
    background-size: 100%;

    @media ${desktopMediaQuery} {
      padding-top: 112px;
    }
  `,
  SexQuestionWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: #8851b6;
    justify-content: center;
  `,
  Content: styled.div`
    ${baseColumnStyles};
  `,
  Content2: styled.div`
    ${baseColumnStyles};
    margin-bottom: 85px;
  `,
  ContentWithSlider: styled.div`
    ${baseColumnStyles};

    @media ${desktopMediaQuery} {
      width: 360px;
      overflow-x: hidden;
    }
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 16px;
    margin-top: 8px;
    min-height: 72px;
    display: flex;
    align-items: center;
  `,
  Title2: styled.h2`
    ${baseTitleStyles};
    min-height: 72px;
    display: flex;
    align-items: center;
  `,
  Title3: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 16px;
    margin-top: 16px;
    min-height: 72px;
    display: flex;
    align-items: center;
  `,
  Option: styled(Option)`
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > input:disabled {
      opacity: 0.5;
    }
  `,
  OptionVariant2: styled(OptionVariant2)`
    position: relative;
    min-width: 154px;

    > div {
      border: none;
    }
  `,
  OptionImage: styled(SvgImage)`
    height: 56px;
    margin-right: 16px;
  `,
  OptionImageVariant2: styled(SvgImage)`
    height: 24px;
    margin-right: 16px;
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `,
  OptionsContainer2: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -6px;
  `,
  CatAgeImage: styled(SvgImage)`
    height: 90px;
    width: 90px;
  `,
  QuestionButtonWithImg: styled.div`
    border-radius: 16px;
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid ${Color.BEIGE};

    input:checked + & {
      border: 2px solid ${Color.MEDIUM_GREEN};
    }
  `,
  CatAgeText: styled.p`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
  `,
  OptionSubText: styled.p`
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
    padding-bottom: 32px;
    color: ${Color.PRIMARY_TEXT_SHADOW};
  `,
  CatImage: styled(SvgImage)`
    height: 100px;
    margin-bottom: 24px;
  `,
  AnswerText: styled.p`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 16px;
  `,
  StickyBlockWrapper: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${Color.BEIGE};
    padding-bottom: 50px;
    padding-top: 8px;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
    }
  `,
  StickyBlockWrapper2: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${Color.BEIGE};
    padding-bottom: 56px;
    padding-top: 8px;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
    }
  `,
  Link: styled.a`
    font-size: 14px;
    margin-top: 16px;
    font-weight: 700;
    line-height: 27px;
    text-decoration-line: underline;
    color: ${Color.BROWNISH_GRAY};
  `,
  SubtitleText: styled.h2`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.PRIMARY_TEXT_SHADOW};
    margin-bottom: 16px;
  `,
  Subtitle: styled.h4`
    ${baseTextStyles};
    margin-bottom: 16px;
    color: ${Color.WHITE};
    opacity: 80%;
  `,
  IconWrapper: styled.span`
    padding-right: 16px;
  `,
  SliderWrapper: styled.div`
    margin-bottom: 48px;

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 200px;
      height: 300px;
    }
  `,
  SliderImage: styled.img`
    width: 200px;
    height: inherit;
    border-radius: 24px;
  `,
  WelcomeTitle: styled.h2`
    ${baseTitleStyles};
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  OptionsContainerWelcome: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `,
  QuestionButton: styled.p`
    ${breedButton};
    white-space: nowrap;
    margin-right: 8px;
    color: ${Color.PRIMARY_TEXT};
    background-color: ${Color.WHITE};
    border: 2px solid ${Color.WHITE};

    input:checked + & {
      border: 2px solid ${Color.MEDIUM_GREEN};
    }
  `,
  WelcomeSubtitle: styled.h4`
    ${baseTitleStyles};
    color: ${Color.PRIMARY_TEXT};
    font-weight: 700;
  `,
  WelcomeTitleVariant2: styled.h4`
    font-size: 26px;
    font-weight: 800;
    line-height: 32px;
    color: #854eb3;
    text-align: center;
    margin-bottom: 24px;
  `,
  EmojiImage: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 16px;
  `,
}
