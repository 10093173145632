import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { IConfig } from 'models/config.model'
import { IVariant } from 'models/variant.model'
import { api } from 'services/api/api-service'
import { getConfigFromConfigRaw } from 'helpers/getConfigFromConfigRaw'
import { getVariantFromRawVariant } from 'helpers/getVariantFromRawVariant'
import { TAnswer } from 'models/common.model'
import { AnyAction } from '@reduxjs/toolkit'
import { eventLogger } from 'services/eventLogger-service'
import firebase from 'firebase/compat/app'
import { PageId } from '../../constants/pages'

const MODULE_NAME = 'COMMON'

export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const GET_VARIANT = `${MODULE_NAME}/GET_VARIANT`
export const SET_VARIANT = `${MODULE_NAME}/SET_VARIANT`
export const GET_CONFIG = `${MODULE_NAME}/GET_CONFIG`
export const SET_CONFIG = `${MODULE_NAME}/SET_CONFIG`
export const SET_LANGUAGE = `${MODULE_NAME}/SET_LANGUAGE`
export const SET_OPTIMIZE_VARIANT_ID = `${MODULE_NAME}/SET_OPTIMIZE_VARIANT_ID`
export const SET_IS_LOGGER_INITIALIZED = `${MODULE_NAME}/SET_IS_LOGGER_INITIALIZED`
export const SET_ANSWERS = `${MODULE_NAME}/SET_ANSWERS`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`
export const SET_FIREBASE_REMOTE_CONFIG = `${MODULE_NAME}/SET_FIREBASE_REMOTE_CONFIG`
export const SET_IS_UPSELL_PAID = `${MODULE_NAME}/SET_IS_UPSELL_PAID`

export function startFetching(action: string): IAction<string> {
  return {
    type: START_FETCHING,
    payload: action,
  }
}

export function setConfigAction(payload: IConfig): IAction<IConfig> {
  return {
    type: SET_CONFIG,
    payload,
  }
}

export function setVariantAction(payload: IVariant): IAction<IVariant> {
  return {
    type: SET_VARIANT,
    payload,
  }
}

export function setLanguageAction(language: string): IAction<string> {
  return {
    type: SET_LANGUAGE,
    payload: language,
  }
}

export function setOptimizeVariantIdAction(id: string): IAction<string> {
  return {
    type: SET_OPTIMIZE_VARIANT_ID,
    payload: id,
  }
}

export function setIsLoggerInitialized(payload: boolean): IAction<boolean> {
  return {
    type: SET_IS_LOGGER_INITIALIZED,
    payload,
  }
}

export function setErrorAction<T>(error: T): IAction<T> {
  return {
    type: SET_ERROR,
    payload: error,
  }
}

export function resetErrorAction(): AnyAction {
  return {
    type: RESET_ERROR,
  }
}

export function setFirebaseRemoteConfigAction(
  config: Record<string, firebase.remoteConfig.Value>,
): IAction<Record<string, firebase.remoteConfig.Value> | null> {
  return {
    type: SET_FIREBASE_REMOTE_CONFIG,
    payload: config,
  }
}

export function setIsUpsellPaidAction(payload: boolean): IAction<boolean> {
  return {
    type: SET_IS_UPSELL_PAID,
    payload,
  }
}

export const stopFetching =
  (actionToStop: string): any =>
  (dispatch: TAppDispatchThunk<string[]>, getState: () => IAppState) => {
    const runningActions = getState().common.actionList
    const fetchList = runningActions.filter(
      (action: string) => action && action !== actionToStop,
    )

    dispatch({
      type: STOP_FETCHING,
      payload: fetchList,
    })
  }

export function getConfigAction(): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_CONFIG))

    const response = await api.get('v1/flow/meowz/config')

    if (response.success && response.data) {
      const config = getConfigFromConfigRaw(response.data.config)

      dispatch(setConfigAction(config as IConfig))
    }

    dispatch(stopFetching(GET_CONFIG))
  }
}

export function getVariantAction({
  cohort,
}: {
  cohort: string
}): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_VARIANT))

    const response = await api.get('v1/flow/meowz/variants', {
      params: {
        cohort,
      },
    })

    if (response.success && response.data) {
      const variant = getVariantFromRawVariant(response.data.variant)

      dispatch(setVariantAction(variant))
    }

    dispatch(stopFetching(GET_VARIANT))
  }
}

export function setAnswersAction({
  question,
  answers,
  pageNumber,
  pageName,
  customProperties,
}: {
  question: string
  answers: TAnswer
  pageNumber: number
  pageName: PageId
  customProperties?: Record<string, any>
}): AnyAction {
  eventLogger.logQuestion({
    question: question || pageName,
    answers,
    pageNumber,
    customProperties,
  })

  return {
    type: SET_ANSWERS,
    payload: { [pageName]: answers },
  }
}
