import { getPageById } from 'helpers/getPageById'
import React, { useContext, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { selectCurrentVariantSteps } from 'root-redux/common/common-selects'
import { FlowContext } from 'components/FlowContext'
import { StyledProgressBar as S } from './ProgressBar.styles'

export const ProgressBar: React.FC = () => {
  const { currentPageId } = useContext(FlowContext)
  const steps = useSelector(selectCurrentVariantSteps)

  const allPagesWithProgressBarIds = useMemo(
    () =>
      steps
        .filter(({ id }) => getPageById(id)?.progressBar)
        ?.map(({ id }) => id),
    [steps],
  )
  const controlledPagesWithProgressBarIds = useMemo(
    () =>
      steps
        .filter(({ id }) => getPageById(id)?.progressBar === 'controlled')
        ?.map(({ id }) => id),
    [steps],
  )

  const currentPageIndex = useMemo(() => {
    const pageIndex = allPagesWithProgressBarIds.findIndex(
      (id) => id === currentPageId,
    )

    const getControlledPageIndex = (index: number) => {
      const pageId = allPagesWithProgressBarIds[index]

      if (!pageId) return -1

      const controlledPageIndex = controlledPagesWithProgressBarIds.findIndex(
        (id) => id === pageId,
      )

      if (controlledPageIndex >= 0) return controlledPageIndex
      if (index - 1 < 0) return -1

      return getControlledPageIndex(index - 1)
    }

    return getControlledPageIndex(pageIndex)
  }, [
    controlledPagesWithProgressBarIds,
    currentPageId,
    allPagesWithProgressBarIds,
  ])

  const pageProps = useMemo(
    () => getPageById(controlledPagesWithProgressBarIds[currentPageIndex]),
    [getPageById, controlledPagesWithProgressBarIds, currentPageIndex],
  )

  return currentPageIndex < 0 || pageProps?.progressBarHidden
    ? null
    : ReactDOM.createPortal(
        <S.Wrapper hasHeader={!!pageProps?.hasHeader}>
          <S.Progress
            max={controlledPagesWithProgressBarIds.length}
            value={currentPageIndex + 1}
          />
          <S.ProgressText>
            {currentPageIndex + 1}/{controlledPagesWithProgressBarIds.length}
          </S.ProgressText>
        </S.Wrapper>,
        document.getElementById('root') as HTMLElement,
      )
}
