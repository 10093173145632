import React, { ButtonHTMLAttributes } from 'react'
import { StyledButton } from './Button.styles'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonRef?: React.Ref<HTMLButtonElement>
}

export const Button: React.FC<IProps> = ({
  children,
  buttonRef = null,
  ...props
}) => (
  <StyledButton ref={buttonRef} {...props}>
    {children}
  </StyledButton>
)
