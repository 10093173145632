import styled from 'styled-components'
import { Color } from 'root-constants'
import { Button } from 'components/buttons/Button'
import {
  baseColumnStyles,
  basePageStyles,
  baseTextStyles,
  baseTitleStyles,
  fullViewHeight,
} from 'pages/styles'

export const StyledUrge = {
  UrgeWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: #854eb3;
    padding-top: 36px;
  `,
  UrgeWrapper2: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.MEDIUM_GREEN};
    padding-top: 36px;
  `,
  UrgeContent: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    line-height: normal;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 8px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  Subtitle: styled.h4`
    ${baseTextStyles};
    margin-bottom: 16px;
    color: ${Color.WHITE};
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
  `,
  UrgeImage: styled.img`
    margin-bottom: 30px;
  `,
  WhiteButton: styled(Button)`
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    margin: 4px 16px 20px;
    box-shadow: none;
    max-width: 328px;

    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ButtonText: styled.div`
    width: 100%;
  `,
}
