import styled from 'styled-components'
import { Color } from 'root-constants'
import { fullViewHeight } from 'pages/styles'

type TStyledModalProps = {
  isShown: boolean
}

export const StyledComponents = {
  Wrapper: styled.div<TStyledModalProps>`
    ${fullViewHeight};
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 10 : -1)};
    width: max(100%, var(--min-screen-width));
    background-color: rgba(0, 0, 0, 0.7);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition: opacity 0.2 ease-out;
  `,
  Content: styled.div`
    max-width: 360px;
    margin: auto;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 16px;
    background-color: ${Color.WHITE};
  `,
  ShelterCatImage: styled.img`
    width: 100%;
    border-radius: 16px;
  `,
  Title: styled.span`
    font-size: 24px;
    line-height: 48px;
    font-weight: 800;
    color: ${Color.PRIMARY_TEXT};
    text-align: center;
  `,
  Subtitle: styled.span`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.PRIMARY_TEXT_SHADOW};
    text-align: center;
    margin-bottom: 8px;
  `,
}
