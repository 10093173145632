import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GENERAL_QUESTIONS_OPTIONS_VALUES } from 'root-constants'

import shelterCat from 'assets/images/sprite/shelter-cat.svg'
import usualCat from 'assets/images/sprite/usual-cat.svg'
import { ShelterModal } from 'pages/questions/modals/ShelterModalMessage'
import { PageId } from 'src/constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'Did you adopt your cat from a shelter?'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const [isModalShown, setIsModalShown] = useState(false)
  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )
      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId],
  )

  const openModal = useCallback(() => {
    setIsModalShown(true)
  }, [])

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Title>
            Did you adopt your cat
            <br /> from a shelter?
          </S.Title>
          <S.OptionsContainer data-is-multiple>
            <S.Option
              {...optionProps}
              onChange={openModal}
              value={GENERAL_QUESTIONS_OPTIONS_VALUES.YES}
            >
              <S.QuestionButtonWithImg>
                <S.CatImage svg={shelterCat} />
                <S.AnswerText>Yes</S.AnswerText>
              </S.QuestionButtonWithImg>
            </S.Option>
            <S.Option
              {...optionProps}
              value={GENERAL_QUESTIONS_OPTIONS_VALUES.NO}
            >
              <S.QuestionButtonWithImg>
                <S.CatImage svg={usualCat} />
                <S.AnswerText>No</S.AnswerText>
              </S.QuestionButtonWithImg>
            </S.Option>
          </S.OptionsContainer>
        </S.Content>
      </S.Wrapper>

      <ShelterModal
        onClose={() => {
          setIsModalShown(false)
          handleContinue(GENERAL_QUESTIONS_OPTIONS_VALUES.YES)
        }}
        isShown={isModalShown}
      />
    </>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.SHELTER_CAT

export const ShelterQuestion = Component
