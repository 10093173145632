import { IPaymentConfig, IPaymentConfigRaw } from 'models/payment.model'

export const getPaymentConfigFromRawData = (
  paymentConfig: IPaymentConfigRaw,
): IPaymentConfig => ({
  stripe: {
    accountId: paymentConfig.stripe.account_id,
    accountName: paymentConfig.stripe.account_name,
    publicKey: paymentConfig.stripe.public_key,
  },
  paypal: {
    clientId: paymentConfig.paypal.client_id,
  },
  payment_methods: {
    card: paymentConfig.payment_methods.card,
    applePay: paymentConfig.payment_methods.apple_pay,
    googlePay: paymentConfig.payment_methods.google_pay,
    paypal: paymentConfig.payment_methods.paypal,
  },
})
