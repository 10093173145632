import { Option, OptionType } from 'components/option/Option'
import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import discount from 'assets/images/sprite/discount-label-65.svg'
import { ISubscription } from 'models/suscriptions.model'
import { selectSubscription } from 'root-redux/payment/payment-selects'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { StyledPlanBlock as S } from './PlanBlock.styles'

type TProps = {
  onButtonClick: () => void
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<React.PropsWithChildren<ITrialSelectPlanItemProps>>
  availableSubscriptions: ISubscription[]
  isNonPayerFlow: boolean
}

export const SelectPlanBlockVariant3: React.FC<
  React.PropsWithChildren<TProps>
> = ({
  onButtonClick,
  onSelect,
  SelectPlanItem,
  availableSubscriptions,
  isNonPayerFlow,
}) => {
  const selectedSubscription = useSelector(selectSubscription)

  const isSubscriptionSelected = useCallback(
    (subscription) => selectedSubscription?.id === subscription.id,
    [selectedSubscription?.id],
  )

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = availableSubscriptions.find(
        (subscription) => subscription.id === value,
      )
      onSelect(checkedSubscription)
    },
    [onSelect, availableSubscriptions],
  )

  return (
    <S.SelectPlanCardVariant2 id={SUBSCRIPTION_SECTION_IDS.chooseMyPlan}>
      <S.CardTitle id={SUBSCRIPTION_SECTION_IDS.interestingFact}>
        Choose Your Plan
      </S.CardTitle>
      <S.PlanItems>
        {availableSubscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
          >
            <SelectPlanItem
              subscription={subscription.trialPrices}
              isSelected={isSubscriptionSelected(subscription)}
              isDefault={subscription.isDefault}
              id={subscription.id}
              isNonPayerFlow={isNonPayerFlow}
            />
          </Option>
        ))}
      </S.PlanItems>
      <S.PlanButtonWrapper>
        <S.DiscountImage svg={discount} />
        <GreenArrowButton onClick={onButtonClick} text="Get My Plan" />
      </S.PlanButtonWrapper>
    </S.SelectPlanCardVariant2>
  )
}
