import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUUID } from 'root-redux/user/user-selects'

const PROD_PIXEL_ID = '1490417521843611'
const DEV_PIXEL_ID = '491882293438984'

export const useFacebookPixelInitialization = (): void => {
  const uuid = useSelector(selectUUID)
  const env = getCurrentEnv()
  const facebookPixelId = useMemo(
    () => (env === 'prod' ? PROD_PIXEL_ID : DEV_PIXEL_ID),
    [env],
  )
  useLayoutEffect(() => {
    window.fbq('init', facebookPixelId, { external_id: uuid })
    window.fbq('track', 'PageView')
  }, [facebookPixelId, uuid])
}
