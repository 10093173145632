import styled from 'styled-components'

export const StyledTotalAmount = {
  TotalPrice: styled.div`
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;

    & > strong {
      font-weight: 600;
      color: rgb(53, 180, 63);
    }
  `,
}
