import styled from 'styled-components'
import { fullViewHeight } from 'pages/styles'

export const StyledOuterLinkIframe = styled.iframe`
  ${fullViewHeight};
  position: fixed;
  background-color: white;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
`
