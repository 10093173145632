import { Color } from 'root-constants'
import styled from 'styled-components'

export const PaymentRequestOptions = {
  Divider: styled.div`
    width: inherit;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    overflow: hidden;
  `,
  DividerText: styled.div`
    position: relative;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    padding: 0 12px;
    color: rgba(74, 53, 50, 0.4);

    ::before,
    ::after {
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: 160px;
      top: 8px;
      background-color: rgba(74, 53, 50, 0.1);
    }

    ::before {
      right: 100%;
    }

    ::after {
      left: 100%;
    }
  `,
  PaymentMethodWrapper: styled.div`
    padding: 0 0 16px;
    width: 100%;
  `,
  CustomPaymentButton: styled.button`
    height: 50px;
    width: 100%;
    background-color: ${Color.BLACK};
    border-radius: 40px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
