import styled, { css } from 'styled-components'
import { Color } from 'root-constants'
import { Link } from 'react-router-dom'

const CommonTextStyles = css`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${Color.TEXT_SECONDARY};
`
export const MIN_WIDTH = 360
const BASE_COLUMN_WIDTH = 1024

const Column = styled.div`
  max-width: ${MIN_WIDTH}px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${BASE_COLUMN_WIDTH}px) {
    max-width: ${BASE_COLUMN_WIDTH}px;
  }
`

export const H2 = styled.h2`
  font-weight: 900;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.5px;

  @media (min-width: ${BASE_COLUMN_WIDTH}px) {
    font-size: 48px;
  }
`

export const StyledPolicyPages = {
  Wrapper: styled.article`
    padding: 24px 0 40px;

    @media (min-width: ${BASE_COLUMN_WIDTH}px) {
      padding: 40px 0 80px;
    }
  `,
  Column: styled(Column)`
    ${CommonTextStyles};
    position: relative;
    width: 100%;
    padding: 0 10px;

    & p {
      margin: 16px 0;
    }

    @media (min-width: ${BASE_COLUMN_WIDTH}px) {
      & p {
        margin: 16px 0;
      }
    }
  `,
  H2: styled(H2)`
    margin-bottom: 16px;
    color: ${Color.RAISIN_BLACK};

    @media (min-width: ${BASE_COLUMN_WIDTH}px) {
      display: inline-block;
      margin-right: 68px;
    }
  `,
  H3: styled.h3`
    font-weight: 800;
    color: ${Color.RAISIN_BLACK};
    font-size: 36px;
    line-height: 1.4;
    margin: 40px 0 16px;
  `,
  Subtitle: styled.h3`
    font-weight: 800;
    color: ${Color.RAISIN_BLACK};
    font-size: 36px;
    line-height: 1.4;
    margin: 16px 0;
  `,
  PlainList: styled.ul`
    & > li {
      margin-bottom: 16px;
    }
  `,
  PlainList2: styled.ul`
    & > li {
      margin-bottom: 16px;
      margin-left: 32px;
    }
  `,
  OffsetList: styled.ul`
    list-style-type: disc;

    & > li {
      & > p {
        margin: 0;
      }
    }

    @media (min-width: ${BASE_COLUMN_WIDTH}px) {
      margin-left: 30px;
    }
  `,
  LatinList: styled.ul`
    list-style-type: none;

    & > li {
      margin-bottom: 16px;
    }

    & > li::before {
      content: counter(list-item, lower-latin) ') ';
    }
  `,
  LatinList2: styled.ul`
    list-style-type: lower-latin;

    & > li {
      margin-bottom: 16px;
      margin-left: 18px;
    }
  `,
  LatinList3: styled.ul`
    list-style-type: none;

    & > li {
      margin-bottom: 16px;
    }

    & > li::before {
      content: '(' counter(list-item, lower-latin) ') ';
    }
  `,
  NestedLatinList: styled.ol`
    list-style-type: none;

    & > li {
      margin-bottom: 16px;
    }
  `,
  Text: styled.span`
    color: ${Color.RAISIN_BLACK};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
  `,
  TableText: styled.span`
    color: ${Color.RAISIN_BLACK};
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid ${Color.GREY};
      text-align: start;
    }

    th {
      min-width: 200px;
      vertical-align: center;
    }

    td {
      vertical-align: top;
      padding: 16px 16px 16px 17px;
    }
  `,
  TableHeader: styled.tr`
    height: 60px;
    text-transform: uppercase;
    background-color: ${Color.SHADOW_GREY};

    th {
      padding-left: 16px;
    }
  `,
  NumericList: styled.ol`
    list-style: decimal;

    li {
      margin-left: 18px;
    }
  `,
  NumericListWIthSpase: styled.ol`
    list-style: decimal;

    li {
      margin-left: 28px;
      margin-bottom: 16px;
    }
  `,
  PointList: styled.ul`
    list-style-type: disc;

    li {
      margin-left: 18px;
    }
  `,
  Link: styled.a`
    color: ${Color.APPLE};
  `,
  GreyLink: styled.a`
    all: unset;
    text-decoration: underline;
    cursor: pointer;
  `,
  InnerLink: styled(Link)`
    color: ${Color.APPLE};
    text-decoration: underline;
  `,
  PolicyLinks: styled(Link)`
    color: ${Color.APPLE};
  `,
  PolicyParagraph: styled.p`
    br {
      display: none;
    }

    @media (max-width: ${BASE_COLUMN_WIDTH}px) {
      br {
        display: flex;
      }
    }
  `,
  EffectiveDate: styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 25px;
  `,
  TableWrapper: styled.div`
    width: 100%;
    overflow-x: scroll;
  `,
  FlexContainer: styled.div`
    display: flex;
    gap: 24px;
    margin-bottom: 34px;
  `,
  FlexItem: styled.div`
    flex: 1;

    p {
      margin: 0;
    }

    @media (max-width: ${BASE_COLUMN_WIDTH}px) {
      width: 110px;
      word-break: break-word;
      display: block;
    }
  `,
  FlexLink: styled.div`
    flex: 1;

    p {
      margin: 0;
    }

    @media (max-width: ${BASE_COLUMN_WIDTH}px) {
      width: 110px;
      word-break: break-word;
      display: flex;
    }
  `,
}
