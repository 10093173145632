import React from 'react'
import { StyledComponents as S } from 'modules/upsell/styles'
import starsSvg from 'assets/images/sprite/question.svg'
import { useTranslation } from 'react-i18next'
import { ISvg } from 'components/SvgImage'

export interface IProps {
  isVisible: boolean
}

export const OfferDetails: React.FC<IProps> = ({ isVisible }) => {
  const { t } = useTranslation()

  return (
    <S.OfferWrapper id="details" isVisible={isVisible}>
      <S.ImgWrapper>
        <S.QuestionImg svg={starsSvg as ISvg} />
      </S.ImgWrapper>
      <S.OfferInfo>
        <S.Subtitle>{t`upsell.whatYouLearn`}</S.Subtitle>
        <S.Block>
          <S.Point>{t`upsell.item1`}</S.Point>
          <S.Point>{t`upsell.item2`}</S.Point>
          <S.Point>{t`upsell.item3`}</S.Point>
          <S.Point>{t`upsell.item4`}</S.Point>
          <S.Point>{t`upsell.item5`}</S.Point>
          <S.Point>{t`upsell.item6`}</S.Point>
        </S.Block>
      </S.OfferInfo>
    </S.OfferWrapper>
  )
}
