import styled from 'styled-components'
import { baseColumnStyles, basePageStyles } from 'pages/styles'

export const StyledPayment = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    margin-top: 15px;
    text-align: left;
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;

    @supports (height: 100dvh) {
      height: calc(var(--full-height, 100dvh) - 50px) !important;
    }
  `,
}
