import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendUserTiktokClidAction } from 'root-redux/user/user-actions'
import { selectEmail } from 'root-redux/user/user-selects'

export const useTiktokPixelInitialization = (): void => {
  const email = useSelector(selectEmail)
  const dispatch = useDispatch()

  useEffect(() => {
    window.ttq.load('CSB4QC3C77U33SRLLTS0')
    window.ttq.identify({ email })
    window.ttq.page()
    // Disabled to avoid sending Page View event second time after entering email
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const searchParams = new URLSearchParams(document.location.search)
    const ttclid = searchParams.get('ttclid')

    if (ttclid) dispatch(sendUserTiktokClidAction(ttclid))
  }, [])
}
