import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { eventLogger, ScreenName } from 'services/eventLogger-service'
import { selectEmail } from 'root-redux/user/user-selects'
import { Spinner } from 'components/spinner/Spinner'
import { createProductId } from 'helpers/createProductId'
import {
  select3DSecureIframeUrl,
  selectFullPrice,
  selectIsPaymentFlowsShown,
  selectPeriodName,
  selectPeriodQuantity,
  selectStripePublicKey,
} from 'root-redux/payment/payment-selects'
import {
  selectIsCancelOfferApplied,
  selectOptimizeExperimentId,
  selectOptimizeSegmentName,
  selectOptimizeVariantId,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { StyledPayment as S } from './Payment.styles'
import { STRIPE_ELEMENTS_OPTIONS } from '../constants'
import { PaymentFlowsVariantCs } from '../components/PaymentFlowsVariantCs'
import { StripePaymentProcessingCs } from '../components/payment-processing/StripePaymentProcessingCs'

export const PaymentVariantCs: React.FC = () => {
  const periodName = useSelector(selectPeriodName)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const price = useSelector(selectFullPrice)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const optimizeVariantId = useSelector(selectOptimizeVariantId)
  const optimizeExperimentId = useSelector(selectOptimizeExperimentId)
  const optimizeSegmentName = useSelector(selectOptimizeSegmentName)
  const currentCohort = useSelector(selectVariantCohort)
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)
  const stripePublicKey = useSelector(selectStripePublicKey)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const email = useSelector(selectEmail)
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null)

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        cohort: currentCohort,
      })

      eventLogger.logPaymentMethodPageShown({
        cohort: currentCohort,
        screenName: isCancelOfferApplied ? ScreenName.CANCEL : ScreenName.SALES,
        email,
      })
      window.ttq.identify({ email })
      window.ttq.track('InitiateCheckout', {
        content_id: productId,
      })
    }
  }, [
    productId,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    currentCohort,
    isCancelOfferApplied,
    email,
  ])

  useEffect(() => {
    if (stripePublicKey) {
      // loadStripe should be called once
      setStripePromise(loadStripe(stripePublicKey))
    }
  }, [stripePublicKey])

  return (
    <Elements stripe={stripePromise} options={STRIPE_ELEMENTS_OPTIONS}>
      <S.Wrapper>
        {threeDSecureIframeUrl ? (
          <S.ThreeDSecureIframe
            title="3DSecure"
            src={threeDSecureIframeUrl}
            scrolling="yes"
          />
        ) : (
          <>
            <StripePaymentProcessingCs />
            <S.Content>
              <PaymentFlowsVariantCs />
            </S.Content>
          </>
        )}
        {!isPaymentFlowsShown && <Spinner />}
      </S.Wrapper>
    </Elements>
  )
}
