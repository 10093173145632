import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import arrowBounce from 'assets/animation/arrowBounce.json'
import fantasticCat from 'assets/images/fantastic-cat.jpg'
import arrow from 'assets/images/sprite/dark-arrow.svg'
import { SvgImage } from 'components/SvgImage'
import lottie from 'lottie-web/build/player/lottie_light'
import { useTranslation } from 'react-i18next'
import { PageId } from 'src/constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledUrge as S } from './Urge.styles'

const Component: TDynamicPageComponent = () => {
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: arrowBounce,
      loop: true,
    })
  }, [])

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep])

  return (
    <S.UrgeWrapper>
      <S.UrgeContent>
        <S.UrgeImage src={fantasticCat} alt="cat" />
        <S.Title>
          Fantastic! Let’s draw up the
          <br /> personal plan for your cat
        </S.Title>
        <S.Subtitle>
          Please be honest with us. It helps us to
          <br /> best customize your plan.
        </S.Subtitle>
        <div ref={animationContainerRef} style={{ width: 30 }} />
        <S.WhiteButton onClick={handleContinue}>
          <S.ButtonText>{t`actions.continue`}</S.ButtonText>
          <SvgImage svg={arrow} width={24} />
        </S.WhiteButton>
      </S.UrgeContent>
    </S.UrgeWrapper>
  )
}

Component.id = PageId.URGE_2

export const UrgeFantasticVariant2 = Component
