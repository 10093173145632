import styled from 'styled-components'

export const StyledBenefits = {
  Wrapper: styled.div`
    padding: 24px;
    width: calc(100% - 4px);
    background: #f4f7ff;
    border: 4px solid #6840bc;
    text-align: center;
    border-radius: 40px;
    margin-top: -2px;
    z-index: 1;
    margin-bottom: 91px;
  `,
  Title: styled.h2`
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 24px;
  `,
  BenefitsContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;
  `,
  BenefitItem: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  Circle: styled.div<{ bgColor: string }>`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 26px;
    background: ${({ bgColor }) => bgColor};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  BenefitItemText: styled.h4`
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-left: 12px;
  `,
  EmojiImage: styled.img`
    width: 32px;
    height: 32px;
  `,
}
