import label1 from 'assets/images/download-label-1.svg'
import label2 from 'assets/images/download-label-2.svg'
import labelPdf1 from 'assets/images/download-one.svg'
import labelPdf2 from 'assets/images/download-two.svg'
import { Color, MediaBreakpoint } from 'root-constants'
import styled from 'styled-components'
import { StyledButtonBase } from 'components/buttons/Button.styles'
import { SvgImage } from 'components/SvgImage'
import { Link } from 'react-router-dom'
import {
  baseColumnStyles,
  baseMediaStyles,
  basePageStyles,
  baseTextStyles,
  baseTitleStyles,
  desktopMediaQuery,
} from '../styles'

export const StyledDownloadAppPage = {
  Wrapper: styled.div`
    ${basePageStyles};
    overflow: hidden;
  `,
  Column: styled.main`
    ${baseColumnStyles};
    padding: 40px 0 38px;

    @media ${desktopMediaQuery} {
      padding-top: 64px;
    }
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;
  `,
  List: styled.ul`
    margin-bottom: 8px;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 16px;
    }
  `,
  ListLabel: styled.div`
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-right: 8px;

    &[data-number='1'] {
      background-image: url(${label1});
    }

    &[data-number='2'] {
      background-image: url(${label2});
    }
  `,
  ListLabel2: styled.div`
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-right: 8px;

    &[data-number='1'] {
      background-image: url(${labelPdf1});
    }

    &[data-number='2'] {
      background-image: url(${labelPdf2});
    }
  `,
  ListText: styled.p`
    ${baseTextStyles};
    text-align: start;

    strong {
      font-weight: 600;
      color: #7879f1;
      text-decoration: underline;
    }
  `,
  DownloadButton: styled.button`
    ${StyledButtonBase};
    margin-bottom: 8px;
  `,
  Footer: styled.footer`
    ${baseMediaStyles};
    position: fixed;
    bottom: 28px;

    @media ${desktopMediaQuery} {
      position: relative;
      inset: 0;
    }
  `,
  AnimationContainer: styled.div`
    width: 324px;
    height: auto;
    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 214px;
    }
    @media ${desktopMediaQuery} {
      width: 100%;
      margin-bottom: 28px;
    }
  `,
  CatImage: styled.img`
    width: 213px;
    height: 240px;
    margin: 27px 0;
  `,
  PDFWrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    text-align: center;
    background-color: ${Color.PURPLE};
    color: ${Color.WHITE};
    border-radius: 40px;
    padding: 24px 16px;
    gap: 16px;
  `,
  PDFInfo: styled.div`
    ${baseTitleStyles};
    display: flex;
    text-align: start;
    gap: 2px;
  `,
  PDFText: styled.div`
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
  `,
  PDFImg: styled.img`
    width: 60px;
    height: 70px;
  `,
  Column2: styled.main`
    ${baseColumnStyles};
    padding: 0 0 38px;
    gap: 16px;
  `,
  ButtonAsLink: styled.button`
    ${StyledButtonBase};
    box-shadow: unset;
  `,
  DownloadPdfButton: styled.button`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 20px;
    cursor: pointer;
    border: none;
    border-radius: 40px;
    outline: none;
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
    text-decoration: none;
    text-align: center;
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    border: 1px solid #979797;
  `,
  DownloadImg: styled(SvgImage)`
    width: 24px;
    height: 24px;
  `,
  DownloadText: styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
  `,
  PrivacyNote: styled.div`
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  `,
  Link: styled(Link)`
    all: unset;
    text-decoration: underline;
  `,
}
