import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web/build/player/lottie_light'
import meowzChart from 'assets/animation/meowzChart.json'
import phoneImg from 'assets/images/phone-cat.jpg'
import { StyledBlock as S } from './DidYouKnowBlock.styles'

export const DidYouKnowBlockCs: React.FC = () => {
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: meowzChart,
      loop: true,
    })
  }, [])

  return (
    <S.Wrapper2>
      <S.PhoneImg2 src={phoneImg} />
      <S.PersonalPlanText>
        <strong>The popular goods</strong>
        according to your cat&apos;s
        <br />
        preferences and needs
      </S.PersonalPlanText>
    </S.Wrapper2>
  )
}
