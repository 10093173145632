import { ISubscription } from 'models/suscriptions.model'
import { availableSupplies } from './constants'

export const getDecoratedTimerValue = (valueInSeconds: number): string => {
  const minutes = Math.trunc(valueInSeconds / 60)
  const seconds = valueInSeconds - minutes * 60
  const get2DigitValue = (value: number): string =>
    value.toString().padStart(2, '0')

  return `${get2DigitValue(minutes)}:${get2DigitValue(seconds)}`
}

export const getAnswer = (value: string | string[] | undefined): string =>
  Array.isArray(value) ? value.join(', ') : value || ''

export const getAvailableSupplies = (
  availableSubscriptions: ISubscription[],
  isOtp?: boolean,
) =>
  availableSupplies.map((block) => ({
    ...block,
    data: block.data.map((item) => {
      const { trialPrices, mainPrices, id } =
        availableSubscriptions.find(
          (subscription) => subscription.product === item.product,
        ) || {}

      const prices = isOtp ? trialPrices : mainPrices

      return {
        ...item,
        fullPrice: prices?.fullPrice,
        oldPrice: prices?.oldPrices?.fullPrice,
        id,
      }
    }),
  }))
