import closeSvg from 'assets/images/sprite/close-button.svg'
import React, { ButtonHTMLAttributes } from 'react'
import { StyledCloseButton as S } from './CloseButton.styles'

type TProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: () => void
}

export const CloseButton: React.FC<TProps> = ({ onClick, ...props }) => (
  <S.CloseButton
    {...props}
    onClick={onClick}
    role="button"
    aria-label="Close button"
  >
    <S.Image svg={closeSvg} />
  </S.CloseButton>
)
