export const SEX_OPTIONS = {
  GIRL: 'girl',
  BOY: 'boy',
}

export const AGE_OPTIONS_VALUES = {
  KITTEN: 'kitten',
  ADOLESCENT: 'adolescent',
  ADULT: 'adult',
  SENIOR: 'senior',
  KITTEN_GROUP: 'kitten_group',
  ADOLESCENT_GROUP: 'adolescent_group',
  ADULT_GROUP: 'adult_group',
  SENIOR_GROUP: 'senior_group',
}

export const REACT_TO_NEW_PEOPLE_OPTIONS_VALUES = {
  HIDES: 'hidesImmediately',
  OBSERVE: 'observesFromDistance',
  CAUTIOUS: 'approachesCautiously',
  CURIOUS: 'curiousAndFriendly',
  OTHER: 'other',
}

export const NOISE_RESPONSE_OPTIONS_VALUES = {
  HIDES: 'extremelyStartledAndHides',
  ANXIOUS: 'getSlightlyAnxious',
  UNFAZED: 'remainsMostlyUnfazed',
  CURIOUS: 'curiousAboutTheNoiseSource',
  OTHER: 'other',
}

export const TOYS_OPTIONS_VALUES = {
  NO_INTEREST: 'noInterest',
  PLAYS_OCCASIONALLY: 'playsOccasionally',
  ENJOYS: 'enjoysVarietyOfToys',
  OBSESSED: 'obsessed',
  OTHER: 'other',
}

export const GROOMING_OPTIONS_VALUES = {
  RESISTS: 'resistsGroomingVigorously',
  TOLERATES: 'toleratesGrooming',
  CALM: 'calmlyAccepts',
  ENJOY: 'enjoyGrooming',
  OTHER: 'other',
}

export const TOUCH_REACT_OPTIONS_VALUES = {
  NEGATIVELY: 'reactsNegatively',
  FIDGETING: 'toleratesWithFidgeting',
  ENJOY: 'enjoy',
  DEMAND: 'demandAttention',
  OTHER: 'other',
}

export const BEHAVIOR_ISSUES_OPTIONS_VALUES = {
  NO_ISSUES: 'noIssues',
  SCRATCHING: 'scratching',
  LITTER_BOX_ISSUES: 'litterBoxIssues',
  AGGRESSION: 'aggression',
  EXCESSIVE_MEOWING: 'excessiveMeowing',
  NIGHTTIME_ACTIVITY: 'nighttimeActivity',
  SEPARATION_ANXIETY: 'separationAnxiety',
  SOCIALIZATION: 'socialization',
  STEALING_FOOD: 'stealingFood',
}

export const CLICKER_TRAINED_OPTIONS_VALUES = {
  NOT_FAMILIAR: 'notFamiliar',
  FAMILIAR: 'familiar',
  TRAINED: 'clickerTrained',
}

export const MEDICAL_CONDITION_OPTIONS_VALUES = {
  NO_MEDICAL_CONDITIONS: 'noMedicalConditions',
  MILD: 'mildConditions',
  MODERATE_TO_SEVERE: 'ModerateToSevere',
  IDK: 'idk',
}

export const ACTIVITY_OPTIONS_VALUES = {
  CHASING_TOYS: 'chasingToys',
  CLIMBING: 'climbing',
  PUZZLES: 'puzzlesAndFoodDispensingToys',
  PLAY_WITH_HUMANS: 'playWithHumans',
  OTHER: 'other',
}

export const GOALS_OPTIONS_VALUES = {
  BEHAVIOUR_PROBLEMS: 'behaviorProblems',
  NEW_TRICKS: 'newTricks',
  CAT_PSYCHOLOGY: 'catPsychology',
  HOME_ADAPTATION: 'homeAdaptation',
  SEPARATION_ANXIETY: 'separationAnxiety',
  BECOME_BEST_PARTNER: 'becomeBestPartner',
  OTHER: 'other',
}

export const TIME_OPTIONS_VALUES = {
  TEN_FIFTEEN_MINUTES: 'tenFifteenMinutes',
  FIFTEEN_TWENTY_MINUTES: 'fifteenTwentyMinutes',
  TWENTY_THIRTY_MINUTES: 'twentyThirtyMinutes',
  MORE_THAN_THIRTY_MINUTES: 'moreThanThirtyMinutes',
}

export const BODY_LANGUAGE_OPTIONS_VALUES = {
  QUITE_WELL: 'quiteWell',
  BASIC: 'basicUnderstanding',
  CHALLENGING: 'challengingToUnderstand',
  NO_IDEA: 'noIdea',
}
