import { Amplitude } from 'services/amplitude-service'
import { CurrentEnvironment, EventLoggerInstanceName } from 'root-constants'
import { eventLogger } from 'services/eventLogger-service'
import { getCurrentEnv } from './getCurrentEnv'

export const addAmplitudeLogger = ({
  amplitudeApiKey,
  uuid,
  URLSearchString,
  cohort,
}: {
  amplitudeApiKey: string
  uuid: string
  URLSearchString: string
  cohort: string
}): void => {
  const URLParams = new URLSearchParams(URLSearchString)
  const utmTags = Object.fromEntries(URLParams.entries())
  const isProdEnvironment = getCurrentEnv() === CurrentEnvironment.PROD

  const amplitudeService = new Amplitude({
    apiKey: amplitudeApiKey,
    instanceName: EventLoggerInstanceName.AMPLITUDE,
    userId: uuid,
    utmTags,
    isProdEnvironment,
  })

  amplitudeService.configure({ cohort })
  eventLogger.addLoggers([amplitudeService])
}
