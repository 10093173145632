import React from 'react'
import { TERMS_OF_USE_LINKS } from 'root-constants'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { OuterLink } from './outer-link/OuterLink'

export const TermsOfUseLink: React.FC<React.PropsWithChildren> = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const env = getCurrentEnv()

  return <OuterLink href={TERMS_OF_USE_LINKS[env]}>{children}</OuterLink>
}
