import { getPageById } from 'helpers/getPageById'
import React, { useCallback, useContext, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { FlowContext } from 'components/FlowContext'
import { COHORT_MAP } from 'components/progress-bar/constants'
import { useTranslation } from 'react-i18next'
import {
  selectCurrentVariantSteps,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { StyledProgressBarVariant2 as S } from './SeparatedProgressBar.styles'

export const SeparatedProgressBar: React.FC = () => {
  const { currentPageId } = useContext(FlowContext)
  const steps = useSelector(selectCurrentVariantSteps)
  const cohort = useSelector(selectVariantCohort)
  const progressBarConfig = useMemo(() => COHORT_MAP[cohort] || [], [cohort])
  const { t } = useTranslation()

  const allPagesWithProgressBarIds = useMemo(
    () =>
      steps
        .filter(({ id }) => getPageById(id)?.progressBar)
        ?.map(({ id }) => id),
    [steps],
  )

  const controlledPagesWithProgressBarIds = useMemo(
    () =>
      steps
        .filter(({ id }) => getPageById(id)?.progressBar === 'controlled')
        ?.map(({ id }) => id),
    [steps],
  )
  const currentPageIndex = useMemo(() => {
    const pageIndex = allPagesWithProgressBarIds?.length
      ? allPagesWithProgressBarIds.findIndex((id) => id === currentPageId)
      : -1

    const getControlledPageIndex = (index: number) => {
      if (
        !allPagesWithProgressBarIds?.length ||
        !allPagesWithProgressBarIds[index]
      ) {
        return -1
      }

      const pageId = allPagesWithProgressBarIds[index]

      const controlledPageIndex = controlledPagesWithProgressBarIds?.length
        ? controlledPagesWithProgressBarIds.findIndex((id) => id === pageId)
        : 0

      if (controlledPageIndex >= 0) return controlledPageIndex
      if (index - 1 < 0) return -1

      return getControlledPageIndex(index - 1)
    }

    return getControlledPageIndex(pageIndex)
  }, [
    controlledPagesWithProgressBarIds,
    currentPageId,
    allPagesWithProgressBarIds,
  ])

  const activeProgressBar = useMemo(
    () =>
      progressBarConfig.find(
        (item) =>
          currentPageIndex + 1 >= item.startIndex &&
          currentPageIndex + 1 < item.startIndex + item.pages,
      ),
    [currentPageIndex, progressBarConfig],
  )

  const getCurrentValue = useCallback(
    (pages, startIndex): number => {
      if (currentPageIndex + 1 > startIndex + pages) {
        return pages
      }

      return currentPageIndex + 1 >= startIndex
        ? currentPageIndex + 2 - startIndex
        : 0
    },
    [currentPageIndex],
  )

  return currentPageIndex < 0 || !activeProgressBar
    ? null
    : ReactDOM.createPortal(
        <S.Wrapper>
          <S.ProgressText style={{ color: activeProgressBar.color }}>
            {t(activeProgressBar?.title)}&nbsp;
            {currentPageIndex + 2 - activeProgressBar.startIndex}/
            {activeProgressBar.pages}
          </S.ProgressText>
          <S.ProgressWrapper>
            {progressBarConfig &&
              progressBarConfig.map((item, index) => (
                <div key={item.title}>
                  <S.Progress
                    isEnd={index === progressBarConfig.length - 1}
                    isStart={index === 0}
                    color={item.color}
                    max={item.pages}
                    value={getCurrentValue(item.pages, item.startIndex)}
                  />
                </div>
              ))}
          </S.ProgressWrapper>
        </S.Wrapper>,
        document.getElementById('root') as HTMLElement,
      )
}
