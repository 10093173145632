import closedEyeIcon from 'assets/images/close-eye.svg'
import openedEyeIcon from 'assets/images/open-eye.svg'
import warningIcon from 'assets/images/warning.svg'
import clearIcon from 'assets/images/input-clean.svg'

import { Color } from 'root-constants'
import styled from 'styled-components'

export const StyledInput = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 20px;

    &::after {
      display: block;
      position: absolute;
      left: 0;
      font-size: 10px;
      line-height: 14px;
      font-weight: 400;
    }

    &[data-is-valid='false']::after {
      content: attr(data-validation-text);
      color: rgba(${Color.ERROR_RGB});
    }

    &[data-is-valid='true']::after {
      content: attr(data-hint-text);
      color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.6);
    }
  `,
  Input: styled.input`
    margin: 0;
    border: 1px solid transparent;
    border-radius: 8px;
    outline: none;

    display: block;
    width: 100%;
    padding: 14px 40px 14px 12px;
    color: rgb(${Color.PRIMARY_TEXT_RGB});
    //background-color: #fffaf7;
    caret-color: #35b43f;
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    transition: border-color 0.2s ease-out;

    &::placeholder {
      font-weight: 600;
      color: rgba(176, 160, 153, 0.5);
    }

    &:focus {
      border-color: #b0a099;
    }

    [data-is-valid='false'] & {
      border: 1px solid ${Color.ERROR};
      color: rgba(${Color.ERROR_RGB});
      background-color: rgba(${Color.ERROR_RGB}, 0.1);
      background-image: url(${warningIcon});
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: right 16px center;
    }

    [data-is-warning-icon-shown='false'] & {
      background-image: none;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  Label: styled.span`
    color: rgba(${Color.PRIMARY_TEXT_RGB});
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  `,
  VisibilityButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 26px;
    right: 0;
    width: 52px;
    height: 48px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;

    &[data-visible='true'] {
      background-image: url(${openedEyeIcon});
    }

    &[data-visible='false'] {
      background-image: url(${closedEyeIcon});
    }
  `,
  EmailContainer: styled.div`
    width: 100%;
    position: relative;
    margin-top: 24px;

    ::after {
      content: attr(data-hint-text);
      color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.6);
      font-size: 12px;
    }
  `,
  ClearIcon: styled.div`
    width: 15px;
    height: 15px;
    background-image: url(${clearIcon});
    position: absolute;
    right: 20px;
    top: calc(50% - 6px);
  `,
}
