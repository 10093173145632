import React from 'react'
import { StyledComponents as S } from 'modules/upsell/styles'
import starsSvg from 'assets/images/sprite/question.svg'
import { useTranslation } from 'react-i18next'
import { ISvg } from 'components/SvgImage'

export interface IProps {
  isVisible: boolean
}

export const OfferDetails: React.FC<IProps> = ({ isVisible }) => {
  const { t } = useTranslation()

  return (
    <S.OfferWrapper id="details" isVisible={isVisible}>
      <S.ImgWrapper>
        <S.QuestionImg svg={starsSvg as ISvg} />
      </S.ImgWrapper>
      <S.OfferInfo>
        <S.Subtitle>{t`upsell.whatYouLearn`}</S.Subtitle>
        <S.Block>
          <S.Point>
            New kitty checklist: everything you need for a new cat
          </S.Point>
          <S.Point>Mastering litter box etiquette: step-by-step guide</S.Point>
          <S.Point>
            Feeding your cat: examples of the most popular cat diets
          </S.Point>
          <S.Point>
            Dealing with destructive behaviors: friendly tips to assist you in
            handling and curbing any destructive behaviors
          </S.Point>
          <S.Point>
            Speak your cat&apos;s language: detailed guide to unraveling your
            cat&apos;s everyday expressions
          </S.Point>
          <S.Point>
            The list of top basic cues for cats: step-by-step instruction to
            learn all the advantages of teaching the commands
          </S.Point>
        </S.Block>
      </S.OfferInfo>
    </S.OfferWrapper>
  )
}
