import React, { InputHTMLAttributes, useCallback } from 'react'
import { StyledInputVariant2 as S } from './InputVariant.styles'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> & {
  labelName?: string
  selectIconVisible?: boolean
  isValid?: boolean
  validationText?: string
  hintText?: string
  setIsAutoCompleteShown?: (flag: boolean) => void
  isDataShown: boolean
}

export const InputVariantSelect: React.FC<TProps> = ({
  labelName,
  selectIconVisible = false,
  isValid = true,
  validationText = '',
  hintText = '',
  setIsAutoCompleteShown,
  isDataShown = false,
  ...props
}) => {
  const handleBlur = useCallback(
    (e) => {
      const target = e.target as any
      target.value = target.value.trim()

      if (props.onChange) {
        props.onChange(e)
      }

      if (props.onBlur) {
        props.onBlur(e)
      }
    },
    [props],
  )

  return (
    <S.Wrapper
      data-is-valid={isValid}
      data-validation-text={validationText}
      data-hint-text={hintText}
    >
      {labelName && <S.Label>{labelName}</S.Label>}
      <S.Input required type="text" {...props} onBlur={handleBlur} />
      {selectIconVisible && (
        <S.SelectButton
          type="button"
          onClick={() =>
            setIsAutoCompleteShown && setIsAutoCompleteShown(!isDataShown)
          }
          data-open={isDataShown}
        />
      )}
    </S.Wrapper>
  )
}
