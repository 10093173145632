import React from 'react'
import { SvgImage } from 'components/SvgImage'
import arrow from 'assets/images/sprite/cat-arrow.svg'
import { useTranslation } from 'react-i18next'
import { StyledComponents as S } from './styles'

type TProps = {
  onClick: () => void
  isButtonDisabled?: boolean
  text?: string
}

export const OutlinedArrowButton: React.FC<React.PropsWithChildren<TProps>> = ({
  onClick,
  isButtonDisabled = false,
  text,
}) => {
  const { t } = useTranslation()
  return (
    <S.OutlinedButton disabled={isButtonDisabled} onClick={onClick}>
      <S.ButtonText>{text || t`actions.continue`}</S.ButtonText>
      <SvgImage svg={arrow} width={24} />
    </S.OutlinedButton>
  )
}
