import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'src/constants/pages'

import screen1 from 'assets/images/slider-cat-1.jpg'
import screen2 from 'assets/images/slider-cat-2.jpg'
import screen3 from 'assets/images/slider-cat-3.jpg'
import screen4 from 'assets/images/slider-cat-4.jpg'
import screen5 from 'assets/images/slider-cat-5.jpg'
import screen6 from 'assets/images/slider-cat-6.jpg'
import screen7 from 'assets/images/slider-cat-7.jpg'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledQuestion as S } from './Questions.styles'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep])

  return (
    <S.WelcomeWrapper>
      <S.ContentWithSlider>
        <S.WelcomeSubtitle>Your ultimate guide:</S.WelcomeSubtitle>
        <S.WelcomeTitleVariant2>
          How to become the best partner for your feline friend
        </S.WelcomeTitleVariant2>
        <S.SliderWrapper>
          <Swiper
            loop
            slidesPerView="auto"
            spaceBetween={8}
            centeredSlides
            autoplay={{
              delay: 1000,
            }}
          >
            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen1} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen2} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen3} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen4} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen5} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen6} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen7} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>
          </Swiper>
        </S.SliderWrapper>
        <S.StickyBlockWrapper2>
          <GreenArrowButton onClick={handleContinue} />
        </S.StickyBlockWrapper2>
      </S.ContentWithSlider>
    </S.WelcomeWrapper>
  )
}

Component.hasHeader = true
Component.id = PageId.WELCOME_1

export const WelcomePage = Component
