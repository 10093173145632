import { Color, MediaBreakpoint } from 'root-constants'
import styled from 'styled-components'
import { Button } from 'components/buttons/Button'
import {
  baseColumnStyles,
  baseMediaStyles,
  basePageStyles,
  baseTitleStyles,
  fullViewHeight,
} from 'pages/styles'

export const StyledCatBreed = {
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.BEIGE};
    padding-top: 96px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.div`
    ${baseTitleStyles};
    display: flex;
    align-items: center;
    min-height: 48px;
    margin-bottom: 16px;
  `,
  AutocompleteSubtitle: styled.p`
    height: 41px;
    font-size: 14px;
    line-height: 41px;
    letter-spacing: 0.88px;
    font-weight: 600;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.3);
    text-align: start;
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 0;
  `,
  AutoCompleteWrapper: styled.div`
    max-height: 260px;
    padding: 0 15px;
    position: absolute;
    top: 50px;
    left: 0;
    box-shadow: 0 6px 20px rgba(74, 53, 50, 0.2);
    border-radius: 8px;
    overflow-y: auto;
    width: 288px;
    background-color: ${Color.WHITE};
    z-index: 2;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 343px;
    }
  `,
  AutoCompleteButton: styled.button`
    height: 40px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-decoration: none;
    text-align: left;
    color: ${Color.PRIMARY_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px dotted #bcc9d0;
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Button: styled(Button)`
    ${baseMediaStyles};
    bottom: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ButtonText: styled.span`
    width: 100%;
  `,
  OtherBreedLink: styled.a`
    font-size: 14px;
    margin-top: 16px;
    font-weight: 700;
    line-height: 27px;
    text-decoration-line: underline;
    color: ${Color.BROWNISH_GRAY};
  `,
}
