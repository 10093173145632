import React from 'react'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import FunnelFlow from 'components/FunnelFlow'
import { useInitGoogleAnalyticsTracker } from 'hooks/useInitGoogleAnalyticsTracker'
import { setViewportFullHeight } from 'helpers/setViewportFullHeight'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { CustomRouter } from './CustomRouter'
import { routeHistory } from '../routerHistory'
import { useSnapPixelInitialization } from 'hooks/useSnapPixelInitialization'

setViewportFullHeight()

export const App: React.FC = () => {
  useInitAppConfig()
  useSnapPixelInitialization()
  useInitFirebase()
  useInitGoogleAnalyticsTracker()

  return (
    <CustomRouter history={routeHistory}>
      <FunnelFlow />
    </CustomRouter>
  )
}
