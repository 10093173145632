import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { TAppDispatch } from 'root-redux/store'
import { getVariantAction } from 'root-redux/common/common-actions'
import { DEFAULT_COHORT } from '../constants/rootConstants'

export const useSetVariant = (): void => {
  const dispatch: TAppDispatch = useDispatch()
  const URLParams = new URLSearchParams(document.location.search)
  const cohortFromUrl = URLParams.get('cohort')

  useEffect(() => {
    const cohort = cohortFromUrl

    dispatch(getVariantAction({ cohort: cohort || DEFAULT_COHORT }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
