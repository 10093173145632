import catImg from 'assets/images/crown-cat.jpg'
import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import React from 'react'
import { Trans } from 'react-i18next'
import { CURRENT_YEAR, PLAN_TYPES } from 'root-constants'
import { ScreenName } from 'services/eventLogger-service'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { PlanItem } from 'modules/subscriptions/components/plan-blocks/PlanItem'
import { StickyBanner } from 'modules/subscriptions/components/sticky-banner/StickyBanner'
import { SelectPlanBlockVariant2 } from 'modules/subscriptions/components/plan-blocks/SelectPlanBlockVariant2'
import { GuaranteeVariant2 } from 'modules/subscriptions/components/guarantee/GuaranteeVariant2'
import { PageId } from '../../../../constants/pages'
import { StyledSubscriptions as S } from '../Subscriptions.styles'

const CancelOfferComponent: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
  isNonPayerFlow,
}) => (
  <S.Wrapper>
    <S.Content>
      <S.TitleVariant2>
        <strong>Enjoy Happy Relationship</strong>
        <br />
        And Fun Trainings With
        <br /> Your Cat!
      </S.TitleVariant2>
      <S.CatImage src={catImg} />
      <StickyBanner />
      <SelectPlanBlockVariant2
        onButtonClick={onButtonClick}
        onSelect={onSubscriptionSelect}
        SelectPlanItem={PlanItem}
        availableSubscriptions={availableSubscriptions}
        isNonPayerFlow={isNonPayerFlow}
      />
      <GuaranteeVariant2 />
      <S.FinalBlockWrapper id={SUBSCRIPTION_SECTION_IDS.startMyPlanButton}>
        <S.PlanButtonWrapper>
          <GreenArrowButton onClick={onButtonClick} text="Start My Plan" />
        </S.PlanButtonWrapper>
        <Trans
          values={{ year: CURRENT_YEAR }}
          i18nKey="footer.allRightsReserved"
        />
      </S.FinalBlockWrapper>
    </S.Content>
  </S.Wrapper>
)
const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    isNonPayerFlow
    screenName={ScreenName.CANCEL}
    planType={PLAN_TYPES.PURCHASE}
  >
    {(props: ISubscriptionsPageProps) => <CancelOfferComponent {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.CANCEL_OFFER

export const CancelOfferPage = Component
