import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'src/constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledQuestion as S } from './Questions.styles'
import { CLICKER_TRAINED_OPTIONS_VALUES } from '../../constants/options-constants'

const QUESTION = 'Is your cat clicker-trained?'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>Is your cat clicker-trained?</S.Title>

        <StyledOption.OptionsContainer>
          <S.Option
            {...optionProps}
            value={CLICKER_TRAINED_OPTIONS_VALUES.NOT_FAMILIAR}
          >
            <QuestionButton>
              <S.IconWrapper>❌</S.IconWrapper> Not familiar with clicker
            </QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            value={CLICKER_TRAINED_OPTIONS_VALUES.FAMILIAR}
          >
            <QuestionButton>
              <S.IconWrapper>🤏🏽</S.IconWrapper> Somewhat familiar
            </QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            value={CLICKER_TRAINED_OPTIONS_VALUES.TRAINED}
          >
            <QuestionButton>
              <S.IconWrapper>✅</S.IconWrapper> Clicker-trained and responsive
              to cues
            </QuestionButton>
          </S.Option>
        </StyledOption.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.CLICKER_TRAINED

export const ClickerTrainedQuestion = Component
