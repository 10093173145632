import React, { useCallback, useMemo, useState } from 'react'
import { SeparatedProgressBar } from 'components/progress-bar/SeparatedProgressBar'
import { Header } from 'components/header/Header'
import { PolicyModal } from 'components/header/policy/PolicyModal'
import { useSelector } from 'react-redux'
import { selectVariantCohort } from 'root-redux/common/common-selects'
import { CS_COHORT } from 'root-constants'
import { ProgressBar } from 'components/progress-bar/ProgressBar'

export const HeaderContainer: React.FC = () => {
  const cohort = useSelector(selectVariantCohort)
  const [isMenuShown, setIsMenuShown] = useState(false)

  const isCsCohort = useMemo(() => cohort === CS_COHORT, [cohort])

  const openPrivacyMenu = useCallback(() => {
    setIsMenuShown(!isMenuShown)
  }, [isMenuShown, setIsMenuShown])

  return (
    <>
      <Header openPrivacyMenu={openPrivacyMenu} isMenuShown={isMenuShown} />
      {isMenuShown && <PolicyModal />}
      {isCsCohort ? <ProgressBar /> : <SeparatedProgressBar />}
    </>
  )
}
