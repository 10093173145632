import planCat from 'assets/images/plan-cat.png'
import React, { Fragment, useCallback, useMemo } from 'react'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { ISubscription } from 'models/suscriptions.model'
import { getAvailableSupplies } from 'modules/subscriptions/helpers'
import { useDispatch } from 'react-redux'
import { setSupplyTitle } from 'root-redux/payment/payment-actions'
import { TAppDispatch } from 'root-redux/store'
import { makeUpsellAction } from 'root-redux/payment/upsell'
import { ISelectPlanItemCsProps } from './PlanItemVariantCs'
import { StyledPlanBlock as S } from './PlanBlock.styles'

type TProps = {
  onButtonClick: () => void
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<React.PropsWithChildren<ISelectPlanItemCsProps>>
  availableSubscriptions: ISubscription[]
  isUpsell?: boolean
}

export const SelectPlanItemsBlockCs: React.FC<
  React.PropsWithChildren<TProps>
> = ({
  onButtonClick,
  onSelect,
  SelectPlanItem,
  availableSubscriptions,
  isUpsell = false,
}) => {
  const dispatch: TAppDispatch = useDispatch()

  const availableSupplies = useMemo(
    () => getAvailableSupplies(availableSubscriptions, isUpsell),
    [availableSubscriptions],
  )

  const handleButtonClick = useCallback(
    (value, title: string) => {
      const checkedSubscription = availableSubscriptions.find(
        (subscription) => subscription.id === value,
      )

      onSelect(checkedSubscription)
      onButtonClick()

      if (isUpsell) {
        dispatch(makeUpsellAction('upsale_screen'))
      } else {
        dispatch(setSupplyTitle(title))
      }
    },
    [onSelect, onButtonClick, availableSubscriptions, isUpsell],
  )

  return (
    <S.SelectPlanCardVariantCs id={SUBSCRIPTION_SECTION_IDS.interestingFact}>
      <S.PlanImage src={planCat} alt="plan cat" />
      <S.CardTitle>Cat Supplies</S.CardTitle>
      {availableSupplies.map((supplies) => (
        <Fragment key={supplies.blockTitle}>
          <S.SuppliesTitle background={supplies.background}>
            {supplies.blockTitle}
          </S.SuppliesTitle>
          <S.PlanItemsCs>
            {supplies.data.map((item) => (
              <SelectPlanItem
                key={item.title}
                supply={item}
                onButtonClick={handleButtonClick}
              />
            ))}
          </S.PlanItemsCs>
        </Fragment>
      ))}
    </S.SelectPlanCardVariantCs>
  )
}
