import React, { useEffect, useMemo, useRef } from 'react'
import guaranteeImage from 'assets/images/cat-guarantee-2.png'
import expertBubble from 'assets/images/sprite/expert-message.svg'
import newbieBubble from 'assets/images/sprite/message.svg'
import lottie from 'lottie-web/build/player/lottie_light'
import trainingProgress from 'assets/animation/trainingProgress.json'
import { SvgImage } from 'components/SvgImage'
import { CONTACT_FORM_LINKS, LOCALES, TERMS_OF_USE_LINKS } from 'root-constants'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { StyledGuarantee as S } from './Guarantee.styles'
import { Trans, useTranslation } from 'react-i18next'

export const GuaranteeVariant3: React.FC = () => {
  const { t, i18n } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const env = getCurrentEnv()

  const isLongLocale = useMemo(
    () => i18n.language === LOCALES.ES,
    [i18n.language],
  )

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: trainingProgress,
      loop: true,
    })
  }, [])

  return (
    <S.WrapperVariant2>
      <S.CatImageVariant2 src={guaranteeImage} />
      <S.Title>
        <Trans i18nKey="onboarding.guarantee.title" />
      </S.Title>
      <S.Text>
        {t`onboarding.guarantee.text`}
        <S.InnerLink target="_blank" to={TERMS_OF_USE_LINKS[env]}>
          &nbsp;{t`commonComponents.termsOfUse`}
        </S.InnerLink>
        &nbsp;{t`commonComponents.contact`}&nbsp;
        <S.InnerLink to={CONTACT_FORM_LINKS[env]} target="_blank">
          {t`commonComponents.support`}.
        </S.InnerLink>
      </S.Text>
      <S.ChartWrapper>
        <S.ChartTitle>{t`onboarding.guarantee.trackProgress`}</S.ChartTitle>
        <S.Chart ref={animationContainerRef} style={{ width: '330px' }} />
        <S.NowText>{t`onboarding.guarantee.now`}</S.NowText>
        <S.TextPlan
          isLongLocale={isLongLocale}
        >{t`onboarding.guarantee.withOurPlan`}</S.TextPlan>
        <S.ExpertBubble svg={expertBubble} />
        <S.NewbieBubble>
          <SvgImage svg={newbieBubble} width={69} height={30} />
          <S.NewbieTitle2>{t`onboarding.guarantee.newbie`}</S.NewbieTitle2>
        </S.NewbieBubble>
      </S.ChartWrapper>
    </S.WrapperVariant2>
  )
}
