import { Color } from 'root-constants'
import styled from 'styled-components'
import { BackButton } from 'components/buttons/back-button/BackButton'
import {
  baseColumnStyles,
  basePageStyles,
  desktopMediaQuery,
} from 'pages/styles'

export const StyledEmailAccount = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    position: relative;
    padding-top: 60px;

    @media ${desktopMediaQuery} {
      padding-top: 120px;
    }
  `,
  BackButton: styled(BackButton)`
    position: absolute;
    top: 36px;
    left: 0;
  `,
  PageTitle: styled.h3`
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 16px;
  `,
  Form: styled.form`
    ${baseColumnStyles};
    margin-bottom: 20px;
  `,
  FormWrapper: styled.form`
    ${baseColumnStyles};
    text-align: start;
  `,
  Agreement: styled.p`
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.6);

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  OtherMethodsButton: styled.button`
    all: unset;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    text-decoration: underline;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.8);
    margin-bottom: 16px;
  `,
}
