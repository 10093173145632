import lottie from 'lottie-web/build/player/lottie_light'
import loaderAnimation from 'assets/animation/loaderAnimation.json'
import spinnerSvg from 'assets/images/sprite/spinner.svg'
import React, { useEffect, useRef } from 'react'
import { ISvg } from 'components/SvgImage'
import { StyledSpinner as S } from './Spinner.styles'

type TProps = {
  isFullScreen?: boolean
}

export const Spinner: React.FC<TProps> = ({ isFullScreen = true }) => {
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: loaderAnimation,
      loop: true,
    })
  }, [])

  return isFullScreen ? (
    <S.Wrapper>
      <S.ComplexAnimatedCircle ref={animationContainerRef} />
    </S.Wrapper>
  ) : (
    <S.SpinCircle svg={spinnerSvg as ISvg} height={76} />
  )
}
