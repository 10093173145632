import styled, { css } from 'styled-components'
import { Color } from 'root-constants'
import { baseMediaStyles } from 'pages/styles'

type TStyledBarProps = {
  hasHeader: boolean
}

const progressValueStyles = css`
  background-color: #35b43f;
  transition: all 0.2s ease-out;
`

export const StyledProgressBar = {
  Wrapper: styled.div<TStyledBarProps>`
    ${baseMediaStyles};
    position: absolute;
    top: ${({ hasHeader }) => (hasHeader ? '64px' : '8px')};
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  `,
  Wrapper2: styled.div`
    position: fixed;
    width: 100%;
    height: 42px;
    background-color: ${Color.WHITE};
    top: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  `,
  Progress: styled.progress`
    ${baseMediaStyles};
    height: 5px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    background-color: #efeceb;
    margin-right: 4px;

    &::-webkit-progress-bar {
      background-color: #efeceb;
    }

    &::-webkit-progress-value {
      ${progressValueStyles}
    }

    &::-moz-progress-bar {
      ${progressValueStyles}
    }

    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
  ProgressText: styled.p`
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.5);
  `,
}
