import { PageId } from 'src/constants/pages'

export type TPriceDetails = {
  oldFullPrice: number
  fullPrice: number
  oldDailyPrice: number
  dailyPrice: number
}

export const getCustomPlanPrices = (
  isCancelOfferApplied: boolean,
  currentPageId?: PageId | null,
): Record<string, TPriceDetails> =>
  isCancelOfferApplied || currentPageId === PageId.CANCEL_OFFER_3
    ? {
        7: {
          oldFullPrice: 17.77,
          fullPrice: 5.15,
          oldDailyPrice: 2.54,
          dailyPrice: 0.74,
        },
        28: {
          oldFullPrice: 38.95,
          fullPrice: 11.29,
          oldDailyPrice: 1.39,
          dailyPrice: 0.4,
        },
        84: {
          oldFullPrice: 94.85,
          fullPrice: 27.49,
          oldDailyPrice: 1.13,
          dailyPrice: 0.33,
        },
      }
    : {
        7: {
          oldFullPrice: 17.77,
          fullPrice: 6.93,
          oldDailyPrice: 2.54,
          dailyPrice: 0.99,
        },
        28: {
          oldFullPrice: 38.95,
          fullPrice: 15.19,
          oldDailyPrice: 1.39,
          dailyPrice: 0.54,
        },
        84: {
          oldFullPrice: 94.85,
          fullPrice: 36.99,
          oldDailyPrice: 1.13,
          dailyPrice: 0.44,
        },
      }
