import { createProductId } from 'helpers/createProductId'
import { eventLogger, ScreenName } from 'services/eventLogger-service'
import { ISubscription } from 'models/suscriptions.model'

export const logSalePageShown = ({
  availableSubscriptions,
  cohort,
  email,
  trialPrice,
  pageNumber,
  screenName,
}: {
  availableSubscriptions: ISubscription[]
  cohort: string
  email: string
  trialPrice?: number
  pageNumber?: number
  screenName?: ScreenName
}): void => {
  eventLogger.logSalePageShown({
    productIds: availableSubscriptions.map(
      ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
        createProductId({
          periodName,
          periodQuantity,
          price: fullPrice,
        }),
    ),
    cohort,
    email,
    trialPrice,
    pageNumber,
    screenName,
  })
}
