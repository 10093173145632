import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  ONE_LINK_URL,
  PDF_CAT_BOOK_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from 'root-constants'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectVariantCohort } from 'root-redux/common/common-selects'
import { eventLogger } from 'services/eventLogger-service'
import downloadCat from 'assets/images/download-app.jpg'
import pdfFile from 'assets/images/pdf-file.png'
import download from 'assets/images/sprite/download-dark.svg'
import { saveAs } from 'helpers/saveAs'
import { Spinner } from 'components/spinner/Spinner'
import { StyledDownloadAppPage as S } from './DownloadApp.styles'

export const DownloadAppWithPdf: React.FC = () => {
  const { t } = useTranslation()
  const cohort = useSelector(selectVariantCohort)
  const [isFetching, setIsFetching] = useState(false)
  const downloadAppLink = useMemo(() => {
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return customToken
      ? `${ONE_LINK_URL}?deep_link_value=${customToken}`
      : ONE_LINK_URL
  }, [cohort])

  useEffect(() => {
    eventLogger.logGettingAppShown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onButtonClick = useCallback(() => {
    const el = document.getElementById('downloadButtonBlock')
    el?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleDownloadAppClick = useCallback(() => {
    eventLogger.logDownloadClicked({
      cb: handleContinue,
      productName: 'meowz_mobile_app',
    })
  }, [handleContinue])

  const handleDownloadPDFClick = useCallback(() => {
    setIsFetching(true)
    saveAs(PDF_CAT_BOOK_LINK, 'meowz_training.pdf', {
      setIsFetching,
    })
    eventLogger.logDownloadClicked({
      productName: 'pdf_meowz',
    })
  }, [setIsFetching])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {t`account.accountCreated`} <br /> {t`account.justMoreStep`}
        </S.Title>
        <S.List>
          <li>
            <S.ListLabel2 data-number="1" />
            <S.ListText>
              Click the <strong onClick={onButtonClick}>button</strong> to
              download Meowz App.
            </S.ListText>
          </li>
          <li>
            <S.ListLabel2 data-number="2" />
            <S.ListText>
              Open the app and choose the same login method you used to start
              your journey.
            </S.ListText>
          </li>
        </S.List>
        <S.CatImage src={downloadCat} />
        <S.PDFWrapper>
          <S.PDFInfo>
            <S.PDFImg alt="pdf file image" src={pdfFile} />
            Also, you have successfully purchased our additional material
          </S.PDFInfo>
          <S.PDFText>
            Cat parent&apos;s essential guide: key insights for your journey
            with your feline companion.
          </S.PDFText>
        </S.PDFWrapper>
        <S.Column2 id="downloadButtonBlock">
          <S.DownloadText>
            You can download it
            <br />
            by pressing the button below:
          </S.DownloadText>
          <S.DownloadPdfButton onClick={handleDownloadPDFClick}>
            <S.DownloadImg svg={download} />
            {t`account.downloadPDF`}
          </S.DownloadPdfButton>
          <S.ButtonAsLink
            type="button"
            data-deep-link={downloadAppLink}
            onClick={handleDownloadAppClick}
          >
            {t`account.download`} Meowz
          </S.ButtonAsLink>
          <S.PrivacyNote>
            &nbsp;By continuing you agree to
            <S.Link target="_blank" to={TERMS_OF_USE_LINK}>
              Terms of Use
            </S.Link>
            &nbsp;and&nbsp;
            <S.Link target="_blank" to={PRIVACY_POLICY_LINK}>
              Privacy Policy
            </S.Link>
          </S.PrivacyNote>
        </S.Column2>
      </S.Column>
      {isFetching && <Spinner />}
    </S.Wrapper>
  )
}
