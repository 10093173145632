import { IConfigRaw } from 'models/config.model'
import { firebaseConfig } from 'root-constants'

export const getConfigFromConfigRaw = (config: IConfigRaw) => ({
  amplitudeApiKey: config.amplitude_api_key,
  giaApiKeys: config.gia_api_keys,
  googleAnalyticsId: config.google_analytics_id,
  googleOptimizeId: config.google_optimize_id,
  payPalClientId: config.paypal_client_id,
  firebaseConfig,
})
