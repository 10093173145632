import { css } from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'

export const fullViewHeight = css`
  height: var(--full-height, 100vh);

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`

export const baseMediaStyles = css`
  width: 328px;

  @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
    width: 361px;
  }
`

export const desktopMediaQuery = `(min-width: ${MediaBreakpoint.MAX_PHONE}px)`

export const basePageStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const baseColumnStyles = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${baseMediaStyles};
`

export const baseTitleStyles = css`
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`

export const baseTextStyles = css`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.8);
  text-align: center;
`

export const fullViewMinHeight = css`
  min-height: var(--full-height, 100vh);

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
`
