import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { eventLogger } from 'services/eventLogger-service'
import downloadCat from 'assets/images/download-app.jpg'
import { StyledDownloadAppPage as S } from './DownloadApp.styles'
import { useSelector } from 'react-redux'
import { selectUUID } from 'root-redux/user/user-selects'
import { getDownloadOneLink } from 'helpers/getDownloadOneLink'

export const DownloadApp: React.FC = () => {
  const { t } = useTranslation()
  const uuid = useSelector(selectUUID)
  const downloadAppLink = useMemo(() => getDownloadOneLink(uuid), [uuid])

  useEffect(() => {
    eventLogger.logGettingAppShown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(
    () => eventLogger.logDownloadClicked({ cb: handleContinue }),
    [handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {t`account.accountCreated`} <br /> {t`account.justMoreStep`}
        </S.Title>
        <S.List>
          <li>
            <S.ListLabel data-number="1" />
            <S.ListText>{t`account.clickDownload`}</S.ListText>
          </li>
          <li>
            <S.ListLabel data-number="2" />
            <S.ListText>{t`account.openApp`}</S.ListText>
          </li>
        </S.List>
        <S.CatImage src={downloadCat} />
        <S.Footer>
          <S.DownloadButton
            type="button"
            data-deep-link={downloadAppLink}
            onClick={handleClick}
          >
            {t`account.download`} Meowz
          </S.DownloadButton>
        </S.Footer>
      </S.Column>
    </S.Wrapper>
  )
}
