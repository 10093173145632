import failedFaceSvg from 'assets/images/sprite/cat-failed.svg'
import loaderAnimation from 'assets/animation/loaderAnimation.json'
import { Button } from 'components/buttons/Button'
import { SvgImage } from 'components/SvgImage'
import lottie from 'lottie-web/build/player/lottie_light'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { PaymentSuccess } from 'modules/payment/components/payment-processing/PaymentSuccess'
import { select3DSecureIframeUrl } from 'root-redux/payment/payment-selects'
import { CHECK_3D_SECURE, PURCHASE } from 'root-redux/payment/payment-actions'
import { selectActionList, selectError } from 'root-redux/common/common-selects'
import { resetErrorAction } from 'root-redux/common/common-actions'
import { MAKE_UPSELL } from 'root-redux/payment/upsell'
import { StyledPaymentStatus as S } from './PaymentStatus.styles'

const enum AnimationState {
  LOADING = 'loading',
  SUCCESS = 'success',
}

const commonLottieProps = { width: '100px' }

type TProps = {
  isUpsell?: boolean
}

export const StripePaymentProcessing: React.FC<TProps> = ({ isUpsell }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const fetchingActionsList = useSelector(selectActionList)
  const requestError = useSelector(selectError)
  const threeDSecureIframeURL = useSelector(select3DSecureIframeUrl)
  const [isShown, setIsShown] = useState(false)
  const [animationState, setAnimationState] = useState<AnimationState | null>(
    AnimationState.LOADING,
  )
  const loadingAnimationRef = useRef<HTMLDivElement>(null!)
  const completeAnimationRef = useRef<HTMLDivElement>(null!)

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(MAKE_UPSELL) ||
      fetchingActionsList.includes(CHECK_3D_SECURE),
    [fetchingActionsList],
  )

  const isError = useMemo(
    () => !isPurchaseInProcess && requestError && isShown,
    [isPurchaseInProcess, requestError, isShown],
  )

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsShown(true)
    }

    if (isPurchaseInProcess && loadingAnimationRef.current) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        animationData: loaderAnimation,
        loop: true,
      })
    }

    if (!isPurchaseInProcess && isShown && !requestError) {
      setAnimationState(AnimationState.SUCCESS)
    }

    if (
      !isPurchaseInProcess &&
      requestError &&
      isShown &&
      !threeDSecureIframeURL
    ) {
      setAnimationState(null)
    }

    return () => lottie.destroy()
  }, [isPurchaseInProcess, isShown, requestError, threeDSecureIframeURL])

  useEffect(() => {
    if (
      animationState !== AnimationState.SUCCESS &&
      loadingAnimationRef.current
    ) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        animationData: loaderAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [animationState])

  const handleResetError = useCallback(() => {
    setIsShown(false)
    setAnimationState(AnimationState.LOADING)
    dispatch(resetErrorAction())
  }, [dispatch])

  return (
    <>
      {isPurchaseInProcess && animationState === AnimationState.LOADING && (
        <S.Wrapper>
          <S.Container>
            <div ref={loadingAnimationRef} style={{ ...commonLottieProps }} />
            <S.Title>
              <Trans i18nKey="payment.processing" components={[<br />]} />
            </S.Title>
            <S.Subtitle>
              <Trans i18nKey="payment.thankYou" components={[<br />]} />
            </S.Subtitle>
          </S.Container>
        </S.Wrapper>
      )}
      {!isPurchaseInProcess &&
        !requestError &&
        animationState === AnimationState.SUCCESS && (
          <PaymentSuccess animationContainerRef={completeAnimationRef} />
        )}
      {isError && (
        <S.Wrapper>
          <S.Container>
            <SvgImage svg={failedFaceSvg} height={110} />
            <S.Title>
              <Trans i18nKey="payment.failed" components={[<br />]} />
            </S.Title>
            <S.Subtitle>
              <Trans i18nKey="payment.pleaseCheck" components={[<br />]} />
            </S.Subtitle>
            <Button onClick={handleResetError}>{t`payment.tryAgain`}</Button>
          </S.Container>
        </S.Wrapper>
      )}
      {isError && isUpsell && (
        <S.Wrapper>
          <S.Container>
            <SvgImage svg={failedFaceSvg} height={110} />
            <S.Title>
              <Trans i18nKey="payment.failed" components={[<br />]} />
            </S.Title>
            <S.Subtitle>
              <Trans i18nKey="payment.yourMoneyIsSafe" components={[<br />]} />
            </S.Subtitle>
            <Button
              onClick={handleResetError}
            >{t`commonComponents.continue`}</Button>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  )
}
