import logoImg from 'assets/images/sprite/logo.svg'
import menuImg from 'assets/images/sprite/menu.svg'
import closeImg from 'assets/images/sprite/close-icon-dark.svg'

import React, { useContext, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { FlowContext } from 'components/FlowContext'
import {
  selectCurrentVariantSteps,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { FlowBackButton } from 'components/buttons/back-button/FlowBackButton'
import { useTranslation } from 'react-i18next'
import { ISvg } from 'components/SvgImage'
import { CS_COHORT } from 'root-constants'
import { ID_TO_PAGE_MAP, PageId } from '../../constants/pages'
import { StyledHeader as S } from './Header.styles'

interface IProps {
  openPrivacyMenu: () => void
  isMenuShown: boolean
}

export const Header: React.FC<IProps> = ({ openPrivacyMenu, isMenuShown }) => {
  const { currentPageId } = useContext(FlowContext)
  const steps = useSelector(selectCurrentVariantSteps)
  const cohort = useSelector(selectVariantCohort)
  const { t } = useTranslation()
  const isUpsell = useMemo(
    () => currentPageId === PageId.UPSELL,
    [currentPageId],
  )

  const title = useMemo(
    () =>
      cohort === CS_COHORT
        ? 'Meowz: Cat Supplies & Care'
        : `${t`header.title`}`,
    [cohort, t],
  )

  const pagesWithHeader = useMemo(
    () =>
      steps.filter(
        ({ id }) =>
          Object.values(ID_TO_PAGE_MAP).find((component) => id === component.id)
            ?.hasHeader,
      ),
    [steps],
  )

  const currentPageIndex = useMemo(
    () =>
      isUpsell
        ? 0
        : pagesWithHeader.findIndex(({ id }) => id === currentPageId),
    [currentPageId, pagesWithHeader],
  )

  return currentPageIndex < 0
    ? null
    : ReactDOM.createPortal(
        <S.HeaderWrapper isUpsell={isUpsell}>
          <S.Header>
            {currentPageIndex > 0 && !isMenuShown && <FlowBackButton />}
            <S.LogoWrapper>
              <S.Logo svg={logoImg as ISvg} />
              <S.LogoTitle isUpsell={isUpsell}>{title}</S.LogoTitle>
            </S.LogoWrapper>
            {!isUpsell && (
              <S.Menu
                onClick={openPrivacyMenu}
                svg={(isMenuShown ? closeImg : menuImg) as ISvg}
              />
            )}
          </S.Header>
        </S.HeaderWrapper>,
        document.getElementById('root') as HTMLElement,
      )
}
