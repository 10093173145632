import { AnyAction } from 'redux'
import { ValidationText } from 'root-constants'
import {
  RESET_EMAIL_ERROR_MESSAGE,
  RESET_PASSWORD_ERROR_MESSAGE,
  SET_EMAIL_ERROR_MESSAGE,
  SET_PASSWORD_ERROR_MESSAGE,
} from 'root-redux/account/account-actions'

export interface IAccountState {
  emailErrorMessage: ValidationText | null
  passwordErrorMessage: ValidationText | null
}

const initialState: IAccountState = {
  emailErrorMessage: null,
  passwordErrorMessage: null,
}

export function accountReducer(
  state = initialState,
  { type, payload }: AnyAction,
): IAccountState {
  switch (type) {
    case SET_EMAIL_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: payload }
    }
    case RESET_EMAIL_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: null }
    }

    case SET_PASSWORD_ERROR_MESSAGE: {
      return { ...state, passwordErrorMessage: payload }
    }
    case RESET_PASSWORD_ERROR_MESSAGE: {
      return { ...state, passwordErrorMessage: null }
    }

    default:
      return state
  }
}
