import lastOfferImg from 'assets/images/cat-75-offer.jpg'
import close from 'assets/images/sprite/close-icon.svg'
import arrow from 'assets/images/sprite/dark-arrow.svg'
import { FlowContext } from 'components/FlowContext'
import { SvgImage } from 'components/SvgImage'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useContext } from 'react'
import { PageId } from 'src/constants/pages'
import { StyledLastChanceOfferPage as S } from './LastChanceOfferPage.styles'

const Component: TDynamicPageComponent = () => {
  const { onNextStep } = useContext(FlowContext)

  return (
    <S.UrgeWrapper>
      <S.CloseIcon svg={close} width={16} onClick={onNextStep} />
      <S.UrgeContent>
        <S.UrgeImage src={lastOfferImg} alt="funny cat" />
        <S.Content>
          <S.UrgeTitle>Last chance offer</S.UrgeTitle>
          <S.Subtitle>
            We are offering you&nbsp;
            <br />
            <strong>the unique 75% discount </strong>
            <br /> that you can see how your feline friend can
            <br /> improve cat’s behavior and learn many
            <br /> commands and tricks in short time.
          </S.Subtitle>
          <S.WhiteButton onClick={onNextStep}>
            <S.ButtonText>Get 75% Off</S.ButtonText>
            <SvgImage svg={arrow} width={24} />
          </S.WhiteButton>
        </S.Content>
      </S.UrgeContent>
    </S.UrgeWrapper>
  )
}

Component.id = PageId.LAST_CHANCE_OFFER

export const LastChanceOfferPage = Component
