import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { Modal } from 'components/modals/Modal'
import { createProductId } from 'helpers/createProductId'
import { PaymentSuccess } from 'modules/payment/components/payment-processing/PaymentSuccess'
import { TPayPallStyle } from 'modules/payment/types'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrency,
  selectFullPrice,
  selectPayPalClientId,
  selectPayPalPlanId,
  selectPeriodName,
  selectPeriodQuantity,
  selectProductPrice,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import {
  selectFirebaseConfigFile,
  selectOptimizeExperimentId,
  selectOptimizeSegmentName,
  selectOptimizeVariantId,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { selectEmail, selectUUID } from 'root-redux/user/user-selects'
import { getUserStatusAction } from 'root-redux/user/user-actions'
import { eventLogger } from 'services/eventLogger-service'
import { setPaymentSystemAction } from 'root-redux/payment/payment-actions'
import { createPaypalProductId } from 'helpers/createPaypalProductId'
import { api } from 'services/api/api-service'
import { TAppDispatch } from 'root-redux/store'
import {
  GlobalStyleForPayPalContainer,
  StyledPayPalContainer as S,
} from './PayPalContainer.styles'
import { logSuccessfulPayment } from '../../helpers/logSuccessfulPayment'
import { logFailedPayment } from '../../helpers/logFailedPayment'
import { PaymentMethod, defaultPayPallStyle } from '../../constants'

type TProps = {
  customStyle?: TPayPallStyle['style']
}

export const PayPalContainer: React.FC<TProps> = ({ customStyle }) => {
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const currentPrice = useSelector(selectFullPrice)
  const productPrice = useSelector(selectProductPrice)
  const currentPeriodName = useSelector(selectPeriodName)
  const currentPeriodQuantity = useSelector(selectPeriodQuantity)
  const trialPeriodPrice = useSelector(selectTrialFullPrice)
  const trialPeriodDays = useSelector(selectTrialDurationDays)
  const currency = useSelector(selectCurrency)
  const optimizeVariantId = useSelector(selectOptimizeVariantId)
  const optimizeExperimentId = useSelector(selectOptimizeExperimentId)
  const optimizeSegmentName = useSelector(selectOptimizeSegmentName)
  const email = useSelector(selectEmail)
  const cohort = useSelector(selectVariantCohort)
  const { paypalMethodShown } = useSelector(selectFirebaseConfigFile)
  const [isPaymentStatusShown, setIsPaymentStatusShown] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)
  const completeAnimationRef = useRef<HTMLDivElement>(null!)

  const productName = createProductId({
    periodName: currentPeriodName,
    periodQuantity: currentPeriodQuantity,
    price: currentPrice,
  })

  const productId = createPaypalProductId({
    priceId: paypalPlanId,
  })

  const handleResetError = useCallback(() => {
    setIsPaymentStatusShown(false)
    setIsErrorModalShown(false)
  }, [])

  const handlePaymentApprove = useCallback(
    async (data) => {
      logSuccessfulPayment({
        subscriptionId: data.subscriptionID,
        productId,
        productName,
        uuid,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        currency,
        email,
        cohort,
        paymentMethod: PaymentMethod.PAYPAL,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        periodName: currentPeriodName,
        periodQuantity: currentPeriodQuantity,
        productPrice,
      })

      const response = await api.post(`v2/purchases/paypal/${uuid}/meowz`, {
        body: {
          paypal_plan_id: paypalPlanId,
          cohort,
        },
      })

      if (response.status) {
        setIsPaymentStatusShown(true)
        setIsErrorModalShown(false)

        dispatch(setPaymentSystemAction(PaymentMethod.PAYPAL))
        dispatch(getUserStatusAction(uuid))
      }
    },
    [
      productId,
      productName,
      uuid,
      currency,
      currentPrice,
      trialPeriodPrice,
      trialPeriodDays,
      email,
      cohort,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      currentPeriodName,
      currentPeriodQuantity,
      paypalPlanId,
      dispatch,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsPaymentStatusShown(true)
      setIsErrorModalShown(true)

      logFailedPayment({
        productId,
        productName,
        price: currentPrice,
        currency,
        paymentResponse: {
          type: error?.name || '',
          code: error?.debug_id,
          message: error?.message,
        },
        paymentMethod: PaymentMethod.PAYPAL,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        isTrialActive: !!trialPeriodPrice,
        email,
      })
    },
    [
      productId,
      productName,
      currentPrice,
      currency,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      trialPeriodPrice,
      email,
    ],
  )

  const handleButtonClick = useCallback(() => {
    eventLogger.logPurchaseStarted({
      productId,
      productName,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      email,
      cohort,
    })

    window.ttq.identify({ email })
    window.ttq.track('AddPaymentInfo')
    window.snaptr('track', 'ADD_BILLING')
  }, [
    productId,
    productName,
    currentPrice,
    trialPeriodPrice,
    currency,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    email,
    cohort,
  ])

  return paypalMethodShown ? (
    <>
      <GlobalStyleForPayPalContainer />
      <S.ButtonsContainer>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              clientId: paypalClientId,
              vault: true,
              disableFunding: 'credit',
            }}
          >
            <PayPalButtons
              style={{ ...defaultPayPallStyle, ...customStyle }}
              forceReRender={[paypalPlanId]}
              createSubscription={(data, actions) =>
                actions.subscription.create({
                  plan_id: paypalPlanId, // Creates the subscription
                  custom_id: uuid,
                })
              }
              onApprove={(data) => handlePaymentApprove(data)}
              onError={(error) => handlePaymentError(error)}
              onClick={handleButtonClick}
            />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      {isPaymentStatusShown && (
        <PaymentSuccess animationContainerRef={completeAnimationRef} isPaypal />
      )}
      <Modal
        onClose={handleResetError}
        isShown={isErrorModalShown}
        error="There was an error processing your payment information"
      />
    </>
  ) : null
}
