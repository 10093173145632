import React from 'react'
import { PRIVACY_POLICY_LINKS, TERMS_OF_USE_LINKS } from 'root-constants'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { GlobalStyleForPayPalContainer } from 'modules/payment/components/paypal/PayPalContainer.styles'
import { StyledPolicyModal as S } from './styles'
import { useTranslation } from 'react-i18next'

export const PolicyModal: React.FC = () => {
  const env = getCurrentEnv()
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <GlobalStyleForPayPalContainer />
      <S.Container>
        <S.Subtitle
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Privacy Policy"
          to={PRIVACY_POLICY_LINKS[env]}
        >
          {t`commonComponents.privacyPolicy`}
        </S.Subtitle>
        <S.Subtitle
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Privacy Policy"
          to={TERMS_OF_USE_LINKS[env]}
        >
          {t`commonComponents.termsOfUse`}
        </S.Subtitle>
      </S.Container>
    </S.Wrapper>
  )
}
