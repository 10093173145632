import { TAnswer, TAnswers } from 'models/common.model'
import { NONE } from 'root-constants'
import { PageId } from '../constants/pages'

export const getSelectedAnswers = (
  answers: Partial<TAnswers> | null,
  pageId: PageId | null,
): TAnswer => {
  if (!answers || !pageId || !answers[pageId]) {
    return []
  }

  return answers[pageId] === NONE ? [] : (answers[pageId] as TAnswer)
}
