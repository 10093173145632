import { useCallback, useEffect, useRef, useState } from 'react'

export const easeInOutQuad = (elapsedTime: number): number => {
  if (elapsedTime < 0.5) return 2 * elapsedTime * elapsedTime

  return 1 - (-2 * elapsedTime + 2) ** 2 / 2
}

const frameDuration = 1000 / 60
export const useProgressAnimation = (animation: {
  duration: number
  from?: number
  to?: number
  animationFunction?: (t: number) => number
  callback?: () => void
}): {
  counter: number
  isFinished: boolean
  progressAnimationPlayback: (stopAt?: number) => void
} => {
  const {
    to: countTo = 100,
    duration: animationDuration,
    animationFunction = easeInOutQuad,
    callback,
  } = animation

  const [counterValue, setCounterValue] = useState(0)
  const [isNotFinished, setIsNotFinished] = useState(true)
  const frame = useRef(1)

  const progressAnimationPlayback = useCallback(
    (stopAt?: number) => {
      const totalFrames = Math.round(animationDuration / frameDuration)
      const counter = setInterval(() => {
        const result = countTo * animationFunction(frame.current / totalFrames)

        if (!result) return
        if (stopAt && Math.round(result) === stopAt + 1) {
          clearInterval(counter)

          return
        }

        frame.current += 1
        setCounterValue(Math.round(result))

        const isAnimationInProgress = frame?.current !== totalFrames
        if (isAnimationInProgress) return

        clearInterval(counter)
      }, frameDuration)
    },
    [animationDuration, countTo, animationFunction],
  )

  useEffect(() => {
    if (counterValue >= countTo && callback && isNotFinished) {
      callback()
      setIsNotFinished(false)
    }
  }, [counterValue, countTo, isNotFinished])

  return {
    counter: counterValue,
    isFinished: counterValue === countTo,
    progressAnimationPlayback,
  }
}
