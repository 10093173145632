import cat from 'assets/images/cat-educational-journey.jpg'
import React from 'react'
import { StyledEducationJourney as S } from './EducationJourney.styles'

export const EducationJourney: React.FC = () => (
  <S.Wrapper>
    <S.Title>
      Your pet’s educational
      <br /> journey <strong>begins now</strong>
    </S.Title>
    <S.Image src={cat} alt="cat" />
  </S.Wrapper>
)
