import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import {
  baseColumnStyles,
  baseMediaStyles,
  basePageStyles,
  baseTextStyles,
  baseTitleStyles,
  desktopMediaQuery,
  fullViewHeight,
} from 'pages/styles'

export const StyledEmailVariant = {
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    padding-top: 50px;
    background-color: ${Color.BEIGE};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding-top: 43px;

    @media ${desktopMediaQuery} {
      padding-top: 67px;
    }
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 16px;

    strong {
      font-weight: 800;
      color: ${Color.MEDIUM_GREEN};
    }
  `,
  Text: styled.p`
    ${baseTextStyles};
    margin-bottom: 16px;
  `,
  HighlightedText: styled.p`
    ${baseTextStyles};
    margin-bottom: 16px;
    font-weight: 700;
    color: #35b43f;
    margin-top: -12px;
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 0;
  `,
  Description: styled.p`
    text-align: left;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: ${Color.BROWNISH_GRAY};
  `,
  AutoCompleteWrapper: styled.div`
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 72px;
    left: 0;
    box-shadow: 0 6px 20px rgba(74, 53, 50, 0.2);
    border-radius: 8px;
    overflow-y: auto;
    width: 288px;
    background-color: ${Color.WHITE};
    z-index: 2;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 343px;
    }
  `,
  AutoCompleteButton: styled.button`
    height: 40px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-decoration: none;
    text-align: left;
    color: ${Color.PRIMARY_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px dotted #bcc9d0;
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  StickyBlockWrapper: styled.div`
    ${baseMediaStyles};
    position: fixed;
    z-index: 1;
    bottom: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
    }
  `,
  RightsReserved: styled.div`
    color: ${Color.BROWNISH_GRAY};
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: -32px;
    padding-top: 32px;
  `,
  PrivacyNoteWrapper: styled.div`
    margin-top: 10px;
  `,
}
