import React from 'react'
import { useRoutes } from 'react-router-dom'
import { TermsOfUse } from 'modules/global/pages/TermsOfUse'
import { PrivacyPolicy } from 'modules/global/pages/PrivacyPolicy'

export const PrivacyLinksRouter: React.FC = () =>
  useRoutes([
    {
      path: 'privacy_policy',
      element: <PrivacyPolicy />,
    },
    { path: 'terms_of_use', element: <TermsOfUse /> },
  ])
