import React, { useCallback, useEffect, useMemo } from 'react'
import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY, ONE_LINK_URL } from 'root-constants'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectVariantCohort } from 'root-redux/common/common-selects'
import { eventLogger } from 'services/eventLogger-service'
import downloadCat from 'assets/images/download-app.jpg'
import { StyledDownloadAppPage as S } from './DownloadApp.styles'

export const DownloadApp: React.FC = () => {
  const { t } = useTranslation()
  const cohort = useSelector(selectVariantCohort)
  const downloadAppLink = useMemo(() => {
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return customToken
      ? `${ONE_LINK_URL}?deep_link_value=${customToken}`
      : ONE_LINK_URL
  }, [cohort])

  useEffect(() => {
    eventLogger.logGettingAppShown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(
    () => eventLogger.logDownloadClicked({ cb: handleContinue }),
    [handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {t`account.accountCreated`} <br /> {t`account.justMoreStep`}
        </S.Title>
        <S.List>
          <li>
            <S.ListLabel data-number="1" />
            <S.ListText>Click the button to download Meowz App.</S.ListText>
          </li>
          <li>
            <S.ListLabel data-number="2" />
            <S.ListText>
              Open the app and choose the same login method you used to start
              your journey.
            </S.ListText>
          </li>
        </S.List>
        <S.CatImage src={downloadCat} />
        <S.Footer>
          <S.DownloadButton
            type="button"
            data-deep-link={downloadAppLink}
            onClick={handleClick}
          >
            {t`account.download`} Meowz
          </S.DownloadButton>
        </S.Footer>
      </S.Column>
    </S.Wrapper>
  )
}
