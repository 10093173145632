import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TimeInterval } from 'root-constants'
import {
  DAYS_IN_PERIOD,
  PeriodCountByDays,
} from 'modules/subscriptions/constants'
import { StyledPlanBlock as S } from './PlanBlock.styles'

export const PlanItemVariant2: React.FC<ITrialSelectPlanItemProps> = ({
  subscription: { durationDays, daily, fullPrice, oldPrices },
  id,
  isDefault,
  isSelected,
  isNonPayerFlow,
}) => {
  const { t } = useTranslation()

  return (
    <S.PlanItem
      isSelected={isSelected}
      isDefault={isDefault}
      key={id}
      isNonPayerFlow={isNonPayerFlow}
    >
      {isDefault && (
        <S.MostPopularBadge>{t`onboarding.planBlock.mostPopularBadge`}</S.MostPopularBadge>
      )}
      <S.PlanTitle>
        {durationDays === DAYS_IN_PERIOD[TimeInterval.WEEK] ? (
          `${durationDays}-days plan`
        ) : (
          <Trans
            i18nKey="onboarding.planBlock.planPeriod2"
            values={{ periodQuantity: PeriodCountByDays[durationDays] }}
          />
        )}
        <S.OldPricesWrapper>
          <S.StartOldPrice>${oldPrices.fullPrice}</S.StartOldPrice>
          <S.CurrentPrice>${fullPrice}</S.CurrentPrice>
        </S.OldPricesWrapper>
      </S.PlanTitle>
      <S.PricesWrapper>
        {daily && (
          <S.PricePerPeriod isSelected={isSelected} isDefault={isDefault}>
            <S.OldIntervalPrice>${oldPrices.daily}</S.OldIntervalPrice>
            <strong>${daily}</strong>
            {t`onboarding.planBlock.per`}&nbsp;
            {t`onboarding.planBlock.day`}
          </S.PricePerPeriod>
        )}
      </S.PricesWrapper>
    </S.PlanItem>
  )
}
