import discount from 'assets/images/sprite/discount-label.svg'
import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { CURRENT_YEAR, PLAN_TYPES } from 'root-constants'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import { Guarantee } from 'modules/subscriptions/components/guarantee/Guarantee'
import { Comments } from 'modules/subscriptions/components/comments/Comments'
import { ScreenName } from 'services/eventLogger-service'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { DidYouKnowBlock } from 'modules/subscriptions/components/did-you-know/DidYouKnowPage'
import { FooterPriceText } from 'modules/subscriptions/components/footer-price/FooterPriceText'
import { ObtainingListVariant2 } from 'modules/subscriptions/components/obtaining-list/ObtainingListVariant2'
import { StickyHeader } from 'modules/subscriptions/components/sticky-header/StickyHeader'
import { InterestingFact } from 'modules/subscriptions/components/interesting-fact/InterestingFact'
import { PlanItemVariant2 } from 'modules/subscriptions/components/plan-blocks/PlanItemVariant2'
import { SelectPlanItemsBlock } from 'modules/subscriptions/components/plan-blocks/SelectPlanItemsBlock'
import { CatCardVariant2 } from 'modules/subscriptions/components/cat-card/CatCardVariant2'
import { PrivacyPolicyLink } from 'modules/subscriptions/components/privacy-policy/PrivacyPolicyLink'
import { ISvg } from 'components/SvgImage'
import { StyledSubscriptions as S } from './Subscriptions.styles'
import { PageId } from '../../../constants/pages'

const CatSubscriptionsPage: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
  isNonPayerFlow,
  selectedSubscription,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper2>
      <S.ContentVariant2>
        <StickyHeader />
        <CatCardVariant2 />
        <DidYouKnowBlock />
        <ObtainingListVariant2 />
        <InterestingFact />
        <SelectPlanItemsBlock
          onButtonClick={onButtonClick}
          onSelect={onSubscriptionSelect}
          SelectPlanItem={PlanItemVariant2}
          availableSubscriptions={availableSubscriptions}
          isNonPayerFlow={isNonPayerFlow}
        />
        <Guarantee />
        <Comments />
        <S.FinalBlockWrapper>
          <S.PlanButtonWrapper>
            <S.DiscountImage svg={discount as ISvg} />
            <GreenArrowButton onClick={onButtonClick} text="Get My Plan" />
          </S.PlanButtonWrapper>
          <S.Agreement>
            <FooterPriceText selectedSubscription={selectedSubscription} />
            <br />
            {t`footer.disclaimer`}&nbsp;
            <TermsOfUseLink>{t`commonComponents.termsOfUse`}</TermsOfUseLink>
            &nbsp;|&nbsp;
            <PrivacyPolicyLink />
            <br />
            &nbsp;
            <Trans
              values={{ year: CURRENT_YEAR }}
              i18nKey="footer.allRightsReserved"
            />
          </S.Agreement>
          &nbsp;
        </S.FinalBlockWrapper>
      </S.ContentVariant2>
    </S.Wrapper2>
  )
}
const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.SALES}
    planType={PLAN_TYPES.PURCHASE}
  >
    {(props: ISubscriptionsPageProps) => <CatSubscriptionsPage {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.SUBSCRIPTIONS_2

export const SubscriptionsVariant2 = Component
