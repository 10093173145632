import firebase from 'firebase/compat/app'
import {
  DEFAULT_WEB_FUNNELS_CONFIG_FILE,
  FirebaseRemoteConfigKey,
} from 'root-constants'
import { getWebFunnelsConfigFromRawData } from 'helpers/getWebFunnelsConfigFromRawData'
import { IRemoteConfigFile } from 'models/config.model'

export const getWebFunnelsConfig = (
  firebaseRemoteConfig: firebase.remoteConfig.RemoteConfig | null,
): IRemoteConfigFile => {
  if (!firebaseRemoteConfig) {
    return DEFAULT_WEB_FUNNELS_CONFIG_FILE
  }

  const value =
    firebaseRemoteConfig[FirebaseRemoteConfigKey.WEB_FUNNELS_CONFIG_FILE]

  if (value) {
    return getWebFunnelsConfigFromRawData(JSON.parse(value.asString()))
  }

  return DEFAULT_WEB_FUNNELS_CONFIG_FILE
}
