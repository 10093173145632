import { Button } from 'components/buttons/Button'
import { useConfirmationPasswordInputField } from 'hooks/useConfirmationPasswordInputField'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { usePasswordInputField } from 'hooks/usePasswordInputField'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LoginMethod } from 'root-constants'
import { eventLogger } from 'services/eventLogger-service'
import { selectIsFetching } from 'root-redux/common/common-selects'
import { TAppDispatch } from 'root-redux/store'
import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
} from 'root-redux/account/account-actions'
import {
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from 'root-redux/account/account-selects'
import { AccountInput } from 'components/input/AccountInput'
import { StyledEmailAccount as S } from './EmailAccount.styles'

type TProps = {
  chooseOtherMethodsShown: boolean
  handleChooseOtherMethods: () => void
}

export const EmailAccount: React.FC<TProps> = ({
  chooseOtherMethodsShown,
  handleChooseOtherMethods,
}) => {
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const [email, setEmail, validateEmail] = useEmailInputField(
    emailErrorMessage,
    () => dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword, validatePassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )
  const [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ] = useConfirmationPasswordInputField(password)

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '' &&
      confirmationPassword.isValid &&
      confirmationPassword.value !== '',
    [
      confirmationPassword.isValid,
      confirmationPassword.value,
      email.isValid,
      email.value,
      password.isValid,
      password.value,
    ],
  )

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <AccountInput
          value={email.value}
          isValid={email.isValid}
          validationText={email.validationText}
          labelName={t`onboarding.email.email`}
          type="email"
          autoComplete="off"
          placeholder="goodboy@dogmail.com"
          onChange={(e) => {
            setEmail((prevState) => ({
              ...prevState,
              value: e.target.value,
              isValid: true,
              validationText: '',
            }))
          }}
          onBlur={(e) => validateEmail(e.target.value)}
        />
        <AccountInput
          value={password.value}
          isValid={password.isValid}
          validationText={password.validationText}
          hintText={t`account.shortPassword`}
          labelName={t`account.password`}
          placeholder={t`account.typeYouPassword`}
          hasVisibilityControl
          onChange={(e) => {
            setPassword((prevState) => ({
              ...prevState,
              value: e.target.value,
              isValid: true,
              validationText: '',
            }))
          }}
          onBlur={(e) => validatePassword(e.target.value)}
        />
        <AccountInput
          value={confirmationPassword.value}
          isValid={confirmationPassword.isValid}
          validationText={confirmationPassword.validationText}
          hasVisibilityControl
          labelName={t`account.confirmPassword`}
          placeholder={t`account.confirmYouPassword`}
          onChange={(e) => {
            setConfirmationPassword((prevState) => ({
              ...prevState,
              value: e.target.value,
              isValid: true,
              validationText: '',
            }))
          }}
          onBlur={(e) => validateConfirmationPassword(e.target.value)}
        />
      </S.FormWrapper>
      {chooseOtherMethodsShown && (
        <S.OtherMethodsButton onClick={handleChooseOtherMethods}>
          {t`account.chooseOtherMethods`}
        </S.OtherMethodsButton>
      )}
      <Button
        disabled={!isComplete || isFetching}
        onClick={() => {
          eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })

          dispatch(
            registerWithEmailFirebaseAction(
              email.value.toLowerCase(),
              password.value,
            ),
          )
        }}
      >
        {t`account.signUp`}
      </Button>
    </S.Wrapper>
  )
}
