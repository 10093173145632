import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StyledTotalAmount as S } from './TotalAmountVariant3.styles'
import { selectIsCancelOfferApplied } from 'root-redux/common/common-selects'
import { selectTrialDurationDays } from 'root-redux/payment/payment-selects'
import { getCustomPlanPrices } from 'helpers/getCustomPlanPrices'

export const TotalAmountVariant3: React.FC = () => {
  const trialPeriodDays = useSelector(selectTrialDurationDays)
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)

  const planPrices = useMemo(
    () => getCustomPlanPrices(isCancelOfferApplied),
    [isCancelOfferApplied],
  )

  const isTrial = useMemo(() => trialPeriodDays === 7, [trialPeriodDays])

  const discountPercent = useMemo(
    () => (isCancelOfferApplied ? '71%' : '61%'),
    [isCancelOfferApplied],
  )

  const getDiscountAmount = useMemo(
    () =>
      (
        planPrices[trialPeriodDays]?.oldFullPrice -
        planPrices[trialPeriodDays]?.fullPrice
      ).toFixed(2),
    [planPrices, trialPeriodDays],
  )

  return (
    <S.TotalPriceWrapper>
      <S.TotalPriceContent>
        <S.TotalPriceItem>
          <p>
            {isTrial
              ? 'Regular 1-week trial price'
              : `Regular ${trialPeriodDays / 7}-week price`}
          </p>
          <strong>${planPrices[trialPeriodDays]?.oldFullPrice}</strong>
        </S.TotalPriceItem>
        <S.TotalPriceItem>
          {discountPercent} introductory offer discount
          <S.DiscountAmount>-${getDiscountAmount}</S.DiscountAmount>
        </S.TotalPriceItem>
      </S.TotalPriceContent>
      <S.TotalWrapper>
        <p>Total today</p>
        <p>${planPrices[trialPeriodDays]?.fullPrice}</p>
      </S.TotalWrapper>
      <S.TotalDiscountInfo>
        <S.DiscountAmount>
          🔥 You just saved ${getDiscountAmount} ({discountPercent}
          &nbsp;off)
        </S.DiscountAmount>
      </S.TotalDiscountInfo>
    </S.TotalPriceWrapper>
  )
}
