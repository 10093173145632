import { commonReducer } from 'root-redux/common/common-reducer'
import { userReducer } from 'root-redux/user/user-reducer'
import { paymentReducer } from 'root-redux/payment/payment-reducer'
import { accountReducer } from 'root-redux/account/account-reducer'

export const rootReducer = {
  common: commonReducer,
  user: userReducer,
  payment: paymentReducer,
  account: accountReducer,
}

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}
