/* eslint-disable max-lines */
import React, { useMemo } from 'react'
import { getContactFormLink } from 'helpers/getContactFormLink'
import { StyledPolicyPages as S } from './styles'

export const PrivacyPolicy: React.FC = () => {
  const contactFormLink = useMemo(() => getContactFormLink(), [])

  return (
    <S.Wrapper>
      <S.Column>
        <S.H2>Privacy Policy</S.H2>
        <S.EffectiveDate>LAST UPDATED: August 01, 2024</S.EffectiveDate>
        <S.H3>ABOUT US</S.H3>
        <p>
          Our Platforms (<strong>“Platforms”</strong> means the website(s),
          including but not limited to&nbsp;
          <S.InnerLink to="https://meowz.app" aria-label="Link to Homepage">
            https://www.meowz.app
          </S.InnerLink>
          &nbsp; (the <strong>“Site”</strong>); mobile applications (means
          applications and games (each an <strong>“App”</strong>,
          collectively&nbsp;
          <strong>“Apps”</strong>)) and any related documentation, services; any
          images, logos, music, photographs and video content, software,
          designs, graphics, photos, images, illustrations, animations, videos,
          scripts, texts, music, sounds, voiceover, interactive features,
          wellness plans, and all other materials and content accessible within
          the Apps or Site that are incorporated into and form part of our Apps
          and etc. (<strong>“App Content”</strong>) are owned, managed and
          operated by the Company).
        </p>
        <p>Company shall mean:</p>
        <p>
          <strong>NOVE8 LTD</strong>, a company duly incorporated in the
          Republic of Cyprus with registration number ΗΕ 456334 and registered
          address at Prodromos, 75, ONEWORLD PARKVIEW HOUSE, Floor 4, 2063,
          Nicosia, Cyprus
        </p>
        <p>We can be contacted by writing to:</p>
        <p>
          <strong>NOVE8 LTD</strong>, Prodromos, 75, ONEWORLD PARKVIEW HOUSE,
          Floor 4, 2063, Nicosia, Cyprus, via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          .
        </p>
        <p>
          At NOVE8 LTD we are dedicated to leveraging technology to enhance
          users&apos; physical and emotional well-being, foster enjoyment
          through music and entertainment, and boost productivity and
          convenience in mobile device usage.
        </p>
        <p>
          We believe in the remarkable potential of technology to drive positive
          change and are committed to the highest standards of privacy and
          security. As trusted keepers of your personal data, we prioritize
          transparency and accountability in our data practices, ensuring that
          you have full knowledge of your data while benefiting from our
          Platforms.
        </p>
        <p>
          When you use Platforms, we may collect, store and process some data,
          including personal data. This privacy policy (“
          <strong>Privacy Policy</strong>”) sets out the main principles on
          which the data collected from you, or that you provide to us, will be
          processed by us. This Privacy Policy also aims to remind you about
          your rights and to provide you with all the elements you need to
          exercise them. In accordance with data protection legislation (GDPR,
          UK GDPR, CCPA and etc.), we act as the controller of your personal
          data. In some cases, we may act as the processor of personal data, as
          indicated in this policy below. For data protection legislation in the
          United Kingdom, we are the controller of your personal data, and,
          regarding a part of personal data, the processor of your personal
          data.
        </p>
        <p>
          We encourage you to review our Privacy Policy in its entirety to gain
          insight into our data handling practices. We have meticulously crafted
          this policy to be clear and accessible, but if you have any questions
          or concerns, please don’t hesitate to contact us at&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          &nbsp;or the address below for further information:
        </p>
        <strong>NOVE8 LTD</strong>
        <p>
          Prodromos, 75, ONEWORLD PARKVIEW HOUSE, Floor 4, 2063, Nicosia,
          Cyprus.
        </p>
        <strong>
          IF YOU DO NOT ACCEPT THE TERMS OF THE PRIVACY POLICY, PLEASE DO NOT
          USE OUR PLATFORMS.
        </strong>
        <S.H3>YOUR DATA COLLECTED BY US</S.H3>
        <p>
          As you engage with our Platforms, we gather data concerning a
          recognized or identifiable living individual (”personal data”) through
          the following means:
        </p>
        <p>
          <strong>Data Directly Provided by You</strong>: this encompasses any
          information you manually input or furnish to us while utilizing our
          Platforms. For instance, this might include details like your name,
          email address, phone number, or any other information you decide to
          disclose during registration or account setup.
        </p>
        <p>
          <strong>Data Automatically Collected by Us</strong>: when you access
          our Platforms, we automatically procure certain details regarding your
          engagement and activities within the Platforms. This may entail
          specifics about your device, such as its model, operating system,
          unique identifiers, IP address, and data related to your actions
          within the Platforms.
        </p>
        <p>
          <strong>Data Acquired via Cookies</strong>: to understand your
          interactions with our Platforms better, we utilize cookies and similar
          technologies. Cookies enable us to retain particular details about
          you, such as your preferences or previous interactions, thereby
          enhancing your experience on our Platforms and delivering tailored
          content. You can find more in our Cookie Policy.
        </p>
        <S.H3>WHY WE PROCESS YOUR DATA</S.H3>
        <p>
          Data processing is crucial to enable your usage of our Platforms.
          Whenever you use our Platforms, some personal and non-personal data is
          collected, stored, and analyzed using internal and third-party tools.
        </p>
        <p>
          Below are several additional purposes for which we process your data:
        </p>
        <S.PointList>
          <li>
            <strong>provide customer support</strong>, we may engage in email
            communications with you if you have contacted our support team with
            any queries relating to our Platforms.
          </li>
          <li>
            <strong>enhance the features of our Platforms</strong>, we analyze
            how you interact with the Platforms to tailor the experience to your
            needs. This includes refining prediction accuracy, developing new
            algorithms for enhanced insights, and generating content on topics
            of interest to you.
          </li>
          <li>
            <strong>enhance the technical aspects of our Platforms</strong>, we
            continuously work to improve your experience and streamline features
            for your benefit.
          </li>
          <li>
            <strong>optimize our advertising strategies</strong>, we analyze the
            performance of our advertising campaigns on the Platforms. This
            helps us identify your engagement and subscription status, as well
            as gauge interaction with our advertisements.
          </li>
          <li>
            <strong>improve our Site</strong>, we utilize website analytics to
            track performance and understand user behavior. This aids us in
            offering an improved user experience and optimizing our Site’s
            functionality.
          </li>
        </S.PointList>
        <S.H3>HOW YOUR DATA IS HANDLED</S.H3>
        <p>
          Depending on which features of the Platforms you use, we will process
          your personal data based on one or more of the following legal bases
          (we have provided you with some examples):
        </p>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>TYPES OF LEGAL BASIS WE USE</strong>
          </S.FlexItem>
          <S.FlexItem>
            <strong>DESCRIPTION</strong>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Legitimate interest</strong>
          </S.FlexItem>
          <S.FlexItem>
            <p>
              We process your usage data on this legal basis to technically
              improve the Platforms based on our legitimate interest as a
              Company to continuously improve the Platforms and deliver an
              improved service to you.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Contract</strong>
          </S.FlexItem>
          <S.FlexItem>
            <p>
              We process your usage data on this legal basis to fulfill the
              contractual duty of granting you access to use our Platforms.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Legal obligation</strong>
          </S.FlexItem>
          <S.FlexItem>
            <p>
              We process your usage data on this legal basis to comply with
              pertinent laws and regulations. This implies that we may handle
              personal data as mandated by law, ensuring adherence to applicable
              legal mandates.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Consent</strong>
          </S.FlexItem>
          <S.FlexItem>
            <p>
              We process your usage data on this legal basis to promote
              Platforms and optimize our advertising efforts to effectively tell
              more people about Platforms.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <p>
          We are committed to being clear and transparent so that you can really
          understand what we do with your data. Below, we explain the purposes
          for which we process your personal data and the legal basis supporting
          this. We also give you some basic examples:
        </p>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>LEGAL BASIS</strong>
          </S.FlexItem>
          <S.FlexItem>
            <strong>PURPOSE OF PROCESSING</strong>
          </S.FlexItem>
          <S.FlexItem>
            <strong>EXAMPLE</strong>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Legitimate interest</strong>
          </S.FlexItem>
          <S.FlexItem>
            <p>Responding to your requests.</p>
          </S.FlexItem>
          <S.FlexItem>
            <p>
              We may process your name and email, e.g., to properly respond to
              your unsubscribe request.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Contract</strong>
          </S.FlexItem>
          <S.FlexItem>
            <p>
              Processing transactions and sending you related information,
              including confirmations and reminders for account management and
              other administrative purposes
            </p>
          </S.FlexItem>
          <S.FlexItem>
            <p>
              We may send you a reminder (e.g., via push notifications) if your
              subscription has expired or is due to expire, or we may email you
              containing your invoice, if applicable, using your device data.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Consent</strong>
          </S.FlexItem>
          <S.FlexItem>
            <p>Making offerings to you.</p>
          </S.FlexItem>
          <S.FlexItem>
            <p>We may offer you a discount for our subscriptions.</p>
          </S.FlexItem>
        </S.FlexContainer>
        <p>
          For the processing of physical data and special categories of personal
          data, including sensitive information like health data, explicit
          consent from the user serves as the legal basis. This consent is
          essential for enabling users to utilize the Platforms provided by us.
        </p>
        <p>
          Our Platforms offer the option to make purchases directly within the
          application (including subscriptions) or directly through the Site. If
          you choose to make a purchase within the application, you may use
          third-party payment systems and/or providers. We do not collect or
          process any information related to your payment instruments, such as
          your bank card number or its validity term, under any circumstances.
        </p>
        <p>
          However, when you make purchases directly through our Site, including
          subscriptions, you authorize an applicable payment system and/or
          provider to collect this payment information. We do not collect or
          process your payment data (such as credit card number, expiration
          date, etc.) for purchases made through our Platforms. We strongly
          recommend reviewing the Privacy Policy of the payment system you use
          for making payments, such as the privacy policy of Stripe:&nbsp;
          <S.InnerLink
            to="https://stripe.com/en-pl/privacy"
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://stripe.com/en-pl/privacy
          </S.InnerLink>
          .
        </p>
        <S.H3>DATA RETENTION</S.H3>
        <p>
          We retain your personal data for as long as your account is active or
          for as long as it is necessary for the purposes of its collection and
          processing (e.g., for resolving disputes, for safety and security
          reasons, or for complying with our legal obligations). However, the
          period for which we may retain your personal data cannot exceed 2
          (two) months from the date of account deactivation and/or deletion.
        </p>
        <S.H3>SHARING OF YOUR PERSONAL DATA</S.H3>
        <p>
          We may need to share your personal data with certain third parties as
          follows:
        </p>
        <strong>Third-party service providers:</strong>
        <p>
          This may include providers of certain systems and services that we use
          to host, administer, and maintain our Platforms, including the servers
          used to host our Platforms, email service providers, payment
          processors, fraud prevention vendors, analytics, customer service
          providers and other service providers.
        </p>
        <strong>Third-party service providers for marketing purposes:</strong>
        <p>
          If you consent to any marketing from us, certain personal data may be
          shared with third-party service providers we use to help us carry out
          marketing including, e.g., third-party marketing automation platforms.
        </p>
        <strong>Compliance with Laws:</strong>
        <p>We may disclose your personal data to a third party if:</p>
        <S.LatinList>
          <li>
            we believe that disclosure is reasonably necessary to comply with
            any applicable law, regulation, legal process, or governmental
            request; or
          </li>
          <li>to protect the security or integrity of the Platforms; or</li>
          <li>
            to protect us, our customers or the public from harm or illegal
            activities; or
          </li>
          <li>
            to respond to an emergency which we believe in the good faith
            requires us to disclose information to assist in preventing the
            death or serious bodily injury of any person.
          </li>
        </S.LatinList>
        <strong>Aggregated or Anonymized Data:</strong>
        <p>
          We may also share aggregated or anonymized information with third
          parties that do not directly identify you.
        </p>
        <strong>Group companies:</strong>
        <p>
          Your personal data may be shared among affiliates and subsidiaries. In
          such cases, these companies must abide by our data privacy and
          security requirements and are not allowed to use personal data they
          receive from us for any other purpose. We may also disclose personal
          data as part of a corporate transaction, such as a merger or sale of
          assets.
        </p>
        <S.H3>
          THE FOLLOWING ARE A FEW OF THE ESSENTIAL PROCESSORS WE TRUST:
        </S.H3>
        <S.FlexContainer>
          <S.FlexItem>
            <strong>Processor’s name</strong>
          </S.FlexItem>
          <S.FlexItem>
            <strong>Processor’s privacy policy</strong>
          </S.FlexItem>
          <S.FlexItem>
            <strong>Purpose</strong>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Amplitude</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://amplitude.com/privacy"
              aria-label="Link to Amplitude Privacy Policy"
            >
              https://amplitude.com/privacy
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Track user interactions and engagement within the App. Collect
              user behavior data, event tracking, and App usage statistics to
              analyze user behavior, measure app performance, and improve user
              experience.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>AppsFlyer</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://www.appsflyer.com/legal/privacy-policy/"
              aria-label="Link to AppsFlyer Privacy Policy"
            >
              https://www.appsflyer.com/legal/privacy-policy/
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Provide mobile attribution and marketing analytics. Collect device
              information, user interactions, and attribution data to measure
              the effectiveness of marketing campaigns and improve user
              acquisition strategies.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Facebook (Analytics) </p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://www.facebook.com/privacy/policy/"
              aria-label="Link to Facebook Privacy Policy"
            >
              https://www.facebook.com/privacy/policy/
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Track user interactions and engagement within the App. Collects
              user behavior data, event tracking, and App usage statistics to
              analyze user behavior, measure app performance, and improve user
              experience.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Facebook Sharing (FBSDKShareKit)</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://www.facebook.com/privacy/policy/"
              aria-label="Link to Amplitude Privacy Policy"
            >
              https://www.facebook.com/privacy/policy/
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Allow users to share content from our App to Facebook. Collect
              content shared by users and user interactions with shared content
              for analytics purposes.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Google Sign-In</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://policies.google.com/privacy"
              aria-label="Link to Google Privacy Policy"
            >
              https://policies.google.com/privacy
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Allow users to sign in to our App using their Google account.
              Collects user’s Google ID, name, email address, and profile
              picture to authenticate users and provide a seamless login
              experience.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Firebase Crashlytics</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://firebase.google.com/support/privacy?hl=en"
              aria-label="Link to Firebase Privacy Policy"
            >
              https://firebase.google.com/support/privacy?hl=en
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Track and report App crashes and stability issues. Collect crash
              reports, device state information, and user interactions leading
              up to a crash to help us identify and fix bugs, ensuring a stable
              and reliable App experience.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Firebase Authentication</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://firebase.google.com/support/privacy?hl=en"
              aria-label="Link to Firebase Privacy Policy"
            >
              https://firebase.google.com/support/privacy?hl=en
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Provide secure authentication for users signing in to our App.
              Collect user identifiers (e.g., email, phone number) and
              authentication tokens to manage user sessions and secure access to
              the App.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Firebase Analytics</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://firebase.google.com/support/privacy?hl=en"
              aria-label="Link to Firebase Privacy Policy"
            >
              https://firebase.google.com/support/privacy?hl=en
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Track user interactions and engagement within the App. Collect
              user behavior data, event tracking, and App usage statistics to
              analyze user behavior, measure App performance, and improve user
              experience.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Firebase Fiestore</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://firebase.google.com/support/privacy?hl=en"
              aria-label="Link to Firebase Privacy Policy"
            >
              https://firebase.google.com/support/privacy?hl=en
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Provide a flexible, scalable database for storing and syncing App
              data in real-time. Collect and stores user- generated data,
              application data, and sync data across multiple devices to ensure
              data consistency and accessibility.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Firebase Dynamic Links</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://firebase.google.com/support/privacy?hl=en"
              aria-label="Link to Firebase Privacy Policy"
            >
              https://firebase.google.com/support/privacy?hl=en
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Create and manage deep links that direct users to specific content
              within the App. Collects data on link performance, user
              interactions with the links, and referral sources to improve user
              engagement and measure the effectiveness of marketing campaigns.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.FlexItem>
            <p>Firebase In-App Messaging</p>
          </S.FlexItem>
          <S.FlexLink>
            <S.PolicyLinks
              to="https://firebase.google.com/support/privacy?hl=en"
              aria-label="Link to Firebase Privacy Policy"
            >
              https://firebase.google.com/support/privacy?hl=en
            </S.PolicyLinks>
          </S.FlexLink>
          <S.FlexItem>
            <p>
              Send targeted messages to users within the App. Collect user
              interaction data with messages and in-app events to help us
              deliver relevant messages and promotions to users.
            </p>
          </S.FlexItem>
        </S.FlexContainer>
        <S.H3>DATA STORAGE AND CROSS-BORDER TRANSFERS</S.H3>
        <p>
          The personal data we maintain will primarily be stored and processed
          within the EU. We will do our best to keep this personal data secure.
          All information we hold is stored on our secure servers (which we own
          or license from appropriate third parties). We use industry-standard
          procedures and security standards to prevent unauthorized access to
          our servers.
        </p>
        <p>
          However, there may arise situations where we need to collaborate with
          trusted third parties located outside the EU to deliver services and
          subscriptions to you (for instance, when utilizing servers in the US).
          We choose our processors very carefully. We do not work with
          processors based in countries where we are concerned about the rule of
          law with respect to privacy.
        </p>
        <p>
          We have entered into Standard Contractual Clauses with all non-EEA
          third parties whose data processing tools we use (data processors) if
          there is no adequacy decision by the EU Commission for their
          particular country. We adhere to the principles of minimization and
          anonymization, where feasible, to ensure compliance with the GDPR and
          other relevant data privacy laws when transferring personal data, if
          necessary.
        </p>
        <p>
          By providing your personal data, you explicitly consent to its
          transfer, storage, or processing outside the EU. We will take all
          reasonable steps to ensure that this information is handled securely
          and in accordance with this Privacy Policy.
        </p>
        <S.H3>SECURITY</S.H3>
        <p>
          Ensuring the security of your data is a top priority for us. We employ
          robust technical and organizational measures to safeguard the personal
          information entrusted to us.
        </p>
        <p>
          Your personal data is safeguarded by the password you create when
          registering on our Platforms. It’s essential to choose a strong
          password and keep it confidential to prevent unauthorized access.
          Additionally, refrain from sharing your password and ensure the
          security of your computer or mobile device.
        </p>
        <p>
          We have instituted reasonable administrative, technical, and physical
          security measures to protect your personal data from unauthorized
          access, alteration, or destruction. For instance:
        </p>
        <S.PointList>
          <li>
            We utilize SSL encryption (HTTPS) for all interactions involving
            personal data.
          </li>
          <li>
            Our databases are encrypted, and we store data on physically secure
            servers protected by firewalls.
          </li>
        </S.PointList>
        <p>
          In the event of a personal data breach as defined in Article 4.12 of
          the GDPR, we will promptly notify you. This notification will include
          relevant details, measures taken, and an assessment of associated
          risks, as required by applicable law and our Privacy Policy. We are
          committed to addressing breaches promptly and transparently, taking
          necessary actions such as logging affected users out, initiating
          password resets, and other appropriate measures to mitigate the
          breach.
        </p>
        <p>
          To report a personal data breach or seek assistance, please contact us
          via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          . We will address your concerns accordingly.
        </p>
        <S.H3>YOUR PRIVACY RIGHTS</S.H3>
        <p>
          We aim to ensure that you are fully informed about all your data
          protection rights and the methods available to exercise them. Please
          note that these rights may vary depending on your location:
        </p>
        <S.PointList>
          <li>
            <strong>Access:</strong> you can request to receive a copy of the
            personal data we hold about you.
          </li>
          <li>
            <strong>Rectification:</strong> if you believe that any personal
            data, we are holding about you is incorrect or incomplete, you can
            request that we correct or supplement it. You can also correct some
            of this information directly from your account. Please contact us as
            soon as possible upon noticing any such inaccuracy or
            incompleteness.
          </li>
          <li>
            <strong>Objection:</strong> you can contact us to inform us that you
            object to the collection or use of your personal data for certain
            purposes.
          </li>
          <li>
            <strong>Erasure:</strong> you can request that we erase some or all
            of your personal data from our systems.
          </li>
          <li>
            <strong>Restriction of Processing:</strong> you can ask us to
            restrict or limit further processing of your personal data.
          </li>
          <li>
            <strong>Portability:</strong> you have the right to ask for a copy
            of your personal data in a machine- readable format. you can also
            request that we transmit the personal data to another entity where
            technically feasible.
          </li>
          <li>
            <strong>Withdrawal of Consent:</strong> if we are processing your
            personal data based on your consent (as indicated at the time of
            such data collection), you have the right to withdraw your consent
            at any time. Please note, however, that if you exercise this right,
            you may have then to provide express consent on a case-by-case basis
            for the use or disclosure of certain of your personal data, if such
            use or disclosure is necessary to enable you to utilize some or all
            Platforms.
          </li>
          <li>
            <strong>Right to File Complaint:</strong> you have the right to
            lodge a complaint about our practices with respect to your personal
            data with the supervisory authority of your country.
          </li>
        </S.PointList>
        <p>
          To exercise your rights, please contact us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          &nbsp;or write to us at the address set forth in the “Contact”
          section.
        </p>
        <p>
          If you submit a request, we typically aim to fulfill it within one
          month. If additional time is needed to assist you in exercising your
          rights, we will inform you accordingly. We reserve the right to reject
          manifestly unfounded or excessive requests.
        </p>
        <p>
          During the process of exercising your data protection rights, we may
          ask you to confirm your identity. This verification step ensures that
          you are entitled to access certain information and that the rights of
          third parties are not infringed upon. If we are unable to verify your
          request, we will be unable to fulfill it.
        </p>
        <S.H3>OUR POLICIES CONCERNING CHILDREN</S.H3>
        <p>
          If you are in the EU, you must meet the age requirement specified by
          the laws of your country to use our services, e.g. if you are at least
          16 years old in Germany, you are eligible to provide explicit consent
          for the processing of your data. Alternatively, if you are under the
          required age, you can still use our services with the consent of your
          parent or legal guardian. In the event that we learn that we have
          inadvertently gathered personal data from children, we will take
          reasonable measures to promptly erase such information from our
          records.
        </p>
        <p>
          If you are a parent and learn that your child is using our Platforms
          without your permission, or if you have a specific question about data
          privacy, do not hesitate to get in touch with us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          .
        </p>
        <p>
          If you are in the United States, you must be at least 13 years old to
          use the Platforms. If we become aware that information has been
          collected from children under the age of 13 in the United States in
          violation of the Children’s Online Privacy Protection Act of 1998 and
          its regulations, we will not disclose this data. We reserve the right
          to promptly delete the account and erase all associated information,
          including health and sensitive data, from our servers.
        </p>
        <S.H3>HOW CAN YOU MANAGE YOUR DATA?</S.H3>
        <p>
          If you wish to access, correct, or update your personal data, you can
          do so at any time by contacting us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          .
        </p>
        <p>
          If you would like us to delete your information, you may: (1) contact
          us &nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          &nbsp;and describe your request in a few words, and/or (2) open
          “Profile” in the App, select “Profile Details”, press “Delete account”
          button, fill subscription field, and press “Submit” button. If you
          proceed with the deletion of your account, you will no longer have
          access to the account or subscriptions associated with your account.
        </p>
        <p>
          Other steps you should take should you wish to delete your account
          include disassociating your Meta account from our applications, if
          applicable, and deleting the application(s) from your device
          (applicable only if the user has selected the option to create an
          account and log in via Meta). Please note that if you ask us to delete
          your account, all your progress in the application and any unused
          virtual items will be lost and we may not be able to restore them in
          the future.
        </p>
        <S.H3>CHANGES TO THIS PRIVACY POLICY</S.H3>
        <p>
          We reserve the right to amend this Privacy Policy from time to time to
          reflect changes in the law, our data collection and data use
          practices, the features of our Platforms, or advances in technology.
        </p>
        <p>
          Please check this page periodically for changes and refer to the “last
          updated” date at the top of the page to know if it has been revised
          since your last visit. If we make any changes to this Privacy Policy
          that we consider to be material to your consent, we will notify you of
          them.
        </p>
        <S.H3>PRIVACY NOTICE FOR CALIFORNIA RESIDENTS, US</S.H3>
        <p>
          <strong>
            We don’t sell your personal info to others for money, and we’re not
            in the business of trading data.
          </strong>
          But like many online companies, we team up with other parties to
          handle our ads on different platforms. Sometimes, we may share some
          basic personal info with them for targeted ads. This might be seen as
          ”selling” or ”sharing” under the CCPA. If you want to opt out of this,
          check out this Privacy Policy on how to protect your privacy.
        </p>
        <p>
          <strong>Opt-Out Provision</strong>: even though we don’t make money by
          selling your personal info, you still have the right to opt out of
          sharing it with our analytics and advertising partners, as defined by
          California or other relevant US state laws. You can do this by
          adjusting your browser settings, visiting the ”Your Privacy Choices”
          link on our Platforms, or by reaching out to us directly.
        </p>
        <p>
          <strong>Limitation on Handling Sensitive Information</strong>: we only
          process sensitive personal information when absolutely necessary to
          provide you with products or access to use Platforms.
        </p>
        <p>
          <strong>Request for Access</strong>: you have the right to ask for
          access to (i) the personal and sensitive information we have about you
          and how we use it; and (ii) the categories, sources, and third parties
          that have received your personal information or to whom it has been
          ”sold” or disclosed in the past 12 months. You can make this request
          twice a year at no cost.
        </p>
        <p>
          <strong>California Residents’ Rights</strong>: residents of California
          have the right to request, once a year, disclosure regarding any
          personal information shared with third parties for their separate
          direct marketing purposes. Even though we do not share information
          with third parties for marketing, you can reach out to us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          . Please ensure the subject line reads ”California Privacy Rights
          Request,” and include relevant details such as your name, street
          address, city, state, and ZIP code.
        </p>
        <p>
          All mentions of ”personal data” in this policy include
          ”sensitive/personal information” as defined by California laws.
        </p>
        <S.H3>
          PRIVACY NOTICE FOR VIRGINIA, CONNECTICUT, COLORADO, UTAH, AND NEVADA,
          US
        </S.H3>
        <p>
          We include this section for residents of other US states with privacy
          laws that may impact them. These privacy laws include the Virginia
          Consumer Data Privacy Act (“VCDPA”), the Connecticut Data Privacy Act
          (“CTDPA”), the Utah Consumer Privacy Act (“UCPA”), the Colorado
          Privacy Act (“CPA”), and the Nevada Privacy Law (“NPL”). This section
          is intended to comply with these laws by supplementing the information
          provided elsewhere in the Privacy Policy.
        </p>
        <p>
          <strong>Collection of personal information.</strong> We may collect
          the personal information as described and categorized above. Please
          note that some of this personal information will be considered
          sensitive under your state’s legal definition which can vary across
          different states. The personal information we may collect depending on
          how you use our Platform includes mental or physical health
          information, racial or ethnic origin, and information about sexual
          orientation or gender identity.
        </p>
        <p>
          <strong>Use of personal information.</strong> We may collect, use, or
          disclose personal information about US state residents for the
          purposes described above.
        </p>
        <p>
          <strong>Disclosure of personal information.</strong> We may disclose
          your personal information to the categories of service providers and
          third parties identified and described in this Privacy Policy.
        </p>
        <p>
          <strong>Your privacy rights.</strong> We generally provide the privacy
          rights described above to you regardless of your location. Your state
          may afford you additional privacy rights as noted below. To exercise
          your right, please see the contact information here or follow the
          instructions below for specific state rights. We will respond to your
          verifiable request within the time limit afforded under applicable
          law.
        </p>
        <p>
          <strong>
            Residents of Colorado, Connecticut, Virginia, and Utah
          </strong>{' '}
          have the right to opt out of targeted advertising and sales. Please
          know that we do not trigger this requirement because we do not sell
          your personal information for payment.
        </p>
        <p>
          <strong>For users in Colorado, Connecticut, and Virginia</strong>, you
          may opt out of profiling in furtherance of decisions that produce
          legal or similarly significant effects. While you may still make this
          request, we do not currently use profiling in this manner.
        </p>
        <p>
          <strong>Nevada</strong> provides its residents a limited right to opt
          out of the sale of personal information. Please know that we do not
          trigger this requirement because we do not sell your personal
          information for payment.
        </p>
      </S.Column>
    </S.Wrapper>
  )
}
