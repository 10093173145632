import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { useDispatch } from 'react-redux'
import { setLanguageAction } from 'root-redux/common/common-actions'
import { TAppDispatch } from 'root-redux/store'
import {
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  SUPPORTED_LOCALES,
} from 'root-constants'
import { routeHistory } from '../routerHistory'

export const useLanguage = (): void => {
  const { pathname, search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const langQueryParam = URLParams.get(LANG_QUERY_PARAM) || ''

    if (!SUPPORTED_LOCALES.includes(langQueryParam)) {
      const browserLang = window.navigator
        ? window.navigator.language.substring(0, 2)
        : ''
      const lang = SUPPORTED_LOCALES.includes(browserLang)
        ? browserLang
        : INITIAL_LANGUAGE

      dispatch(setLanguageAction(lang))

      URLParams.set(LANG_QUERY_PARAM, lang)
      routeHistory.replace({
        pathname,
        search: URLParams.toString(),
      })
      return
    }

    dispatch(setLanguageAction(langQueryParam))
  }, [dispatch, pathname, search])
}
