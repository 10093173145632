import appleIcon from 'assets/images/sprite/continue-with-apple.svg'
import fbIcon from 'assets/images/sprite/continue-with-facebook.svg'
import googleIcon from 'assets/images/sprite/continue-with-google.svg'
import { FlowContext } from 'components/FlowContext'
import { ISvg, SvgImage } from 'components/SvgImage'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { useAuthObserver } from 'hooks/useAuthObserver'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { LoginMethod, PlatformOS } from 'root-constants'
import { EmailAccount } from 'modules/account/components/EmailAccount'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { AccountCreationFailed } from 'modules/account/components/AccountCreationFailed'
import { Spinner } from 'components/spinner/Spinner'
import { PrivacyPolicyLink } from 'modules/subscriptions/components/privacy-policy/PrivacyPolicyLink'
import {
  selectError,
  selectFirebaseConfigFile,
  selectIsFetching,
} from 'root-redux/common/common-selects'
import { eventLogger } from 'services/eventLogger-service'
import {
  resetErrorAction,
  setErrorAction,
} from 'root-redux/common/common-actions'
import { bindUserAction } from 'root-redux/user/user-actions'
import { TAppDispatch } from 'root-redux/store'
import { selectUserStatus } from 'root-redux/user/user-selects'
import { StyledAccountButton } from './AccountButton.styles'
import { StyledAccount as S } from './Account.styles'

export const Account: React.FC = () => {
  const location = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const userStatus = useSelector(selectUserStatus)
  const { onNextStep } = useContext(FlowContext)
  const [isModalShown, setIsModalShown] = useState(false)
  const [isOtherMethodsShown, setIsOtherMethodsShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)
  const isAndroid = useMemo(
    () => getMobileOperatingSystem() === PlatformOS.ANDROID,
    [],
  )
  const {
    signUpMethods: {
      chooseOtherMethodsShown,
      otherMethodsShown: { facebook, apple, google },
    },
  } = useSelector(selectFirebaseConfigFile)

  const { t } = useTranslation()

  const signInFirebaseWithPopup = useCallback(
    (provider) => firebase.auth().signInWithPopup(provider),
    [],
  )

  useEffect(() => {
    window.removeEventListener('popstate', onNextStep)
  }, [onNextStep])

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  useEffect(() => {
    if (location.pathname === '/account') {
      setIsOtherMethodsShown(false)
    }
  }, [location.pathname])

  const handleContinueWithGoogle = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.GOOGLE })

    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    await signInFirebaseWithPopup(provider)
  }, [signInFirebaseWithPopup])

  const handleContinueWithFb = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.FACEBOOK })

    const provider = new firebase.auth.FacebookAuthProvider()
    provider.setCustomParameters({
      display: 'popup',
    })
    await signInFirebaseWithPopup(provider)
  }, [signInFirebaseWithPopup])

  const handleContinueWithApple = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.APPLE })

    await signInFirebaseWithPopup(new firebase.auth.OAuthProvider('apple.com'))
  }, [signInFirebaseWithPopup])

  const handleChooseOtherMethods = useCallback(() => {
    setIsOtherMethodsShown(true)
  }, [])

  const handleButtonsClick = useCallback(
    (event) => {
      if (error) {
        event.stopPropagation()
        setIsModalShown(true)
      }
    },
    [error],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      if (userStatus?.account.hasAccount) return

      dispatch(bindUserAction(token, onNextStep))
    },
    [dispatch, onNextStep, userStatus?.account.hasAccount],
  )

  const onError = useCallback(
    (redirectError) => {
      dispatch(setErrorAction(redirectError))
      eventLogger.logAccountCreationFailed({
        error: redirectError,
      })
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(
    authStateChangeHandler,
    setIsFirebaseDataLoading,
    onError,
  )
  useAuthObserver(authStateChangeHandler)

  return (
    <>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`account.welcome`}</S.Title>
          <S.SubtitleVariant2>
            <Trans i18nKey="account.signUpAndCreate" components={[<br />]} />
          </S.SubtitleVariant2>
          <EmailAccount
            chooseOtherMethodsShown={chooseOtherMethodsShown}
            handleChooseOtherMethods={handleChooseOtherMethods}
          />
          {isOtherMethodsShown && chooseOtherMethodsShown && (
            <S.Buttons onClickCapture={handleButtonsClick}>
              <S.Divider>
                <S.DividerText>{t`payment.OR`}</S.DividerText>
              </S.Divider>
              {facebook && (
                <StyledAccountButton.Facebook onClick={handleContinueWithFb}>
                  <SvgImage svg={fbIcon as ISvg} width={24} />
                  <span>{t`account.facebook`}</span>
                </StyledAccountButton.Facebook>
              )}
              {!isAndroid && apple && (
                <StyledAccountButton.Apple onClick={handleContinueWithApple}>
                  <SvgImage svg={appleIcon as ISvg} width={24} />
                  <span>{t`account.apple`}</span>
                </StyledAccountButton.Apple>
              )}
              {google && (
                <StyledAccountButton.Google onClick={handleContinueWithGoogle}>
                  <SvgImage svg={googleIcon as ISvg} width={24} />
                  <span>{t`account.google`}</span>
                </StyledAccountButton.Google>
              )}
            </S.Buttons>
          )}
        </S.Column>
        <S.Agreement>
          {t`account.bySigningUp`}&nbsp;
          <TermsOfUseLink>
            {t`commonComponents.termsOfUse`}&nbsp;
          </TermsOfUseLink>
          {t`commonComponents.and`}&nbsp;
          <PrivacyPolicyLink />
        </S.Agreement>
      </S.Wrapper>

      {isModalShown && (
        <AccountCreationFailed
          onClose={() => {
            setIsModalShown(false)
            dispatch(resetErrorAction())
          }}
        />
      )}
    </>
  )
}
