import { IRequestOptions, IResponseResult } from 'models/api.model'
import { RequestMethod } from 'services/api/api-types'
import { ApiService } from 'services/api/api-service'

export class UserApi {
  private readonly api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  saveFacebookParams({
    fbc,
    fbp,
    uuid,
  }: {
    uuid: string
    fbc: string
    fbp: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        fbc,
        fbp,
      },
    }

    return this.api.makeRequest<IResponseResult<string>>(
      `v2/leads/${uuid}/meowz/facebook`,
      options,
    )
  }

  saveUserTTCLID({
    ttclid,
    uuid,
  }: {
    ttclid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { ttclid },
    }
    return this.api.makeRequest(`v2/leads/${uuid}/tiktok`, options)
  }
}
