import boy from 'assets/images/sprite/cat-boy.svg'
import girl from 'assets/images/sprite/cat-girl.svg'
import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'src/constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledQuestion as S } from './Questions.styles'
import { SEX_OPTIONS } from '../../constants/options-constants'

const QUESTION = 'Your cat is:'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()
  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{QUESTION}</S.Title>

        <StyledOption.OptionsContainer>
          <S.Option {...optionProps} value={SEX_OPTIONS.GIRL}>
            <QuestionButton>
              <S.OptionImage svg={girl} />
              Girl
            </QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={SEX_OPTIONS.BOY}>
            <QuestionButton>
              <S.OptionImage svg={boy} />
              Boy
            </QuestionButton>
          </S.Option>
        </StyledOption.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.CAT_SEX

export const SexQuestion = Component
