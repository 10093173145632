import checkAnimation from 'assets/animation/checkAnimation.json'
import lottie from 'lottie-web/build/player/lottie_light'
import { Button } from 'components/buttons/Button'
import { FlowContext } from 'components/FlowContext'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TAppDispatch } from 'root-redux/store'
import { useDispatch } from 'react-redux'
import { setSuccessfulPaypalPayment } from 'root-redux/payment/payment-actions'
import { StyledPaymentStatus as S } from './PaymentStatus.styles'
import { LOCALES } from 'root-constants'

type TProps = {
  animationContainerRef: React.MutableRefObject<HTMLDivElement>
  isPaypal?: boolean
}

export const PaymentSuccess: React.FC<TProps> = ({
  animationContainerRef,
  isPaypal,
}) => {
  const { onNextStep, onAccountStep } = useContext(FlowContext)
  const { t, i18n } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const isNonUpsellLocale = useMemo(
    () => i18n.language === LOCALES.ES,
    [i18n.language],
  )

  useEffect(() => {
    window.addEventListener('popstate', onNextStep)
  }, [onNextStep])

  useEffect(() => {
    if (animationContainerRef.current) {
      const animation = lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: checkAnimation,
        loop: true,
      })
      animation.addEventListener('loopComplete', () => {
        animation.goToAndStop(2000) // end of animation loop
      })
    }

    return () => lottie.destroy()
  }, [animationContainerRef])

  const onContinue = useCallback(() => {
    if (isPaypal) {
      dispatch(setSuccessfulPaypalPayment(true))

      return
    }

    isNonUpsellLocale ? onAccountStep() : onNextStep()
  }, [isPaypal, onNextStep, setSuccessfulPaypalPayment])

  return (
    <S.Wrapper>
      <S.Container>
        <div ref={animationContainerRef} style={{ width: 100 }} />
        <S.Title>
          <Trans i18nKey="purchase.thankYou" components={[<br />]} />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="purchase.subtitle" components={[<br />]} />
        </S.Subtitle>
        <Button onClick={onContinue}>{t`actions.continue`}</Button>
      </S.Container>
    </S.Wrapper>
  )
}
