import { IStep } from 'models/variant.model'
import { PageId } from 'src/constants/pages'
import { IUserFlowContext } from 'services/userFlowState-service'
import { CS_COHORT } from 'root-constants'

export const getXStateStepMachine = (pages: IStep[]): any => ({
  initial: {
    always: [
      {
        guard: ({ context: ctx }: IUserFlowContext): boolean => {
          const {
            hasEmail,
            hasSubscription,
            onboarding,
            account: { hasAccount },
          } = ctx.userStatus

          return !hasEmail && !hasSubscription && !hasAccount && !!onboarding
        },
        target:
          pages.find((dynamicPage) => {
            const page = window.location.href.substring(
              window.location.href.lastIndexOf('/') + 1,
              window.location.href.indexOf('?'),
            )

            return page === dynamicPage.id
          })?.id || pages[0].id,
      },
      {
        guard: ({ context: ctx }: IUserFlowContext): boolean => {
          const {
            hasEmail,
            hasSubscription,
            account: { hasAccount },
          } = ctx.userStatus

          return !hasEmail && !hasSubscription && !hasAccount
        },
        target: pages[0].id,
      },
      {
        guard: ({ context: ctx }: IUserFlowContext): boolean =>
          !!ctx.userStatus?.account.hasAccount,
        target: 'download',
      },
      {
        guard: ({ context: ctx }: IUserFlowContext): boolean =>
          !!ctx.userStatus?.hasSubscription,
        target: !window.location.href.includes(CS_COHORT)
          ? 'account'
          : 'upsell_cs',
      },
      {
        guard: ({ context: ctx }: IUserFlowContext): boolean =>
          !!ctx.userStatus?.hasEmail,
        target: pages.find((page) => page.isSubscriptions)?.id || pages[0].id,
      },
    ],
  },
  ...pages.reduce((acc, page, index) => {
    const upsellPage = pages.find((dynamicPage) =>
      [PageId.UPSELL, PageId.UPSELL_CS].includes(dynamicPage.id),
    )?.id

    const isLastPage =
      (page.isPayment && !pages[index + 1]) || page.id === upsellPage

    const subscriptionsPageForNonPayerUsers = pages.find((dynamicPage) =>
      [
        PageId.CANCEL_OFFER,
        PageId.CANCEL_OFFER_2,
        PageId.SUBSCRIPTIONS_CS,
      ].includes(dynamicPage.id),
    )?.id

    const firstAdditionalPageForNonPayerUsers = pages.find((dynamicPage) =>
      [PageId.LAST_CHANCE_OFFER, PageId.LAST_CHANCE_OFFER_2].includes(
        dynamicPage.id,
      ),
    )?.id

    return {
      ...acc,
      [page.id]: {
        on: {
          NEXT_FLOW_STEP: {
            target: isLastPage ? 'account' : pages[index + 1].id,
          },
          PREVIOUS_FLOW_STEP: {
            target: index - 1 >= 0 ? pages[index - 1].id : pages[index].id,
          },
          SKIP_ONE_STEP_BACK: {
            target: index - 2 > 0 ? pages[index - 2].id : pages[index].id,
          },
          GO_TO_ACCOUNT_PAGE: {
            target: 'account',
          },
          SKIP_FLOW_STEPS: {
            target: (
              pages.find(
                (dynamicPage, dynamicPageIndex) =>
                  !dynamicPage.isSkippable && dynamicPageIndex > index,
              ) || page
            ).id,
          },
          ...(page.isPayment && {
            SUBSCRIPTIONS_FOR_NON_PAYER_USERS: {
              target: subscriptionsPageForNonPayerUsers,
            },
            FIRST_ADDITIONAL_PAGE_FOR_NON_PAYER_USERS: {
              target: firstAdditionalPageForNonPayerUsers,
            },
            GO_TO_UPSELL_PAGE: {
              target: upsellPage,
            },
          }),
        },
      },
    }
  }, {}),
  account: {
    on: {
      NEXT_FLOW_STEP: 'download',
    },
  },
  download: {
    type: 'final',
  },
  upsell_cs: {
    type: 'final',
  },
})
