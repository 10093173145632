import { useSelector } from 'react-redux'
import { selectUserStatus } from 'root-redux/user/user-selects'
import { useContext, useMemo } from 'react'
import { FlowContext } from 'components/FlowContext'
import { CS_COHORT } from 'root-constants'
import { selectVariantCohort } from 'root-redux/common/common-selects'
import { PageId } from '../constants/pages'

export const useGetAvailableRoute = (): PageId => {
  const userStatus = useSelector(selectUserStatus)
  const cohort = useSelector(selectVariantCohort)
  const { currentPageId } = useContext(FlowContext)

  const isCsCohort = useMemo(() => cohort === CS_COHORT, [cohort])

  if (userStatus?.account.hasAccount) {
    return PageId.DOWNLOAD
  }

  if (
    userStatus?.hasSubscription &&
    !currentPageId?.includes('payment') &&
    !isCsCohort
  ) {
    return PageId.ACCOUNT
  }

  return '' as PageId
}
