import React, { ButtonHTMLAttributes } from 'react'
import { StyledButtonVariant2 } from './ButtonVariant2.styles'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonRef?: React.Ref<HTMLButtonElement>
}

export const ButtonVariant2: React.FC<IProps> = ({
  children,
  buttonRef = null,
  ...props
}) => (
  <StyledButtonVariant2 ref={buttonRef} {...props}>
    {children}
  </StyledButtonVariant2>
)
