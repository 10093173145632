import styled from 'styled-components'
import { Button } from 'components/buttons/Button'
import { baseColumnStyles } from 'pages/styles'
import { MediaBreakpoint } from 'root-constants'
import { Link } from 'react-router-dom'

export const StyledPolicyModal = {
  Wrapper: styled.div`
    padding: 20px 16px;
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff5e9;
    height: 100vh;
    overflow: hidden;
  `,
  Container: styled.div`
    ${baseColumnStyles};
  `,
  Subtitle: styled(Link)`
    all: unset;
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
    text-align: center;

    @media (max-width: ${MediaBreakpoint.MIN_PHONE}px) {
      width: 328px;
    }

    @media (min-width: 361px) {
      width: 361px;
    }
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
