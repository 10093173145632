import { BREED_NAMES, POPULAR_BREEDS } from './constants'

export const getFilteredBreedNames = (enteredValue: string): string[] =>
  BREED_NAMES.filter((breed) =>
    breed
      .toLowerCase()
      .split(' ')
      .some((item) => item.startsWith(enteredValue.toLowerCase())),
  )

export const getFilteredPopularBreeds = (enteredValue: string): string[] =>
  POPULAR_BREEDS.filter((breed) =>
    breed
      .toLowerCase()
      .split(' ')
      .some((item) => item.startsWith(enteredValue.toLowerCase())),
  )
