import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'

import React from 'react'
import { PLAN_TYPES } from 'root-constants'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import { ScreenName } from 'services/eventLogger-service'
import { StickyHeader } from 'modules/subscriptions/components/sticky-header/StickyHeader'
import { DidYouKnowBlockCs } from 'modules/subscriptions/components/did-you-know/DidYouKnowBlockCs'
import { CatCardVariantCs } from 'modules/subscriptions/components/cat-card/CatCardVariantCs'
import { StyledSubscriptions as S } from 'modules/subscriptions/pages/Subscriptions.styles'
import { Benefits } from 'modules/subscriptions/components/benefits/Benefits'
import { GuaranteeCs } from 'modules/subscriptions/components/guarantee/GuaranteeVariantCs'
import { PlanItemVariantCs } from 'modules/subscriptions/components/plan-blocks/PlanItemVariantCs'
import { StripePaymentProcessingCs } from 'modules/payment/components/payment-processing/StripePaymentProcessingCs'
import { SelectPlanItemsBlockCs } from 'modules/subscriptions/components/plan-blocks/SelectPlanBlockCs'
import { PageId } from '../../../constants/pages'

const UpsellPageVariantCs: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
}) => (
  <S.Wrapper>
    <StripePaymentProcessingCs />
    <S.ContentVariant2>
      <StickyHeader buttonText="MAKE AN ORDER" />
      <CatCardVariantCs />
      <DidYouKnowBlockCs />
      <Benefits />
      <SelectPlanItemsBlockCs
        onButtonClick={onButtonClick}
        onSelect={onSubscriptionSelect}
        SelectPlanItem={PlanItemVariantCs}
        availableSubscriptions={availableSubscriptions}
        isUpsell
      />
      <GuaranteeCs />
    </S.ContentVariant2>
  </S.Wrapper>
)

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.UPSELL}
    planType={PLAN_TYPES.ONE_TIME}
  >
    {(props: ISubscriptionsPageProps) => <UpsellPageVariantCs {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.UPSELL_CS

export const UpsellVariantCs = Component
