import React from 'react'
import lockImg from 'assets/images/sprite/lock.svg'
import { ISvg } from 'components/SvgImage'
import { StyledComponents as S } from './styles'
import { useTranslation } from 'react-i18next'

export const PrivacyNote: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Note>
      <S.LockImg svg={lockImg as ISvg} />
      <S.Description>{t`commonComponents.privacyProtect`}</S.Description>
    </S.Note>
  )
}
