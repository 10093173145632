import firebase from 'firebase/compat/app'
import 'firebase/compat/remote-config'
import 'firebase/compat/auth'
import remoteConfigDefaults from 'assets/firebase/remote_config_defaults.json'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  // selectFirebaseConfig,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { setFirebaseRemoteConfigAction } from 'root-redux/common/common-actions'
import { TAppDispatch } from 'root-redux/store'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import {
  firebaseConfigDEV,
  firebaseConfigPROD,
} from '../constants/firebase-config'

export const useInitFirebase = (): void => {
  const dispatch: TAppDispatch = useDispatch()
  const currentEnv = getCurrentEnv()
  const firebaseConfig =
    currentEnv === 'prod' ? firebaseConfigPROD : firebaseConfigDEV
  // const firebaseConfig = useSelector(selectFirebaseConfig)
  const cohort = useSelector(selectVariantCohort)

  useEffect(() => {
    if (!firebaseConfig || firebase.apps.length || !cohort) return

    const initializeAndActivateRemoteConfig = async () => {
      firebase.initializeApp(firebaseConfig)
      firebase.auth().signOut()

      const remoteConfig = firebase.remoteConfig()
      remoteConfig.defaultConfig = remoteConfigDefaults

      await remoteConfig.fetchAndActivate()
      dispatch(setFirebaseRemoteConfigAction(remoteConfig.getAll()))
    }

    initializeAndActivateRemoteConfig()
  }, [dispatch, firebaseConfig, cohort])
}
