import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GENERAL_QUESTIONS_OPTIONS_VALUES } from 'root-constants'
import { PageId } from 'src/constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { BodyLanguageModal } from 'pages/questions/modals/BodyLanguageModalMessage'
import { StyledQuestion as S } from './Questions.styles'
import { BODY_LANGUAGE_OPTIONS_VALUES } from '../../constants/options-constants'
import { Trans, useTranslation } from 'react-i18next'

const QUESTION = 'Do you understand your cat`s body language?'

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isModalShown, setIsModalShown] = useState(false)

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  const openModal = useCallback(() => {
    setIsModalShown(true)
  }, [])

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Title>
            <Trans
              i18nKey="onboarding.bodyLanguage.question"
              components={[<br />]}
            />
          </S.Title>

          <StyledOption.OptionsContainer>
            <S.Option
              {...optionProps}
              onChange={openModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.QUITE_WELL}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.usuallyInterpret`}
              </QuestionButton>
            </S.Option>
            <S.Option
              {...optionProps}
              onChange={openModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.BASIC}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.basicUnderstanding`}
              </QuestionButton>
            </S.Option>
            <S.Option
              {...optionProps}
              onChange={openModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.CHALLENGING}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.challengingToUnderstand`}
              </QuestionButton>
            </S.Option>
            <S.Option
              {...optionProps}
              onChange={openModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.NO_IDEA}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.noIdea`}
              </QuestionButton>
            </S.Option>
          </StyledOption.OptionsContainer>
        </S.Content>
      </S.Wrapper>

      <BodyLanguageModal
        onClose={() => {
          setIsModalShown(false)
          handleContinue(GENERAL_QUESTIONS_OPTIONS_VALUES.YES)
        }}
        isShown={isModalShown}
      />
    </>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.BODY_LANGUAGE

export const BodyLanguageQuestion = Component
