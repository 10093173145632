import { Color } from 'root-constants'
import styled from 'styled-components'

export const StyledTotalAmount = {
  TotalPriceWrapper: styled.div`
    width: 100%;
    margin: 8px 0 16px;
  `,
  TotalPriceWrapper2: styled.div`
    width: 100%;
  `,
  TotalPriceContent: styled.div`
    border-bottom: 1px solid rgba(74, 53, 50, 0.1);
    padding-bottom: 12px;
  `,
  TotalPriceContent2: styled.div`
    border-top: 1px solid rgba(74, 53, 50, 0.1);
    border-bottom: 1px solid rgba(74, 53, 50, 0.1);
    padding: 12px 0;
  `,
  TotalPriceItem: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 20px;

    strong {
      font-weight: 800;
    }
  `,
  TotalPriceItem2: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    line-height: 20px;
    color: #7d6b66;

    strong {
      font-weight: 600;
    }
  `,
  DiscountAmount: styled.strong`
    font-size: 12px;
    line-height: 20px;
    font-weight: 800;
    color: ${Color.ERROR};
  `,
  DiscountAmount2: styled.strong`
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: ${Color.ERROR};
  `,
  TotalWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    margin: 12px 0;
  `,
  TotalAmount: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      color: #7d6b66;
    }
  `,
  TotalWrapper2: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(148, 206, 142, 0.1);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 12px 0;
    padding: 12px;
    border-radius: 8px;

    strong {
      color: ${Color.MEDIUM_GREEN};
      font-size: 16px;
    }
  `,
  TotalDiscountInfo: styled.div`
    width: 100%;
    text-align: right;
    border-bottom: 1px solid rgba(74, 53, 50, 0.1);
    padding-bottom: 12px;
  `,
  TotalDiscountInfo2: styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: ${Color.ERROR};

    img {
      width: 12px;
      margin-right: 4px;
    }
  `,
}
