import styled from 'styled-components'
import { desktopMediaQuery } from '../../pages/styles'

export interface IProps {
  disabled?: boolean
}

export const OptionContainerVariant2 = styled.label<IProps>`
  cursor: pointer;
  display: block;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  & > input {
    display: none;
  }

  [data-is-multiple='true'] & {
    width: calc(50% - 10px);
  }

  opacity: ${(props) => (props.disabled ? '50%' : 1)};
`

export const StyledOption = {
  OptionsContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;

    &[data-is-multiple='true'] {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media ${desktopMediaQuery} {
      margin-bottom: 24px;
    }
  `,
  OptionWrapper: styled.label`
    cursor: pointer;
    display: block;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    & > input {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    [data-is-multiple='true'] & {
      width: calc(50% - 4px);

      &:nth-child(2n + 1) {
        margin-right: 8px;
      }
    }
  `,
}
