/* eslint-disable max-lines */
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import {
  IEvent,
  IEventLogger,
  IEventLogParams,
  TQuestionPageParams,
} from 'models/events.model'
import { TProductId, TProductName } from 'models/variant.model'
import { IStripeAccount } from 'models/common.model'
import { EventLoggerInstanceName, LoginMethod } from 'root-constants'
import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'

export const enum ScreenName {
  PURCHASE = 'onboarding',
  SALES = 'sales_screen',
  CANCEL = 'cancel_offer',
  UPSELL = 'upsell',
}

export const enum Events {
  SESSION_STARTED = 'session_start',
  CONFIG_LOADED = 'config_loaded',
  SALE_SCREEN_SHOW = 'plans_page_show', // required for Amplituda events
  ONBOARDING_PAGE_COMPLETED = 'onboarding_page_completed',
  FEEDBACK_MESSAGE_SENT = 'feedback_message_sent',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed', // required for Amplituda events
  EMAIL_PAGE_ERROR = 'email_page_error',
  PAYMENT_METHOD_PAGE_SHOW = 'payment_method_page_show', // required for Amplituda events
  PURCHASE_SHOW = 'subs_purchase_show',
  PURCHASE_STARTED = 'subs_purchase_started', // required for Amplituda events
  PURCHASE_COMPLETED = 'subs_purchase_completed', // required for Amplituda events
  PURCHASE_FAILED = 'subs_purchase_failed', // required for Amplituda events
  SUBS_PURCHASE_CLOSE = 'subs_purchase_screen_close', // required for Amplituda events
  CREATE_ACCOUNT_PAGE = 'create_account_page', // required for Amplituda events
  LOGIN_METHOD_SELECTED = 'login_method_selected', // required for Amplituda events
  ACCOUNT_CREATED = 'account_created', // required for Amplituda events
  ACCOUNT_CREATION_FAILED = 'account_creation_failed', // required for Amplituda events
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view', // required for Amplituda events
  DOWNLOAD_BTN_PRESSED = 'download_button_press', // required for Amplituda events
  VIEW_PRICE = 'view_price', // required for Amplituda events
  AB_SEGMENT = 'ab_segment',
  PRESS_UPSELL_TOOLTIP = 'press_upsell_tooltip',
  INAPP_PURCHASE_COMPLETED = 'inapp_purchase_completed',
  CALENDLY_SESSION_BOOKED = 'session_booked',
  PAGE_SHOWED = 'page_showed',
  SUPPORT_EMAIL_PRESSED = 'support_mail_pressed',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  init(loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )
    this.loggers = new Map(entriesArr)
    this.notifyInitFinished()
  }

  addLoggers(loggers: IEventLogger[]): void {
    loggers.forEach((logger) => {
      this.loggers?.set(logger.name, logger)
    })
  }

  hasLogger(name: EventLoggerInstanceName): boolean {
    return !!this.loggers?.has(name)
  }

  logSessionStarted = ({
    optimizeVariantId,
    optimizeExperimentId,
    optimizeSegmentName,
    cohort,
  }: {
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    cohort: string
  }): void => {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      device_type: getMobileOperatingSystem(),
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
      cohort,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logConfigLoaded = ({
    optimizeExperimentId,
    cohort,
    abVariant,
    abSegmentName,
  }: {
    optimizeExperimentId: string
    abVariant: string
    abSegmentName: string
    cohort: string
  }): void => {
    const event = Events.CONFIG_LOADED
    const eventProperty = {
      device_type: getMobileOperatingSystem(),
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: abVariant,
      ab_segment_name: abSegmentName,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
      cohort,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logQuestion({
    question,
    answers,
    pageNumber,
    customProperties = {},
  }: TQuestionPageParams): void {
    const event = Events.ONBOARDING_PAGE_COMPLETED
    const eventProperty = {
      page_number: pageNumber,
      question,
      ...(answers && {
        answer: Array.isArray(answers) ? answers.join(',') : answers,
      }),
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
      ...customProperties,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logFeedbackMessageSent(): void {
    const event = Events.FEEDBACK_MESSAGE_SENT
    this.logEventOrPushToQueue({ event })
  }

  logCalendlySessionBooked(): void {
    const event = Events.CALENDLY_SESSION_BOOKED
    this.logEventOrPushToQueue({ event })
  }

  logSupportEmailPressed(): void {
    const event = Events.SUPPORT_EMAIL_PRESSED
    this.logEventOrPushToQueue({ event })
  }

  logPageShown({
    pageName,
    email,
    phone,
  }: {
    pageName: string
    email?: string
    phone?: string
  }): void {
    const event = Events.PAGE_SHOWED
    const eventProperty = {
      page_name: pageName,
      email,
      phone_number: phone,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    cohort,
    email,
    pageNumber,
    trialPrice,
    screenName,
  }: {
    productIds: string[]
    cohort: string
    email: string
    trialPrice?: number
    pageNumber?: number
    screenName?: ScreenName
  }): void {
    const event = Events.SALE_SCREEN_SHOW
    const eventProperty = {
      product_id: productIds.join(','),
      screen_name: screenName || ScreenName.PURCHASE,
      cohort,
      email,
      intro_price: trialPrice,
      ...(pageNumber !== undefined && { page_number: pageNumber }),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    const event = Events.EMAIL_PAGE_SHOW
    const eventProperty = {
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logEmailPageCompleted({ email }: { email: string }): void {
    const event = Events.EMAIL_PAGE_COMPLETED
    const eventProperty = {
      email,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logEmailPageError(eventProperty: { error: string }): void {
    const event = Events.EMAIL_PAGE_ERROR
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // Account Page events
  logCreateAccountShown(): void {
    const event = Events.CREATE_ACCOUNT_PAGE
    const eventProperty = {
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logLoginMethodSelected({ method }: { method: LoginMethod }): void {
    const event = Events.LOGIN_METHOD_SELECTED
    const eventProperty = {
      method,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logAccountCreated({ method }: { method: any }): void {
    const event = Events.ACCOUNT_CREATED
    const eventProperty = {
      method,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    const event = Events.ACCOUNT_CREATION_FAILED
    const eventProperty = {
      error_reason: error,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW
    this.logEventOrPushToQueue({ event })
  }

  logDownloadClicked({
    cb,
    productName,
    cohort,
  }: {
    cb?: () => void
    productName?: string
    cohort?: string
  }): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    const eventProperty = {
      cohort,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
      ...(productName && { product_name: productName }),
    }

    this.logEventOrPushToQueue({ event, eventProperty, cb })
  }

  // Payment
  logPaymentMethodPageShown({
    cohort,
    email,
    screenName,
  }: {
    cohort: string
    email: string
    screenName: string
  }): void {
    const event = Events.PAYMENT_METHOD_PAGE_SHOW
    const eventProperty = {
      cohort,
      screen_name: screenName,
      email,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPaymentMethodPageClose({
    cohort,
    email,
    productId,
    screenName,
  }: {
    cohort: string
    isCancelOfferApplied?: boolean
    email: string
    productId: TProductId
    screenName: string
  }): void {
    const event = Events.SUBS_PURCHASE_CLOSE
    const eventProperty = {
      product_id: productId,
      cohort,
      email,
      screen_name: screenName,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // SubscriptionsWrapper Page events
  logPurchaseShown({
    productName,
    productId,
    optimizeExperimentId,
    optimizeSegmentName,
    optimizeVariantId,
    cohort,
  }: {
    productId: TProductId
    productName?: TProductName
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    cohort: string
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      product_id: productId,
      product_name: productName,
      screen_name: ScreenName.PURCHASE,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
      cohort,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logSubscriptionsViewPrice({
    cohort,
    method,
    isNonPayerFlow = false,
  }: {
    cohort: string
    method: string
    isNonPayerFlow?: boolean
  }): void {
    const event = Events.VIEW_PRICE
    const eventProperty = {
      cohort,
      method,
      screen_name: isNonPayerFlow ? ScreenName.SALES : ScreenName.CANCEL,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPressUpsellTooltip({
    cohort,
    productName,
  }: {
    cohort: string
    productName: string
  }): void {
    const event = Events.PRESS_UPSELL_TOOLTIP
    const eventProperty = {
      cohort,
      product_name: productName,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPurchaseStarted({
    productId,
    productName,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    optimizeExperimentId,
    optimizeSegmentName,
    optimizeVariantId,
    email,
    cohort,
    screenName,
    stripeAccount,
  }: {
    productId: TProductId
    productName?: TProductName
    priceDetails: { price: number; trial?: boolean; currency?: string }
    paymentMethod: PaymentMethod
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    email: string
    cohort: string
    screenName?: string
    stripeAccount?: IStripeAccount
  }): void {
    const event = Events.PURCHASE_STARTED
    const eventProperty = {
      trial,
      price,
      currency,
      email,
      cohort,
      product_id: productId,
      product_name: productName,
      screen_name: screenName || ScreenName.PURCHASE,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
      payment_system:
        paymentMethod === PaymentMethod.PAYPAL
          ? PaymentSystem.PAYPAL
          : PaymentSystem.STRIPE,
      ...(paymentMethod !== PaymentMethod.PAYPAL &&
        stripeAccount && {
          stripe_account_id: stripeAccount.accountId,
          stripe_account_name: stripeAccount.accountName,
        }),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPurchaseCompleted({
    productId,
    productName,
    priceDetails: { price, trial = false, currency = 'USD' },
    optimizeExperimentId,
    optimizeSegmentName,
    optimizeVariantId,
    paymentMethod,
    discountApplied,
    transactionId,
    email,
    cohort,
    screenName,
    isUpsell,
    productPrice,
    stripeAccount,
  }: {
    productId: TProductId
    productName?: TProductName
    priceDetails: { price: number; trial?: boolean; currency?: string }
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    paymentMethod?: PaymentMethod
    discountApplied?: string
    transactionId?: string
    email: string
    cohort: string
    screenName?: string
    isUpsell?: boolean
    productPrice: string
    stripeAccount?: IStripeAccount
  }): void {
    const event = isUpsell
      ? Events.INAPP_PURCHASE_COMPLETED
      : Events.PURCHASE_COMPLETED
    const eventProperty = {
      trial,
      price,
      currency,
      email,
      cohort,
      product_name: productName,
      product_id: productId,
      screen_name: screenName || ScreenName.PURCHASE,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
      product_price: productPrice,
      payment_system:
        paymentMethod === PaymentMethod.PAYPAL
          ? PaymentSystem.PAYPAL
          : PaymentSystem.STRIPE,
      ...(paymentMethod !== PaymentMethod.PAYPAL &&
        stripeAccount && {
          stripe_account_id: stripeAccount.accountId,
          stripe_account_name: stripeAccount.accountName,
        }),
      ...(transactionId && { transaction_id: transactionId }),
      ...(discountApplied && { discount_applied: discountApplied }),
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPurchaseFailed({
    productId,
    productName,
    priceDetails: { price, trial = false, currency = 'USD' },
    optimizeExperimentId,
    optimizeSegmentName,
    optimizeVariantId,
    error: { description, type, code, declineCode },
    paymentMethod,
    screenName,
    stripeAccount,
    email,
  }: {
    productId: TProductId
    productName?: TProductName
    priceDetails: { price: number; trial?: boolean; currency?: string }
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    error: {
      type: any
      description?: string
      code?: string
      declineCode?: string
    }
    paymentMethod?: PaymentMethod
    screenName?: string
    stripeAccount?: IStripeAccount
    email: string
  }): void {
    const event = Events.PURCHASE_FAILED
    const eventProperty = {
      trial,
      price,
      currency,
      email,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      ...(declineCode && { decline_code: declineCode }),
      product_id: productId,
      product_name: productName,
      screen_name: screenName || ScreenName.PURCHASE,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
      payment_system:
        paymentMethod === PaymentMethod.PAYPAL
          ? PaymentSystem.PAYPAL
          : PaymentSystem.STRIPE,
      ...(paymentMethod !== PaymentMethod.PAYPAL &&
        stripeAccount && {
          stripe_account_id: stripeAccount.accountId,
          stripe_account_name: stripeAccount.accountName,
        }),
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  updateAmplitudeUserProperties(options: Record<string, any>): void {
    if (
      this.loggers?.has(EventLoggerInstanceName.AMPLITUDE) &&
      this.loggers?.get(EventLoggerInstanceName.AMPLITUDE)?.updateUserProperties
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.loggers
        ?.get(EventLoggerInstanceName.AMPLITUDE)
        .updateUserProperties(options)
    }
  }

  private logEventOrPushToQueue({
    event,
    eventProperty,
    cb,
  }: IEventLogParams): void {
    if (this.loggers?.size) {
      this.logEvent({ event, eventProperty, cb })
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({ event, eventProperty }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({ event, eventProperty, cb }: IEventLogParams): void {
    this.loggers?.forEach((logger) => {
      logger.log({ event, eventProperty, cb })
    })
  }
}

export const eventLogger = new EventLoggerService()
