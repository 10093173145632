export const firebaseConfigPROD = {
  apiKey: 'AIzaSyAJpq22YSehG4Tf44pHIpRhBJY3irczxBM',
  authDomain: 'meowz-d84a0.firebaseapp.com',
  projectId: 'meowz-d84a0',
  storageBucket: 'meowz-d84a0.firebasestorage.app',
  messagingSenderId: '1098149029047',
  appId: '1:1098149029047:web:b1e3d2249a7e3715bd5932',
  measurementId: 'G-MLEEENFZ6G',
}

export const firebaseConfigDEV = {
  apiKey: 'AIzaSyDk_VyT56hYtg0sJvNQ-QIl_bg9lqOSZD4',
  authDomain: 'meowz-dev.firebaseapp.com',
  projectId: 'meowz-dev',
  storageBucket: 'meowz-dev.firebasestorage.app',
  messagingSenderId: '898731460431',
  appId: '1:898731460431:web:e26b7ba9d01ae456ebe73a',
  measurementId: 'G-28L6E07C2F',
}
