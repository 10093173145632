export function createPaypalProductId({
  priceId,
  trialPriceId,
  trialPeriodDays,
}: {
  priceId: string
  trialPeriodDays?: number | null
  trialPriceId?: string | null
}): string {
  return trialPriceId
    ? `${priceId};${trialPriceId};${trialPeriodDays}`
    : priceId
}
