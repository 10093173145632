import { IConfig, IRemoteConfigFile } from 'models/config.model'
import { IVariant } from 'models/variant.model'
import { AnyAction } from 'redux'
import {
  RESET_ERROR,
  SET_ANSWERS,
  SET_CONFIG,
  SET_ERROR,
  SET_FIREBASE_REMOTE_CONFIG,
  SET_IS_LOGGER_INITIALIZED,
  SET_LANGUAGE,
  SET_OPTIMIZE_VARIANT_ID,
  SET_VARIANT,
  START_FETCHING,
  STOP_FETCHING,
} from 'root-redux/common/common-actions'
import { TAnswers } from 'models/common.model'
import {
  RESET_ANSWERS,
  SET_ONBOARDING_ANSWERS,
} from 'root-redux/user/user-actions'
import { getWebFunnelsConfig } from 'helpers/getWebFunnelsConfig'
import { DEFAULT_WEB_FUNNELS_CONFIG_FILE } from 'root-constants'
import { SET_IS_CANCEL_OFFER_APPLIED } from 'root-redux/payment/payment-actions'

export interface ICommonState {
  isFetching: boolean
  actionList: string[]
  variant: IVariant | null
  config: IConfig | null
  language: string
  optimizeVariantId: string
  optimizeSegmentName: string
  isLoggerInitialized: boolean
  // TODO find definition of app config in firebase
  firebaseRemoteConfig: any | null
  error: any
  answers: TAnswers | null
  isCancelOfferApplied: boolean
  firebaseConfigFile: IRemoteConfigFile
}

const initState: ICommonState = {
  isFetching: false,
  actionList: [],
  variant: null,
  config: null,
  language: '',
  optimizeVariantId: 'no_test',
  optimizeSegmentName: 'no_test',
  isLoggerInitialized: false,
  firebaseRemoteConfig: null,
  error: null,
  answers: null,
  isCancelOfferApplied: false,
  firebaseConfigFile: DEFAULT_WEB_FUNNELS_CONFIG_FILE,
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_VARIANT: {
      return {
        ...state,
        variant: {
          ...payload,
        },
      }
    }
    case SET_CONFIG: {
      return {
        ...state,
        config: payload,
      }
    }
    case SET_OPTIMIZE_VARIANT_ID: {
      return {
        ...state,
        optimizeVariantId: payload,
        optimizeSegmentName: state.variant?.optimizeMap?.[payload] || 'no_name',
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case SET_ONBOARDING_ANSWERS: {
      return {
        ...state,
        answers: payload,
      }
    }
    case RESET_ANSWERS: {
      return {
        ...state,
        answers: null,
      }
    }
    case SET_FIREBASE_REMOTE_CONFIG: {
      return {
        ...state,
        firebaseRemoteConfig: payload,
        firebaseConfigFile: getWebFunnelsConfig(payload),
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_IS_CANCEL_OFFER_APPLIED: {
      return {
        ...state,
        isCancelOfferApplied: payload,
      }
    }
    case SET_IS_LOGGER_INITIALIZED: {
      return {
        ...state,
        isLoggerInitialized: payload,
      }
    }
    default:
      return state
  }
}
