import React, { useEffect, useRef } from 'react'
import guaranteeImage from 'assets/images/cat-guarantee-2.png'
import expertBubble from 'assets/images/sprite/expert-message.svg'
import newbieBubble from 'assets/images/sprite/message.svg'
import lottie from 'lottie-web/build/player/lottie_light'
import trainingProgress from 'assets/animation/trainingProgress.json'
import { SvgImage } from 'components/SvgImage'
import { CONTACT_FORM_LINKS, TERMS_OF_USE_LINKS } from 'root-constants'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { StyledGuarantee as S } from './Guarantee.styles'

export const GuaranteeVariant3: React.FC = () => {
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const env = getCurrentEnv()

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: trainingProgress,
      loop: true,
    })
  }, [])

  return (
    <S.WrapperVariant2>
      <S.CatImageVariant2 src={guaranteeImage} />
      <S.Title>
        100% money-back
        <br /> guarantee
      </S.Title>
      <S.Text>
        We are confident in the quality of our plan - millions of clients around
        the world have already get visible results. We are ready to return your
        money back if you don&apos;t see the results and can demonstrate that
        you followed your plan. For more information, please read our&nbsp;
        <S.InnerLink target="_blank" to={TERMS_OF_USE_LINKS[env]}>
          Terms of Use
        </S.InnerLink>
        &nbsp;or contact&nbsp;
        <S.InnerLink to={CONTACT_FORM_LINKS[env]} target="_blank">
          support.
        </S.InnerLink>
      </S.Text>
      <S.ChartWrapper>
        <S.ChartTitle>Track the training progress</S.ChartTitle>
        <S.Chart ref={animationContainerRef} style={{ width: '330px' }} />
        <S.NowText>now</S.NowText>
        <S.TextPlan>with our plan</S.TextPlan>
        <S.ExpertBubble svg={expertBubble} />
        <S.NewbieBubble>
          <SvgImage svg={newbieBubble} width={69} height={30} />
          <S.NewbieTitle2>Newbie</S.NewbieTitle2>
        </S.NewbieBubble>
      </S.ChartWrapper>
    </S.WrapperVariant2>
  )
}
