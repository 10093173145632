import { Color } from 'root-constants'
import styled, { keyframes } from 'styled-components'
import {
  baseColumnStyles,
  basePageStyles,
  fullViewMinHeight,
} from 'pages/styles'

const l = keyframes`
    to {
        clip-path: inset(0 -30% 0 0)
    }
`

export const StyledPrepareFlow = {
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    padding-top: 50px;
    background-color: ${Color.BEIGE};
    height: 100%;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    margin-top: 24px;
  `,
  SliderWrapper: styled.div`
    .commentsCarousel {
      max-width: 328px;
      overflow: hidden;
    }

    .swiper-wrapper {
      display: flex;
      height: 300px;
    }

    .swiper-slide {
      position: relative;
      width: 288px;
      //height: 470px;
      padding-top: 22px;
    }
  `,
  CommentsTitleWrapper: styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
  `,
  BrunchImg: styled.img`
    width: 18px;
    height: 32px;
  `,
  CommentsTitle: styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.PRIMARY_TEXT};
    text-align: center;
  `,
  PrepareFlowTitle: styled.div`
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: ${Color.PRIMARY_TEXT};
    margin-bottom: 12px;
  `,
  Dots: styled.div`
    display: inline-block;
    clip-path: inset(0 100% 0 0);
    animation: ${l} 2s steps(4) infinite;
  `,
}
