import React from 'react'
import { Option, OptionType } from 'components/option/Option'
import { StyledAnalyzingAnswersModal as S } from './AnalyzingAnswersModal.styles'

export const enum UserAnswer {
  VERY_INACTIVE = 'veryInactive',
  SOMEWHAT_ACTIVE = 'somewhatActive',
  VERY_ACTIVE = 'veryActive',
}

type TAnalyzingAnswersModal = {
  isModalVisible: boolean
  onChange?: (value: string) => void
  answers?: string[]
  setAnswers?: () => void
  children?: React.ReactNode
}

export const AnalyzingAnswersModal: React.FC<TAnalyzingAnswersModal> = ({
  isModalVisible,
  onChange,
  children,
  ...rest
}) => (
  <S.Wrapper isVisible={isModalVisible}>
    <S.Content>
      <S.Title>{children}</S.Title>
      <Option
        type={OptionType.RADIO}
        value={UserAnswer.VERY_INACTIVE}
        {...rest}
        onChange={() => onChange && onChange(UserAnswer.VERY_INACTIVE)}
      >
        <S.Option>😴️&nbsp; Very inactive</S.Option>
      </Option>
      <Option
        type={OptionType.RADIO}
        value={UserAnswer.SOMEWHAT_ACTIVE}
        {...rest}
        onChange={() => onChange && onChange(UserAnswer.SOMEWHAT_ACTIVE)}
      >
        <S.Option>🤏🏽&nbsp; Somewhat active</S.Option>
      </Option>
      <Option
        type={OptionType.RADIO}
        value={UserAnswer.VERY_ACTIVE}
        {...rest}
        onChange={() => onChange && onChange(UserAnswer.VERY_ACTIVE)}
      >
        <S.Option>⚡&nbsp; Very active</S.Option>
      </Option>
    </S.Content>
  </S.Wrapper>
)
