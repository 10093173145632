import styled, { css } from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import { SvgImage } from 'components/SvgImage'
import { Link } from 'react-router-dom'

const Wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -40px;
  z-index: 1;
  width: 100vw;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
`

export const StyledGuarantee = {
  Wrapper: styled.div`
    ${Wrapper};
    background-color: ${Color.WHITE};
    padding: 40px 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  WrapperVariant2: styled.div`
    ${Wrapper};
    background-color: ${Color.BEIGE};
    padding: 40px 16px 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  CatImage: styled.img`
    width: 144px;
    height: 164px;
    margin-bottom: 16px;
    margin-top: -74px;
  `,
  CatImageVariant2: styled.img`
    width: 114px;
    height: 156px;
    margin-bottom: 16px;
    margin-top: -74px;
  `,
  Title: styled.h2`
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    padding: 0px 12px;
    opacity: 0.8;

    a {
      color: inherit;
      text-decoration: underline;
    }

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      padding: 0 32px;
    }
  `,
  Text2: styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 24px;

    br {
      display: none;
    }
  `,
  ResultsBlock: styled.div`
    background: #854eb3;
    color: ${Color.WHITE};
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding: 40px 16px;

    strong {
      color: ${Color.YELLOW_ORANGE};
    }
  `,
  ResultsWrapper: styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  `,
  ImageWrapper: styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  `,
  ResultImage: styled.img`
    width: 123px;
    height: 182px;
    border-radius: 14px;
    border: 4px solid ${Color.WHITE};
  `,
  LabelBefore: styled.div`
    color: ${Color.WHITE};
    border-radius: 16px;
    background: linear-gradient(
        279deg,
        #eb3524 -1.71%,
        #fc754a 47.96%,
        #f8bc45 100.06%
      ),
      #c4c4c4;
    width: 114px;
    height: 30px;
    margin-top: -15px;
    text-align: center;
    padding-top: 6px;
    font-size: 12px;
    font-weight: 700;
  `,
  LabelAfter: styled.div`
    color: ${Color.WHITE};
    border-radius: 16px;
    background: linear-gradient(282deg, #35b43f 1.06%, #72d444 100.05%);
    width: 114px;
    height: 30px;
    margin-top: -15px;
    text-align: center;
    padding-top: 6px;
    font-size: 12px;
    font-weight: 700;
  `,
  Emoji: styled(SvgImage)`
    width: 32px;
    position: absolute;
    right: -12px;
    bottom: 18px;
  `,
  Chart: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 14px;
  `,
  ChartWrapper: styled.div`
    position: relative;
    margin-top: 32px;
  `,
  ChartTitle: styled.h2`
    font-size: 20px;
    font-style: normal;
    line-height: 23px;
    position: absolute;
    left: 36px;
    top: 16px;
  `,
  NowText: styled.h2`
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${Color.BROWNISH_GRAY};
    position: absolute;
    bottom: 22px;
    left: 43px;
  `,
  TextPlan: styled.h2`
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${Color.PURPLE};
    position: absolute;
    bottom: 22px;
    left: 207px;
  `,
  ExpertBubble: styled(SvgImage)`
    position: absolute;
    width: 107px;
    right: 18px;
    top: 62px;
  `,
  NewbieBubble: styled.div`
    position: absolute;
    left: 26px;
    bottom: 74px;
  `,
  NewbieTitle: styled.div`
    font-size: 12px;
    font-weight: 700;
    color: ${Color.WHITE};
    position: absolute;
    left: 8px;
    top: 4px;
  `,
  NewbieTitle2: styled.div`
    font-size: 12px;
    font-weight: 700;
    color: ${Color.WHITE};
    position: absolute;
    left: 12px;
    top: 4px;
  `,
  CommandsCount: styled.div`
    font-size: 8px;
    font-weight: 500;
    color: ${Color.WHITE};
    opacity: 0.8;
    position: absolute;
    left: 8px;
    top: 20px;
  `,
  InnerLink: styled(Link)`
    text-decoration: underline;
  `,
  InnerLink2: styled(Link)`
    text-decoration: underline;
    color: ${Color.BROWNISH_GRAY};
  `,
  PrivacyNote: styled.div`
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: ${Color.BROWNISH_GRAY};
  `,
}
