import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { eventLogger } from 'services/eventLogger-service'
import {
  selectOptimizeExperimentId,
  selectOptimizeSegmentName,
  selectOptimizeVariantId,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { selectUUID } from 'root-redux/user/user-selects'

export const useStartSession = (): void => {
  const optimizeVariantId = useSelector(selectOptimizeVariantId)
  const optimizeExperimentId = useSelector(selectOptimizeExperimentId)
  const optimizeSegmentName = useSelector(selectOptimizeSegmentName)
  const cohort = useSelector(selectVariantCohort)
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    eventLogger.logSessionStarted({
      optimizeVariantId,
      optimizeExperimentId,
      optimizeSegmentName,
      cohort,
    })
  }, [optimizeExperimentId, optimizeSegmentName, optimizeVariantId, cohort])

  useEffect(() => {
    if (uuid && window.fbq !== undefined) {
      window.fbq('track', 'ViewContent', {}, { eventID: uuid })
    }
  }, [uuid])
}
