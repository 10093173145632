import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web/build/player/lottie_light'
import meowzChart from 'assets/animation/meowzChart.json'
import phoneImg from 'assets/images/phone-cat.jpg'
import { StyledBlock as S } from './DidYouKnowBlock.styles'

export const DidYouKnowBlock: React.FC = () => {
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: meowzChart,
      loop: true,
    })
  }, [])

  return (
    <S.Wrapper>
      <S.Title>Did you know?</S.Title>
      <S.Description>
        <strong>74% of users</strong> started Meowz training program advanced in
        their goals within the first month*
      </S.Description>
      <S.Chart ref={animationContainerRef} style={{ width: '288px' }} />
      <S.Comment>
        * based on the information of users have shared their progress
      </S.Comment>
      <S.PhoneImg src={phoneImg} />
      <S.PersonalPlanText>
        <strong>The personal plan</strong>
        according to your goals
        <br />
        and needs
      </S.PersonalPlanText>
    </S.Wrapper>
  )
}
