import styled from 'styled-components'
import { SvgImage } from 'components/SvgImage'

export const StyledCloseButton = {
  CloseButton: styled.button`
    width: 34px;
    height: 34px;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
  `,
  Image: styled(SvgImage)`
    width: 10px;
    height: 10px;
  `,
}
