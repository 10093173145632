import mixedBreedImg from 'assets/images/breeds/mixed-breed-cat-2.jpg'
import exoticShutterstockImg from 'assets/images/breeds/exotic-shutterstock-2.jpg'
import ragdollImg from 'assets/images/breeds/ragdoll-breed-2.jpg'
import persianImg from 'assets/images/breeds/persian-breed-2.jpg'
import abyssinianImg from 'assets/images/breeds/abyssinian-breed-2.jpg'
import maineCoonImg from 'assets/images/breeds/maine-coon-breed-2.jpg'
import sphynxImg from 'assets/images/breeds/sphynx-breed-2.jpg'
import devonRexImg from 'assets/images/breeds/devon-rex-breed-2.jpg'
import scottishImg from 'assets/images/breeds/scottish-fold-breed-2.jpg'
import americanShorthairImg from 'assets/images/breeds/american-shorthair-breed-2.jpg'
import britishShorthairImg from 'assets/images/breeds/british-shorthair-breed-2.jpg'
import siameseImg from 'assets/images/breeds/siamese-breed.jpg'

import React, { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { StyledSubscriptions as S } from 'modules/subscriptions/pages/Subscriptions.styles'
import { getCatQuestionAnswers } from 'helpers/getCatQuestionAnswers'
import lottie from 'lottie-web/build/player/lottie_light'
import confetti from 'assets/animation/confetti.json'
import check from 'assets/images/sprite/green-check.svg'
import { getAnswer } from 'modules/subscriptions/helpers'
import { IDK } from 'root-constants'
import { selectAnswers } from 'root-redux/common/common-selects'
import { AnswerKey, BREED_OPTIONS } from 'modules/subscriptions/constants'
import { ISvg } from 'components/SvgImage'

const CAT_BREED_TO_IMAGE_MAP = {
  [BREED_OPTIONS.MIXED_BREED]: mixedBreedImg,
  [BREED_OPTIONS.EXOTIC]: exoticShutterstockImg,
  [BREED_OPTIONS.RAGDOLL]: ragdollImg,
  [BREED_OPTIONS.PERSIAN]: persianImg,
  [BREED_OPTIONS.ABYSSINIAN]: abyssinianImg,
  [BREED_OPTIONS.MAINE_COON]: maineCoonImg,
  [BREED_OPTIONS.SPHYNX]: sphynxImg,
  [BREED_OPTIONS.DEVON_REX]: devonRexImg,
  [BREED_OPTIONS.SCOTTISH]: scottishImg,
  [BREED_OPTIONS.AMERICAN_SHORTHAIR]: americanShorthairImg,
  [BREED_OPTIONS.BRITISH_SHORTHAIR]: britishShorthairImg,
  [BREED_OPTIONS.SIAMESE]: siameseImg,
}

const FIELDS = [
  AnswerKey.SEX,
  AnswerKey.CAT_BREED,
  AnswerKey.GOALS_QUESTION,
  AnswerKey.BEHAVIOUR_ISSUES,
  AnswerKey.AGE,
  AnswerKey.TIME,
]

export const CatCardVariant2: React.FC = () => {
  const answers = useSelector(selectAnswers)
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const catCardValues = useMemo(
    () => getCatQuestionAnswers(answers, FIELDS) || {},
    [answers],
  )
  const catBreed = useMemo(
    () => getAnswer(catCardValues[AnswerKey.CAT_BREED]),
    [catCardValues],
  )

  useEffect(() => {
    if (!animationContainerRef || !animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: confetti,
      loop: true,
    })
  }, [])

  return (
    <S.WrapperWhite>
      <S.AnimationWrapper ref={animationContainerRef} />
      <S.TitleVariant4>Your plan is ready!</S.TitleVariant4>
      <S.CatImageVariant2
        src={CAT_BREED_TO_IMAGE_MAP[catBreed] || mixedBreedImg}
        alt="cat"
      />
      <S.CardItemsWrapper>
        {![IDK, BREED_OPTIONS.OTHER].includes(catBreed) && (
          <S.CardItem>
            <S.CheckImage svg={check as ISvg} />
            <S.Answer>
              Cat’s breed:&nbsp;
              <strong>{catBreed}</strong>
            </S.Answer>
          </S.CardItem>
        )}
        <S.CardItem>
          <S.CheckImage svg={check as ISvg} />
          <S.Answer>
            Age group:&nbsp;
            <strong>{getAnswer(catCardValues[AnswerKey.AGE])}</strong>
          </S.Answer>
        </S.CardItem>
        <S.CardItem>
          <S.CheckImage svg={check as ISvg} />
          <S.Answer>
            Goal:&nbsp;
            <strong>
              {getAnswer(catCardValues[AnswerKey.GOALS_QUESTION])}
            </strong>
          </S.Answer>
        </S.CardItem>
        <S.CardItem>
          <S.CheckImage svg={check as ISvg} />
          <S.Answer>
            Behavioral issues:&nbsp;
            <strong>
              {getAnswer(catCardValues[AnswerKey.BEHAVIOUR_ISSUES])}
            </strong>
          </S.Answer>
        </S.CardItem>
        <S.CardItem style={{ border: 'none' }}>
          <S.CheckImage svg={check as ISvg} />
          <S.Answer>
            Daily training time:&nbsp;
            <strong>{getAnswer(catCardValues[AnswerKey.TIME])}</strong>
          </S.Answer>
        </S.CardItem>
      </S.CardItemsWrapper>
    </S.WrapperWhite>
  )
}
