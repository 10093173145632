import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TimeInterval } from 'root-constants'
import { ISubscription } from 'models/suscriptions.model'
import { PERIODS_MAP } from 'modules/subscriptions/constants'

interface IProps {
  selectedSubscription: ISubscription
}

export const FooterPriceText: React.FC<IProps> = ({ selectedSubscription }) => {
  const { t } = useTranslation()

  const periodQuantity = useMemo(
    () =>
      selectedSubscription?.trialPrices.durationDays === 7
        ? 1
        : selectedSubscription?.trialPrices.durationDays / 30,
    [selectedSubscription],
  )

  const periodName = useMemo(
    () =>
      selectedSubscription?.trialPrices.durationDays === 7
        ? TimeInterval.WEEK
        : TimeInterval.MONTH,
    [selectedSubscription],
  )

  return (
    <Trans
      i18nKey="payment.extended"
      values={{
        periodQuantity,
        periodName: t(`onboarding.planBlock.${periodName}`),
        periodQuantity2: t(`payment.${PERIODS_MAP[periodQuantity]}`),
        fullPrice: selectedSubscription?.mainPrices.fullPrice,
      }}
    />
  )
}
