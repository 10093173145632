import { CENTS_IN_DOLLAR, INITIAL_PRICE } from 'root-constants'
import { TOldPrices } from 'models/suscriptions.model'

export const getFormattedPrice = (price: number | undefined): number =>
  price ? price / CENTS_IN_DOLLAR : INITIAL_PRICE

export const getCurrentOldPrices = (oldPrices): TOldPrices => {
  const prices =
    oldPrices.before_custom_discount || oldPrices.before_50_percent_discount

  return {
    fullPrice: getFormattedPrice(prices.amount),
    weekly: getFormattedPrice(prices.in_the_box.weekly),
    daily: getFormattedPrice(prices.in_the_box.daily),
    yearly: getFormattedPrice(prices.in_the_box.yearly),
    monthly: getFormattedPrice(prices.in_the_box.monthly),
  }
}
