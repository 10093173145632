import React, { useEffect } from 'react'
import kittyCat from 'assets/images/kitty-kitty.jpg'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { StyledComponents as S } from './Modal.styles'

type TProps = {
  isShown: boolean
  onClose: () => void
}

export const BodyLanguageModal: React.FC<React.PropsWithChildren<TProps>> = ({
  isShown,
  onClose,
}) => {
  useEffect(() => {
    document.body.style.overflow = isShown ? 'hidden' : 'unset'
  }, [isShown])

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset'
    },
    [],
  )

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        <S.ShelterCatImage src={kittyCat} />
        <S.Title>Kitty kitty kitty 🐈</S.Title>
        <S.Subtitle>
          Understanding your furry feline&apos;s body
          <br /> language is like unlocking a secret code to their
          <br /> feelings and needs 😉
        </S.Subtitle>
        <GreenArrowButton onClick={onClose} />
      </S.Content>
    </S.Wrapper>
  )
}
