import React from 'react'
import discountCat from 'assets/images/discount-cat-cs.png'
import tale from 'assets/images/sprite/tooltip-tale.svg'
import { StyledComponents as S } from './styles'

export const EmailTooltip: React.FC = () => (
  <S.TooltipContainer>
    <S.Image src={discountCat} alt="discount cat" />
    <S.TooltipContent>
      <S.TooltipTitle>Great deal</S.TooltipTitle>
      <S.TooltipText>
        if you provide your email, you will get a <span>50% discount</span>
        &nbsp;for your first order
      </S.TooltipText>
    </S.TooltipContent>
    <S.TooltipTale svg={tale} />
  </S.TooltipContainer>
)
