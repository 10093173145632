import { setup } from 'xstate'
import { IUserStatus } from 'models/user.model'

export interface IUserFlowContext {
  context: {
    userStatus: IUserStatus
  }
}

export const createUserFlowStateMachine = (
  userStatus: IUserStatus,
  currentXStateConfig: any,
): any =>
  setup({
    types: {
      context: {} as {
        userStatus: IUserStatus
      },
    },
  }).createMachine({
    id: 'userMeowzFlow',
    initial: 'initial',
    states: currentXStateConfig,
    context: {
      userStatus,
    },
    predictableActionArguments: true,
  })
