import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { EMPTY_FIELD_ERROR, ENV_MAP, StripeFieldName } from 'root-constants'
import { THREEDS_REDIRECT_SEARCH_PARAM } from 'modules/payment/constants'
import { TPaymentErrorState } from 'modules/payment/types'
import { PageId } from '../../../constants/pages'

export const getRedirectUrl = (paymentPageId: PageId): string => {
  const currentEnv = getCurrentEnv()
  const url = ENV_MAP[currentEnv]

  return `https://${url}/${paymentPageId}?${THREEDS_REDIRECT_SEARCH_PARAM}`
}

export const getDefaultPaymentErrorsState = (): Record<
  StripeFieldName,
  {
    error: string
    isTouched: boolean
    isShown: boolean
    isComplete: boolean
  }
> => ({
  [StripeFieldName.NAME]: {
    error: '',
    isTouched: true,
    isShown: true,
    isComplete: true,
  },
  [StripeFieldName.NUMBER]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.EXPIRY]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.CVC]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.EMAIL]: {
    error: '',
    isTouched: true,
    isShown: true,
    isComplete: true,
  },
})

export const getPaymentErrorStateBySubmitWithUntouchedFields = (
  errors: TPaymentErrorState,
): TPaymentErrorState =>
  Object.entries(errors).reduce(
    (result, error) => ({
      ...result,
      [error[0]]: {
        ...error[1],
        ...(!error[1].isTouched && {
          isTouched: true,
          error: EMPTY_FIELD_ERROR,
        }),
      },
    }),
    {} as TPaymentErrorState,
  )
