import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledBanner = {
  HeaderWrapper: styled.div`
    width: 100%;
    background: linear-gradient(
        279deg,
        #eb3524 -1.71%,
        #fc754a 47.96%,
        #f89b45 100.06%
      ),
      #c4c4c4;
    height: 60px;
    box-shadow: 0 8px 12px rgba(242, 76, 50, 0.2);
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;

    &[data-is-hide='true'] {
      display: none;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      height: 60px;
      box-shadow: 0 8px 12px rgba(242, 76, 50, 0.2);
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
      left: calc(50% - 180px);
    }
  `,
  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: ${Color.WHITE};
    line-height: 60px;

    strong {
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      padding: 0 12px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      position: relative;
      left: calc(50% - 180px);
      width: 360px;
      height: 60px;
      display: flex;
    }
  `,
  Text: styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-weight: 800;
    font-size: 19px;
    height: 60px;
    line-height: 20px;
    color: ${Color.WHITE};
  `,
}
