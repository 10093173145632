import { TDynamicPageComponent } from 'models/common.model'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageId } from 'src/constants/pages'
import { Trans, useTranslation } from 'react-i18next'
import { IEmailPageProps } from 'modules/email/types'
import { getFilteredEmailDomains } from 'modules/email/helpers'
import { PrivacyNote } from 'modules/email/pages/components/PrivacyNote'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import {
  selectAnswers,
  selectIsFetching,
} from 'root-redux/common/common-selects'
import { InputEmail } from 'modules/email/pages/components/email-input/EmailInput'
import { AnswerKey } from 'modules/subscriptions/constants'
import { getCatQuestionAnswers } from 'helpers/getCatQuestionAnswers'
import { StyledEmailVariant as S } from './Email.styles'
import { EmailWrapper } from '../EmailWrapper'
import { SEX_OPTIONS } from '../../../constants/options-constants'

const EmailComponent: React.FC<IEmailPageProps> = ({
  email,
  onChange,
  onBlur,
  onSubmit,
  onClearButtonClick,
}) => {
  const isFetching = useSelector(selectIsFetching)
  const { t } = useTranslation()
  const [emailHasFocus, setEmailHasFocus] = useState(false)
  const [isAutoCompleteShown, setIsAutoCompleteShown] = useState(false)
  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isFetching,
    [email.isValid, email.value, isFetching],
  )
  const answers = useSelector(selectAnswers)
  const answerValues = useMemo(
    () => getCatQuestionAnswers(answers, [AnswerKey.SEX]) || {},
    [answers],
  )
  const placeholder = useMemo(
    () =>
      answerValues[AnswerKey.SEX] === SEX_OPTIONS.GIRL
        ? 'goodgirl@catmail.com'
        : 'goodboy@catmail.com',
    [answerValues],
  )

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(email.value),
    [email.value],
  )

  useEffect(() => {
    filteredEmailDomains.length && emailHasFocus
      ? setIsAutoCompleteShown(true)
      : setIsAutoCompleteShown(false)
  }, [email.isValid, email.value, emailHasFocus, filteredEmailDomains.length])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          The personal program
          <br />
          for your cutest cat is ready!
        </S.Title>
        <S.Text>Enter your email to get your plan</S.Text>
        <S.InputWrapper>
          <InputEmail
            labelName={t`onboarding.email.email`}
            placeholder={placeholder}
            value={email.value}
            isValid={email.isValid}
            validationText={email.validationText as string}
            name="email"
            type="email"
            autoComplete="off"
            onChange={onChange}
            onFocus={() => setEmailHasFocus(true)}
            onBlur={(e) => {
              onBlur(e)
            }}
            handleClearButtonClick={onClearButtonClick}
          />
          {isAutoCompleteShown && (
            <S.AutoCompleteWrapper>
              {filteredEmailDomains.map((value) => (
                <S.AutoCompleteButton
                  type="button"
                  key={value}
                  value={value}
                  onClick={(e) => {
                    onChange({
                      target: {
                        value: (e.currentTarget as HTMLButtonElement).value,
                      },
                    })
                    setEmailHasFocus(false)
                  }}
                >
                  <S.AutoCompleteButtonTextWrapper>
                    {value}
                  </S.AutoCompleteButtonTextWrapper>
                </S.AutoCompleteButton>
              ))}
            </S.AutoCompleteWrapper>
          )}
        </S.InputWrapper>
        <PrivacyNote />
        <S.StickyBlockWrapper>
          <GreenArrowButton
            isButtonDisabled={isButtonDisabled}
            onClick={onSubmit}
            text="Get My Plan"
          />
          <S.RightsReserved>
            <Trans
              values={{ year: '2024' }}
              i18nKey="footer.allRightsReserved"
            />
          </S.RightsReserved>
        </S.StickyBlockWrapper>
      </S.Content>
    </S.Wrapper>
  )
}

const ComponentVariant5: TDynamicPageComponent = () => (
  <EmailWrapper>{(props) => <EmailComponent {...props} />}</EmailWrapper>
)

ComponentVariant5.hasHeader = true
ComponentVariant5.id = PageId.EMAIL_2

export const EmailVariant2 = ComponentVariant5
