import { AnyAction, configureStore } from '@reduxjs/toolkit'
import { ThunkDispatch } from 'redux-thunk'
import { IAppState } from 'models/store.model'
import { rootReducer } from './rootReducer'

export type TAppDispatch = ThunkDispatch<IAppState, any, AnyAction>
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
