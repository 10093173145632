export const POPULAR_BREEDS = [
  'Mixed Breed',
  'Exotic Shorthair',
  'Ragdoll',
  'Persian',
  'Abyssinian',
  'Maine Coon',
  'Sphynx',
  'Devon Rex',
  'Scottish Fold',
  'American Shorthair',
  'British Shorthair',
]

export const BREED_NAMES = [
  'Abyssinian',
  'Aegean',
  'American Bobtail',
  'American Curl',
  'American Shorthair',
  'American Wirehair',
  'Arabian Mau',
  'Australian Mist',
  'Balinese',
  'Bengal',
  'Birman',
  'Bombay',
  'British Longhair',
  'British Shorthair',
  'Burmese',
  'Burmilla',
  'California Spangled',
  'Chausie',
  'Chartreux',
  'Chinchilla',
  'Cornish Rex',
  'Cymric',
  'Devon Rex',
  'Donskoy',
  'Egyptian Mau',
  'European Shorthair',
  'Exotic Shorthair',
  'Foldex',
  'German Rex',
  'Havana Brown',
  'Highlander',
  'Himalayan',
  'Japanese Bobtail',
  'Javanese',
  'Khao Manee',
  'Korat',
  'Kurilian Bobtail',
  'LaPerm',
  'Maine Coon',
  'Manx',
  'Mixed Breed',
  'Munchkin',
  'Nebelung',
  'Norwegian Forest Cat',
  'Ocicat',
  'Oriental Bicolor',
  'Oriental Longhair',
  'Oriental Shorthair',
  'Persian',
  'Peterbald',
  'Pixie - Bob',
  'Ragamuffin',
  'Ragdoll',
  'Russian Black',
  'Russian Blue',
  'Russian Tabby',
  'Russian White',
  'Black and Tabby',
  'Savannah',
  'Scottish Fold',
  'Selkirk Rex',
  'Serengeti',
  'Serrade petit',
  'Siamese',
  'Siberian',
  'Singapura',
  'Snowshoe',
  'Sokoke',
  'Somali',
  'Sphynx',
  'Thai',
  'Tonkinese',
  'Toyger',
  'Turkish Angora',
  'Turkish Van',
  'Ukrainian Levkoy',
  'York Chocolate',
]
