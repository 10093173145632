import styled from 'styled-components'
import { Color } from 'root-constants'
import {
  baseColumnStyles,
  basePageStyles,
  baseTextStyles,
  desktopMediaQuery,
} from 'pages/styles'

export const StyledAccount = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding: 60px 0 40px;

    @media ${desktopMediaQuery} {
      padding-top: 120px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  `,
  Subtitle: styled.p`
    ${baseTextStyles};
    margin-bottom: 32px;
  `,
  SubtitleVariant2: styled.p`
    ${baseTextStyles};
    margin-bottom: 16px;
  `,
  Buttons: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
  Agreement: styled.p`
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.6);

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  Divider: styled.div`
    width: inherit;
    display: flex;
    justify-content: center;
    margin: 16px 0 0;
    overflow: hidden;
  `,
  DividerText: styled.div`
    position: relative;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    padding: 0 12px;
    text-transform: lowercase;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.6);

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: 160px;
      top: 8px;
      background-color: #d9d9d9;
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  `,
}
