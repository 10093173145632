import { IUserStatus } from 'models/user.model'
import { AnyAction } from 'redux'
import {
  SET_EMAIL,
  SET_SECRET,
  SET_STATUS,
  SET_USER_UUID,
} from 'root-redux/user/user-actions'

export interface IUserState {
  uuid: string
  secret: string
  status: IUserStatus | null
  email: string
}

const initState: IUserState = {
  uuid: '',
  secret: '',
  status: null,
  email: '',
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_USER_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_SECRET: {
      return {
        ...state,
        secret: payload,
      }
    }
    case SET_STATUS: {
      return {
        ...state,
        status: payload,
      }
    }
    case SET_EMAIL: {
      return {
        ...state,
        email: payload,
      }
    }
    default:
      return state
  }
}
