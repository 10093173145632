import React from 'react'
import { PRIVACY_POLICY_LINKS } from 'root-constants'
import { useTranslation } from 'react-i18next'
import { OuterLink } from 'components/outer-link/OuterLink'
import { getCurrentEnv } from 'helpers/getCurrentEnv'

export const PrivacyPolicyLink: React.FC = () => {
  const { t } = useTranslation()
  const env = getCurrentEnv()

  return (
    <OuterLink
      href={PRIVACY_POLICY_LINKS[env]}
    >{t`commonComponents.privacyPolicy`}</OuterLink>
  )
}
