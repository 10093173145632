import React from 'react'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import cat from 'assets/images/plan-cat.png'
import { StyledInterestingFact as S } from './InterestingFact.styles'

export const InterestingFact: React.FC = () => (
  <S.Wrapper id={SUBSCRIPTION_SECTION_IDS.interestingFact}>
    <S.Label>💡Interesting fact</S.Label>
    <S.Title>
      You can increase cat&apos;s life
      <br />
      up to a few years!
    </S.Title>
    <S.Description>
      Cat training is a game-changer, extending their life and safety. Simple
      training prevents dangers, keeps them sharp, and strengthens your bond.
      So, don&apos;t be surprised if your well-trained cat becomes the
      neighborhood legend – they&apos;re living their best nine lives! 😺🐾
    </S.Description>
    <S.Image src={cat} alt="cat" />
  </S.Wrapper>
)
