import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyleForPayPalContainer = createGlobalStyle`
   body {
     overflow: hidden;
   }
`

export const StyledPayPalContainer = {
  ButtonsContainer: styled.div`
    width: 100%;
    margin-bottom: 10px;

    & iframe {
      z-index: 1 !important;
    }

    .paypal-buttons {
      min-height: 50px !important;
    }
  `,
}
