import styled from 'styled-components'
import { Button } from 'components/buttons/Button'
import { baseColumnStyles } from 'pages/styles'
import { Color, MediaBreakpoint } from 'root-constants'
import { Link } from 'react-router-dom'

export const StyledPaymentStatus = {
  Wrapper: styled.div`
    padding: 20px 16px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
  `,
  Container: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h2`
    margin: 24px 0 16px;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    span {
      color: ${Color.PURPLE};
    }

    @media (max-width: ${MediaBreakpoint.MIN_PHONE}px) {
      width: 328px;
    }

    @media (min-width: 361px) {
      width: 361px;
    }
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ButtonText: styled.p`
    width: 100%;
  `,
  StickyBlockWrapper: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${Color.WHITE};
    padding: 8px 20px 50px;
    max-width: 358px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
    }
  `,
  SupportBlock: styled.div`
    margin-top: 16px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    font-weight: 600;
    color: ${Color.BROWNISH_GRAY};
  `,
  InnerLink: styled(Link)`
    text-decoration: underline;
    color: ${Color.BROWNISH_GRAY};
  `,
}
