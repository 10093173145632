import React, { InputHTMLAttributes, useCallback, useState } from 'react'
import { StyledInput as S } from './Input.styles'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> & {
  labelName?: string
  hasVisibilityControl?: boolean
  isValid?: boolean
  validationText?: string
  hintText?: string
  type?: string
}

export const AccountInput: React.FC<TProps> = ({
  labelName,
  hasVisibilityControl = false,
  isValid = true,
  validationText = '',
  hintText = '',
  type = 'text',
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const getInputType = useCallback(() => {
    if (!hasVisibilityControl) return type

    return isVisible ? 'text' : 'password'
  }, [hasVisibilityControl, isVisible, type])

  const handleBlur = useCallback(
    (e) => {
      const target = e.target as any
      target.value = target.value.trim()

      if (props.onChange) {
        props.onChange(e)
      }

      if (props.onBlur) {
        props.onBlur(e)
      }
    },
    [props],
  )

  return (
    <S.Wrapper
      data-is-valid={isValid}
      data-validation-text={validationText}
      data-hint-text={hintText}
      data-is-warning-icon-shown={!hasVisibilityControl}
    >
      {labelName && <S.Label>{labelName}</S.Label>}
      <S.AccountInput
        required
        type={getInputType()}
        {...props}
        onBlur={handleBlur}
      />
      {hasVisibilityControl && (
        <S.VisibilityButton
          type="button"
          onClick={() => setIsVisible((prevValue) => !prevValue)}
          data-visible={isVisible}
        />
      )}
    </S.Wrapper>
  )
}
