import styled, { css } from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import { StyledButtonBase } from 'components/buttons/Button.styles'

const StyledAccountButtonBase = css`
  ${StyledButtonBase};
  position: relative;
  width: 100%;
  font-size: 16px;
  box-shadow: none;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  svg {
    position: absolute;
    top: calc(50% - 24px / 2);
    left: 28px;
  }

  @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
    font-size: 12px;
  }
`

export const StyledAccountButton = {
  Email: styled.button`
    ${StyledAccountButtonBase}
  `,
  Google: styled.button`
    ${StyledAccountButtonBase};
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    border: 1px solid #979797;
  `,
  Facebook: styled.button`
    ${StyledAccountButtonBase};
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledAccountButtonBase};
    background-color: #010002;
  `,
}
