import { Color } from 'root-constants'
import styled, { css } from 'styled-components'
import { IProps as IButtonProps } from './ButtonVariant2'

export const StyledButtonVariant2Base = css`
  background-color: ${Color.MEDIUM_GREEN};
  width: 100%;
  height: 58px;
  display: block;
  padding: 0 20px;
  cursor: pointer;
  border: 4px solid ${Color.BEIGE};
  border-radius: 40px;
  outline: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 50px;
  text-decoration: none;
  text-align: center;
  color: white;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  transition:
    background-color 0.2s ease-out,
    box-shadow 0.2s ease-out;

  &:disabled {
    background-color: #94ce8e;
    box-shadow: none;
  }
`

export const StyledButtonVariant2 = styled.button<IButtonProps>`
  ${StyledButtonVariant2Base};
`
