import React from 'react'
import laptop from 'assets/images/sprite/laptop-cat.svg'
import home from 'assets/images/sprite/home.svg'
import think from 'assets/images/sprite/emoji-think.svg'
import gain from 'assets/images/sprite/gain.svg'
import angryCatImg from 'assets/images/sprite/angry-cat-emoji.svg'
import emoji from 'assets/images/sprite/emoji-clever.svg'
import cat from 'assets/images/sprite/cat-emoji.svg'
import catInLove from 'assets/images/sprite/cat-in-love-emoji.svg'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { ISvg, SvgImage } from 'components/SvgImage'
import { StyledObtainingList as S } from './ObtainingList.styles'

export const ObtainingListVariant2: React.FC = () => (
  <S.Wrapper id={SUBSCRIPTION_SECTION_IDS.obtaining}>
    <S.Title>What&apos;s inside in your plan?</S.Title>
    <S.ListsWrapper>
      <S.ListItem>
        <SvgImage svg={laptop as ISvg} width={48} /> The ultimate and tailored
        cat training plan
      </S.ListItem>
      <S.ListItem>
        <SvgImage svg={catInLove as ISvg} width={48} /> How to be the best
        partner for your cat
      </S.ListItem>
      <S.ListItem>
        <SvgImage svg={angryCatImg as ISvg} width={48} /> Prevent problem
        behaviors
      </S.ListItem>
      <S.ListItem>
        <SvgImage svg={think as ISvg} width={48} /> Basic cues every cat must
        know
      </S.ListItem>
      <S.ListItem>
        <SvgImage svg={gain as ISvg} width={48} /> Cat target training
      </S.ListItem>
      <S.ListItem>
        <SvgImage svg={cat as ISvg} width={48} /> Guide for understanding your
        cat’s
        <br />
        body language
      </S.ListItem>
      <S.ListItem>
        <SvgImage svg={home as ISvg} width={48} /> Handy tips for home
        adaptation
      </S.ListItem>

      <S.ListItem>
        <SvgImage svg={emoji as ISvg} width={48} /> Lots of useful cat facts
      </S.ListItem>
    </S.ListsWrapper>
  </S.Wrapper>
)
