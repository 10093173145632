import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'src/constants/pages'

import branchLeft from 'assets/images/black-branch-left.svg'
import branchRight from 'assets/images/black-branch-right.svg'
import { useProgressAnimationData } from 'hooks/useProgressAnimationData'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { ProgressBar } from 'pages/prepare-flow/components/ProgressBar'
import { StyledPrepareFlow as S } from './PrepareFlow.styles'
import { AnalyzingAnswersModal } from './components/AnalyzingAnswersModal'
import { Comment } from './components/Comment'
import { COMMENTS } from './components/constants'
import { useTranslation } from 'react-i18next'

export const QUESTION = 'What is your cat`s current activity level?'
export const STOP_COUNTER_AT = 99

const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [answer, setAnswer] = useState('')
  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: QUESTION,
        answers: answer,
        pageNumber: currentPageNumber,
        pageName: currentPageId,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep, answer])

  const handleContinueAnimation = useCallback((playback: () => void) => {
    const timerId = setTimeout(() => {
      playback && playback()
      clearTimeout(timerId)
    }, 500)
  }, [])

  const handleChange = useCallback(
    (animationPlayback) => {
      handleContinueAnimation(animationPlayback)
    },
    [handleContinueAnimation],
  )

  const {
    firstCounter,
    isFirstCounterFinished,
    secondCounter,
    isSecondCounterFinished,
    secondProgressAnimationPlayback,
    thirdCounter,
    isThirdCounterFinished,
    fourthCounter,
    isFourthCounterFinished,
  } = useProgressAnimationData({
    onNextStep: handleContinue,
    stopCounterAt: STOP_COUNTER_AT,
    durations: [6000, 6000, 4000, 4000],
  })

  const handleModalChange = useCallback(
    (value) => {
      if (answer) return

      setAnswer(value)
      handleChange(secondProgressAnimationPlayback)
    },
    [answer, handleChange, secondProgressAnimationPlayback],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.PrepareFlowTitle>
          {t`onboarding.prepareFlow.title`}
          <S.Dots>...</S.Dots>
        </S.PrepareFlowTitle>
        <ProgressBar
          value={firstCounter}
          isInProgress={!!firstCounter}
          isFinished={isFirstCounterFinished}
        >
          {t`onboarding.prepareFlow.item1`}
        </ProgressBar>
        <ProgressBar
          value={secondCounter}
          isInProgress={!!secondCounter}
          isFinished={isSecondCounterFinished}
        >
          {t`onboarding.prepareFlow.item2`}
        </ProgressBar>
        <ProgressBar
          value={thirdCounter}
          isInProgress={!!thirdCounter}
          isFinished={isThirdCounterFinished}
        >
          {t`onboarding.prepareFlow.item3`}
        </ProgressBar>
        <ProgressBar
          value={fourthCounter}
          isInProgress={!!fourthCounter}
          isFinished={isFourthCounterFinished}
        >
          {t`onboarding.prepareFlow.item4`}
        </ProgressBar>
        <S.CommentsTitleWrapper>
          <S.BrunchImg src={branchLeft} />
          <S.CommentsTitle>{t`onboarding.prepareFlow.item5`}</S.CommentsTitle>
          <S.BrunchImg src={branchRight} />
        </S.CommentsTitleWrapper>
        <S.SliderWrapper>
          <Carousel
            className="commentsCarousel"
            slidesPerView={1}
            spaceBetween={8}
            centeredSlides
            pagination={false}
            loop
            modules={[Autoplay, Navigation]}
            speed={1000}
            autoplay={{ disableOnInteraction: false }}
          >
            {COMMENTS.map(({ title, avatar, text, data, autoplayDelay }) => (
              <SwiperSlide key={title} data-swiper-autoplay={autoplayDelay}>
                <Comment image={avatar} title={title} data={data}>
                  {t(text)}
                </Comment>
              </SwiperSlide>
            ))}
          </Carousel>
        </S.SliderWrapper>

        <AnalyzingAnswersModal
          isModalVisible={secondCounter === STOP_COUNTER_AT}
          onChange={(value) => handleModalChange(value)}
        >
          {t`onboarding.prepareFlow.question`}
        </AnalyzingAnswersModal>
      </S.Content>
    </S.Wrapper>
  )
}

Component.hasHeader = true
Component.id = PageId.PREPARE_FLOW

export const PrepareFlow = Component
