export const CONTACT_FORM_LINK_PROD = 'https://account.meowz.app/contact-form'
export const CONTACT_FORM_LINK_DEV =
  'https://account-meowz.gidev.xyz/contact-form'

export const getContactFormLink = () => {
  if (typeof window === 'undefined') return CONTACT_FORM_LINK_PROD
  return window.location.href.includes('meowz.app')
    ? CONTACT_FORM_LINK_PROD
    : CONTACT_FORM_LINK_DEV
}
