import { FlowContext } from 'components/FlowContext'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { IEmailPageProps } from 'modules/email/types'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { eventLogger } from 'services/eventLogger-service'
import { Modal } from 'components/modals/Modal'
import { selectUUID } from 'root-redux/user/user-selects'
import {
  selectError,
  selectLanguage,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { resetErrorAction } from 'root-redux/common/common-actions'
import { sendUserEmailAction } from 'root-redux/user/user-actions'

interface IProps {
  children?: React.ReactNode | ((props: IEmailPageProps) => React.ReactNode)
}

export const EmailWrapper: React.FC<IProps> = ({ children }) => {
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const cohort = useSelector(selectVariantCohort)
  const { onNextStep } = useContext(FlowContext)
  const { search } = useLocation()
  const uuid = useSelector(selectUUID)
  const [email, setEmail, validateEmail] = useEmailInputField()
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)
  const language = useSelector(selectLanguage)

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleSubmit = useCallback(() => {
    if (!email.isValid) return

    dispatch(
      sendUserEmailAction({
        email: email.value.toLowerCase(),
        uuid,
        successCallback: onNextStep,
        unsuccessCallback: () => setIsErrorModalShown(true),
      }),
    )
  }, [
    cohort,
    dispatch,
    email.isValid,
    email.value,
    language,
    onNextStep,
    search,
    uuid,
  ])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setEmail((prevState) => ({
        ...prevState,
        value,
        isValid: true,
        validationText: '',
      }))
    },
    [setEmail],
  )

  const handleClearButton = useCallback(() => {
    setEmail({
      value: '',
      isValid: true,
      validationText: '',
    })
  }, [setEmail])

  const childrenWithProps = useMemo(
    () =>
      typeof children === 'function'
        ? children({
            onSubmit: handleSubmit,
            onChange: handleChange,
            onBlur: (e) => validateEmail(e.target.value),
            email,
            onClearButtonClick: handleClearButton,
          })
        : children,
    [children, handleSubmit, handleChange, email, validateEmail],
  )

  return (
    <>
      {childrenWithProps}
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </>
  )
}
