import { ApiService } from 'services/api/api-service'
import { EventsApi } from './EventsApi'
import { UserApi } from './UserApi'

/**
 * API_PREFIX - declare in webpack.config.js as a global variable
 */
declare const API_PREFIX: string

export const baseApiService = new ApiService(API_PREFIX)
export const hooksApiService = new ApiService('/hooks')
export const eventsApi = new EventsApi(hooksApiService)
export const userApi = new UserApi(baseApiService)
