/* eslint-disable max-lines */
import React, { useMemo } from 'react'
import { getContactFormLink } from 'helpers/getContactFormLink'
import { StyledPolicyPages as S } from './styles'

export const TermsOfUse: React.FC = () => {
  const contactFormLink = useMemo(() => getContactFormLink(), [])

  return (
    <S.Wrapper>
      <S.Column>
        <S.H2>Terms of Use</S.H2>
        <S.EffectiveDate>Effective as of August 01, 2024</S.EffectiveDate>
        <S.Subtitle>ABOUT US</S.Subtitle>
        <S.Text>
          Our Platforms (“<strong>Platforms</strong>” means website(s),
          including but not limited to:&nbsp;
        </S.Text>
        <p>
          <S.InnerLink to="https://meowz.app" aria-label="Link to Homepage">
            https://meowz.app
          </S.InnerLink>
          , (the “<strong>Site</strong>”), mobile applications (means
          applications and games (each an “<strong>App</strong>”, collectively “
          <strong>Apps</strong>”)) and any related documentation, services; any
          images, logos, music, photographs and video content, software,
          designs, graphics, photos, images, illustrations, animations, videos,
          scripts, texts, music, sounds, voiceover, interactive features,
          wellness plans, and all other materials and content accessible within
          the Apps or Site that are incorporated into and form part of our Apps
          and etc. (“<strong>App Content</strong>”) are owned, managed and
          operated by the Company).
        </p>
        <p>Company shall mean:</p>
        <p>
          <strong>NOVE8 LTD</strong>, a company duly incorporated in the
          Republic of Cyprus with registration number ΗΕ 456334 and registered
          address at Prodromos, 75, ONEWORLD PARKVIEW HOUSE, Floor 4, 2063,
          Nicosia, Cyprus
        </p>
        <S.Text>We can be contacted by writing to:</S.Text>
        <p>
          <strong>NOVE8 LTD</strong>, Prodromos, 75, ONEWORLD PARKVIEW HOUSE,
          Floor 4, 2063, Nicosia, Cyprus, via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          .
        </p>
        <p>
          These terms of use (“<strong>Terms</strong>” or “
          <strong>Agreement</strong>”) constitute a legally binding agreement
          between you and us regarding your use of our Platforms. In addition to
          these Terms, the following terms apply and form part of your agreement
          with us (“<strong>Privacy Policy</strong>”). Access to and use of our
          Platforms is expressly conditioned upon acceptance of these Terms and
          Privacy Policy. By creating an Account or accessing or using the
          Platforms, you acknowledge that you accept and agree to be bound by
          the provisions of these Terms and Privacy Policy. IF YOU DO NOT AGREE
          TO THESE TERMS AND PRIVACY POLICY, YOU MAY NOT ACCESS OR USE THE
          PLATFORMS.
        </p>
        <p>
          Our Platforms are not intended for children (under the age of 13 or
          such higher age as required by applicable law). We do not knowingly
          collect or solicit any personal data or target interest-based
          advertising to children, and we do not knowingly allow children to
          register for or use the Platforms. Children should not use our
          Platforms or send us any personal data about themselves at any time.
          If we learn that we have inadvertently gathered personal data from
          children, we will take reasonable measures to promptly erase such
          information from our records. If you believe that we might have
          information from or about a child, please contact us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          .
        </p>
        <p>
          If you use our Platforms onto any device not owned by you, you must
          have the owner’s permission to do so. You will be responsible for
          complying with these Terms, whether or not you own the device.
        </p>
        <p>
          Please note that any terms you have with your respective mobile
          network provider (“<strong>Mobile Provider</strong>”) continue to
          apply, and you may be charged by the Mobile Provider for access to
          network connection services for the duration of the connection while
          accessing our Platforms and any third-party charges as may arise from
          time to time. You will be solely responsible for any costs and other
          charges or expenses charged by your Mobile Provider, internet service
          provider, network operator and/or any other third-party provider in
          relation to your internet service, your mobile service and any use of
          our Platforms. In the event that you are not the bill payer for the
          device being used to access our Platforms, you will be assumed to have
          obtained permission from the relevant bill payer for using our
          Platforms.
        </p>
        <p>
          Any words following the terms ‘including’, ‘include’, ‘in particular’,
          ‘for example’ or any similar expression shall be construed as
          illustrative and shall not limit the sense of the words, description,
          definition, phrase or term preceding those terms.
        </p>
        <p>
          While all of these Terms are important, you should pay particular
          attention to:
        </p>

        <S.LatinList3>
          <li>
            the conditions of use of our Platforms as set out in “YOUR USE OF
            THE PLATFORMS” paragraph;
          </li>
          <li>
            our liability to each other as set out in “LIMITATION OF LIABILITY”
            paragraph, and
          </li>
          <li>
            when we may bring these Terms to an end as set out in the
            “TERMINATION” paragraph.
          </li>
        </S.LatinList3>

        <S.H3>OUR PLATFORMS</S.H3>
        <p>Our Platforms include our Site, our App and App Content.</p>
        <p>
          You accept and agree that the name of the Apps in different stores
          (e.g., App Store and Google Play) stores may vary (for example,
          depending on the locale). For the avoidance of doubt, the
          functionality of the App will remain the same, only the name itself
          may change.
        </p>
        <S.H3>DISCLAIMERS</S.H3>
        <S.Text>Medical disclaimer</S.Text>
        <p>
          WHILE WE DO OUR BEST TO PROVIDE YOU WITH USEFUL INFORMATION, PLEASE
          REMEMBER THAT OUR PLATFORMS ARE SOFTWARE AND NOT A HUMAN DOCTOR OR
          PHYSICAL MEDICAL DEVICE. OUR PLATFORMS ARE PROVIDED FOR INFORMATIONAL
          PURPOSES ONLY. THEY DO NOT INVOLVE THE PROVISION OF MEDICAL CARE,
          DIAGNOSIS, TREATMENT, EVALUATION OR THERAPY RELATED TO EXERCISE,
          NUTRITION, WEIGHT LOSS, WELLNESS, MENTAL HEALTH AND SHOULD NOT BE USED
          TO DIAGNOSE, TREAT OR MANAGE ANY INJURY, ILLNESS, DISEASE OR
          CONDITION. OUR PLATFORMS ARE NOT INTENDED FOR USE BY MEDICAL
          PERSONNEL.
        </p>
        <p>
          THE INFORMATION PROVIDED ON THE PLATFORMS IS NOT INTENDED TO AND
          SHOULD NOT BE USED AS A SUBSTITUTE FOR (A) PROFESSIONAL MEDICAL ADVICE
          FROM YOUR DOCTOR OR OTHER QUALIFIED HEALTHCARE PROVIDER; (B)
          FACE-TO-FACE CONSULTATION WITH YOUR DOCTOR OR OTHER QUALIFIED
          HEALTHCARE PROVIDER; AND/OR (C) INFORMATION PROVIDED ON PRODUCT
          PACKAGING OR LABELS. IF YOU HAVE ANY HEALTH CONCERNS, IT IS IMPORTANT
          TO CONSULT YOUR PHYSICIAN OR OTHER QUALIFIED HEALTHCARE PROVIDER
          IMMEDIATELY. IN THE EVENT OF AN EMERGENCY, PLEASE CONTACT THE
          EMERGENCY SERVICES IN YOUR COUNTRY OF RESIDENCE IMMEDIATELY.
        </p>
        <p>
          THE EXCHANGE OF CONTENT, IN WHOLE OR IN PART, BY EMAIL OR OTHERWISE,
          DOES NOT CREATE A DOCTOR-PATIENT, THERAPIST-PATIENT OR OTHER
          HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN YOU AND US.
        </p>
        <p>
          YOU AGREE, CONFIRM AND ACKNOWLEDGE THAT THE INFORMATION PROVIDED BY
          OUR PLATFORMS WILL ALWAYS BE BASED ON THE INFORMATION THAT YOU HAVE
          PROVIDED TO US THROUGH THE PLATFORMS. IT IS YOUR RESPONSIBILITY TO
          ENSURE THAT THIS INFORMATION IS ACCURATE AND COMPLETE AND YOU ACCEPT
          THAT ANY FAILURE TO DO SO (WHETHER INTENTIONAL OR NOT) MAY AFFECT THE
          INFORMATION WE ARE ABLE TO PROVIDE TO YOU AND MAY HAVE CONSEQUENCES
          FOR WHICH WE ARE NOT RESPONSIBLE.
        </p>
        <p>
          YOU SHOULD NEVER RELY ON OR MAKE DECISIONS ABOUT YOUR HEALTH OR
          WELL-BEING BASED SOLELY ON INFORMATION PROVIDED ON OUR PLATFORMS. IN
          ADDITION, YOU SHOULD ALWAYS SEEK ADVICE BY MAKING A FACE-TO-FACE
          APPOINTMENT WITH A LICENSED AND QUALIFIED PROFESSIONAL. IT IS
          IMPORTANT THAT YOU NEVER DISREGARD MEDICAL ADVICE OR DELAY SEEKING
          MEDICAL ATTENTION BASED ON ANY CONTENT PROVIDED ON THE PLATFORMS. YOU
          AGREE, CONFIRM AND ACKNOWLEDGE THAT ALTHOUGH OUR PLATFORMS MAY PROVIDE
          USEFUL INFORMATION, WE CANNOT ASSESS WHETHER THE USE OF THE PLATFORMS
          IS RIGHT AND APPROPRIATE FOR YOUR NEEDS. WE MAKE NO PROMISES AS TO THE
          ACHIEVEMENT OF ANY PARTICULAR LEVEL OF SUCCESS. WE ARE NOT LIABLE FOR
          ANY POSITIVE OR NEGATIVE RESULTS FROM THE USE OF OUR PLATFORMS. WE ARE
          NOT RESPONSIBLE FOR ANY INADVERTENT ERRORS OR OMISSIONS IN THE APP
          CONTENT PROVIDED, NOR ARE WE RESPONSIBLE FOR ANY ETHICAL OR MORAL
          VIOLATIONS RELATED TO THE FITNESS INFORMATION PRESENTED HERE,
          ACCORDING TO THE STANDARD OF YOUR COMMUNITY.
        </p>
        <S.Text>App disclaimer</S.Text>
        <p>
          We provide access to our Platforms, where you can receive guidance on
          pet training, behavior, socialization, and health from experienced pet
          handlers. Please note that our Platforms are intended for educational
          purposes only. Consultations with our pet handlers should not be used
          for nutritional, fitness, or other health-related diagnoses,
          prescriptions, or treatments. You agree that we and our pet handlers
          are in no way responsible for the behavior of the pet during or
          following all consultations and do not guarantee specific results. Pet
          handler agrees to consult you and your pet on a lesson-by-lesson
          basis, the goal being to teach you how to train and work with pet. Pet
          handler will make every reasonable effort to help you to achieve
          training and behavior modification goals but makes no guarantee of
          pet’s performance or behavior as a result of providing professional
          animal behavior consultation. You acknowledge that you are solely
          responsible for any loss or damage caused by reliance on information
          provided by our pet handlers during the use of the Platforms.
        </p>
        <p>
          Please note that every pet is unique, and we cannot guarantee the
          elimination or modification of behaviors. By using our Platforms and
          purchasing subscription you understand that every pet reacts in a
          different manner to training and that pet are by nature unpredictable.
        </p>
        <p>
          All information provided to you through our Platforms is intended for
          general guidance only and is not a substitute for professional medical
          advice, diagnosis, or treatment by a veterinarian who has examined
          your pet or possesses firsthand knowledge of your pet’s condition. You
          are solely responsible for any loss or damage resulting from your
          reliance on information provided through our Platforms. Always seek
          the advice of your veterinarian or other qualified health provider
          with any questions you may have regarding a medical condition. Never
          disregard professional medical advice or delay in seeking it because
          of something you have read or heard through the Platforms. If you
          believe your pet may have a medical emergency, call your veterinarian
          immediately. If you have any concerns or questions about your pet’s
          anxiety, you should always consult with a veterinarian.
        </p>
        <p>
          NEITHER WE NOR THE RELEVANT PET HANDLER ARE RESPONSIBLE FOR, AND BOTH
          HEREBY DISCLAIM, ANY AND ALL LIABILITY RELATED TO ANY AND ALL
          INFORMATION PROVIDED IN RESPECT OF THE USE OF OUR PLATFORMS.
          ACCORDINGLY, YOU USE THE PLATFORMS AT YOUR OWN RISK.
        </p>
        <S.H3>Registration</S.H3>
        <p>
          In order to make full use of our Platforms, you may be required to
          register for an account (“Account”). You acknowledge and agree that
          the information provided by you is true, accurate and correct.
        </p>
        <p>
          You agree to promptly notify us in writing in the event of any changes
          to any of your information. We cannot, however, guarantee the absolute
          security of your Account and we cannot promise that our security
          measures will prevent third-party “hackers” from illegally accessing
          the Platforms.
        </p>
        <p>
          Your login details are for your own personal use only and you must
          keep your login details confidential and secure. Sharing your login
          details with any other person is strictly prohibited.
        </p>
        <p>
          You must notify us immediately if you suspect or become aware of any
          unauthorized use of your login details or any breach of our security
          by contacting us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          &nbsp;and giving details of the unauthorized use or breach.
        </p>
        <p>
          Without prejudice to our rights and remedies, we reserve the right to
          promptly disable your Account details and suspend your access to any
          of our Platforms, if in our reasonable opinion, we believe that you
          have breached any of the provisions of these Terms.
        </p>
        <S.H3>SUBSCRIPTIONS</S.H3>
        <S.Text>Free or paid trial</S.Text>
        <p>
          We may offer a free or paid trial subscription for the use of
          Platforms. Unless you cancel at least 24 hours before the end of the
          trial, you will be automatically charged a price indicated on the
          payment screen or/and Apple’s/Google’s or in our web page payment
          pop-up screen for a chosen subscription period. Please note that if a
          trial is offered, this will be explicitly stated on the price screen
          before the checkout. If this is not the case, you will purchase our
          subscription without a trial.
        </p>
        <S.Text>Subscription</S.Text>
        <p>
          The subscription renews automatically for the same period at the end
          of each subscription period (each week, month, 6 months, year, or
          otherwise, depending on the option selected by you at the time of
          purchase) at the cost of the chosen plan. Your Account will be charged
          for the renewed subscription within 24 hours prior to the end of the
          current subscription. If you cancel the automatic renewal within the
          last 24 hours of the subscription, such cancellation will take effect
          at the end of the new subscription. We hereby notice that the cost of
          any subscription may vary.
        </p>
        <p>
          You may choose to add an additional features plan to your
          subscription. This additional feature plan will be merged with the
          main plan comprising the subscription.
        </p>
        <p>
          <S.Text>Payment method</S.Text>
        </p>
        <S.Text>
          Payment methods for App Store or Google Play applicable in your region
          or credit card, PayPal, Apple Pay, Google Pay for our Site.
        </S.Text>
        <p>
          Payment will be charged to the payment method you submitted at the
          time of purchase at confirmation of purchase (after you confirm by
          single-touch identification, facial recognition, or entering your
          payment method details on the web, or otherwise accepting subscription
          terms provided on the payment screen or on the pop-up screen provided
          by Apple/Google or on our web page) or after the end of the trial
          period. You authorize us to charge the applicable subscription fees to
          the payment method that you use.
        </p>
        <S.Text>Receiving and processing payments</S.Text>
        <p>
          The Company, in its exclusive discretion and prerogative, shall
          ascertain and designate the specific Account to be employed for the
          processing of any and all payments attributable to you, thereby
          retaining full authority and autonomy in the selection thereof.
        </p>
        <S.Text>Refunds</S.Text>
        <p>
          You purchased a subscription or enabled trial on the App Store: if you
          are eligible for a refund, you’ll have to request it directly from
          Apple. To request a refund, follow these instructions from the&nbsp;
          <S.GreyLink
            href="https://support.apple.com/en-ca/HT202039"
            aria-label="Apple support page link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple support page
          </S.GreyLink>
          .
        </p>
        <p>
          You purchased a subscription or enabled trial on Google Play: if you
          are eligible for a refund, you’ll have to request it directly from
          Google. To request a refund, follow these instructions from&nbsp;
          <S.GreyLink
            href="https://support.google.com/googleplay/answer/2479637?hl=en"
            aria-label="Google's support page link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google’s support page
          </S.GreyLink>
          .
        </p>
        <p>
          You purchased a subscription or enabled trial on our Site: generally,
          the payments made to us are non-refundable.
        </p>
        <p>
          However, there are a few exceptions that can lead to a positive
          response to a refund request - see sections ”Refund for subscriptions
          purchased on our Site” and “Money-back policy for subscriptions
          purchased on our Site”.
        </p>
        <S.Text>Refund for subscriptions purchased on our Site.</S.Text>
        <p>
          Below you will find the cases when a refund has a higher likelihood to
          be approved.
        </p>
        <S.PlainList2>
          <li>
            If you possess certain refund rights under applicable laws. In this
            case, you should justify your refund request and submit all the
            relevant documentation that supports your right to receive the
            refund.
          </li>
          <li>
            In case of a confirmed bug that doesn’t allow you to use the
            application properly. To determine whether an issue you are
            experiencing is a bug indeed, you’ll need to provide all the
            necessary technical information to our Support Team and receive a
            confirmation from them.
          </li>
          <li>
            If you apply for a refund under our “Money-back policy for
            subscriptions purchased on our Site” and meet all the conditions
            set. Please, see the money-back policy rules below.
          </li>
        </S.PlainList2>
        <S.Text>
          Money-back policy for subscriptions purchased on our Site.
        </S.Text>
        <p>
          If you purchased the Subscription directly on our Site and the
          money-back option was presented to you during the checkout, you are
          eligible to receive a refund if you did not get visible results with
          our App, provided that all of the following conditions are met:
        </p>
        <S.PlainList2>
          <li>
            you contact us within 30 days after your initial purchase and before
            the end of your subscription period; and
          </li>
          <li>
            you have followed and actively used our App program (i) at least 14
            consecutive days within the first subscription period after the
            purchase (for monthly and more lengthy subscription periods).
          </li>
        </S.PlainList2>
        <p>
          Please note that after your subscription period expires, we will not
          be able to refund you as the usage of the Platforms will be deemed
          executed in full, unless otherwise provided for by applicable law.
        </p>
        <S.Text>Dispute</S.Text>
        <p>
          If, at any time, we record a decline, chargeback or other rejection of
          a charge of any payable fees on your Account&nbsp; (“
          <strong>Dispute</strong>”), this will be considered as a breach of
          your payment obligations hereunder, and your use of our Platforms may
          be automatically disabled or terminated.
        </p>
        <p>
          In the event a Dispute is performed, your Account may be blocked
          without the option to re- purchase or re-use it, and any data
          contained in such Account may be subject to cancellation.
        </p>
        <p>
          If you have any questions or concerns regarding a payment made to us,
          we encourage you to first contact our customer support team before
          submitting a Dispute or reversal of payment in order to prevent the
          purchased subscriptions from being cancelled and your Account from
          being suspended, and to avoid submitting an unwarranted or incorrect
          Dispute, which may result in you being liable for the applicable fees,
          in addition to repaying all fees applicable to the purchased
          subscriptions (and Dispute).
        </p>
        <S.Text>Additional Subscription Terms</S.Text>
        <p>
          If you choose the credit card payment method, we reserve the right to
          charge a validation fee in the amount of $0.60 (the price may slightly
          vary due to region and currency exchange rates) to make sure that your
          payment method is valid. Please note that an immediate refund will be
          issued for this charge to your payment method.
        </p>
        <p>
          In case when processing a payment, you don’t have enough funds to
          subscribe or bill for reporting month of subscription, we may apply a
          discount automatically as a one-time action. The discount does not
          apply to subsequent payments for auto-renewing subscriptions. The
          discount amount is determined at our discretion.
        </p>
        <S.Text>Changes</S.Text>
        <p>
          To the maximum extent permitted by applicable laws, we may change
          subscription fees at any time. We will give you advance notice of any
          such pricing changes by posting the new prices on or through the app
          or by emailing you notification, or in other prominent ways. If you do
          not wish to pay the new fees, you can cancel the applicable
          subscription prior to the change going into effect.
        </p>
        <p>
          If you have any questions in relation to the subscription terms,
          please contact us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          .
        </p>
        <S.H3>FEES</S.H3>
        <S.H3>WEB PURCHASE</S.H3>
        <p>
          After completing the questionnaire on the Site, you can select the
          subscription that you would like to use. Subscriptions are available
          for various periods, and further details of each type of subscription
          and its cost are detailed on the Site. In some cases, set by us, based
          on our promotional plans, we may offer discounted subscription prices.
        </p>
        <S.H3>IN-APP PURCHASE</S.H3>
        <p>
          Once you have installed our Apps from Google Play or App Store, you
          can select the subscription that you would like in order to use each
          App. Subscriptions are available for various periods and further
          details of each type of subscription and its cost are detailed on the
          App.
        </p>
        <S.PointList>
          <li>App Content may be made available via in-app purchasing.</li>
          <li>
            All transactions between you and us will be processed through either
            Google Play or the App Store, depending on the store from which you
            have downloaded the App.
          </li>
          <li>
            In some cases, set by us, based on our promotional plans, we may
            offer discounted subscription prices.
          </li>
        </S.PointList>
        <p>
          You understand and agree that your payments may be processed by
          third-party payment processors, which may impose additional fees,
          commissions, rewards, etc. for payments. We are not responsible for
          and do not cover such additional costs.
        </p>
        <S.H3>YOUR USE OF THE PLATFORMS</S.H3>
        <p>In return for your agreeing to comply with these Terms you may:</p>
        <S.LatinList3>
          <li>
            download our Apps onto a device and view, use and display our Apps
            on the device for your personal non-commercial purposes only;
          </li>
          <li>
            receive updates to the software code of our Apps through either
            Google Play or the App Store, depending on the store from which you
            have downloaded the App – these may incorporate patches and
            corrections of errors as we may provide to you.
          </li>
        </S.LatinList3>
        <p>
          Your right to use our Apps is personal to you, you may not otherwise
          transfer our Apps to anyone else for any reason. If you sell or give
          away any device on which our Apps is installed, you must remove our
          Apps from it first.
        </p>
        <p>
          The ways in which you can use our Apps may also be governed by the
          terms and conditions of the store from which you downloaded the App.
          In the event of a conflict between these Terms and the terms and
          conditions of the store from which you downloaded our Apps, the terms
          and conditions of the store from which you downloaded our Apps shall
          prevail.
        </p>
        <p>
          You understand and agree that the Apps may require an updated
          operating system, operating system version, etc. to function
          correctly, and each App has its own technical requirements (including
          requirements for the operating system, its version, etc.). Please
          monitor the operating system for updates and update it to ensure the
          Apps works correctly. Make sure you are using the latest version of
          your web browser when accessing the Site, as that will help prevent
          security problems and ensure all the Site features work for you.
        </p>
        <p>
          You are responsible for all of your activity in connection with the
          Platforms and you shall abide by all local, state, national, and
          international laws and regulations and any applicable regulatory
          codes.
        </p>
        <S.H3>PROHIBITED USE OF OUR PLATFORMS</S.H3>
        <p>
          Unless you have requested and received our prior written consent or
          unless expressly permitted in these Terms, you must not:
        </p>
        <S.NestedLatinList>
          <li>
            a) copy, adapt, alter, modify or translate our Platforms in whole or
            in part, or combine or merge our Platforms with any other object
            code or program;
          </li>
          <li>
            b) reverse engineer, decompile, disassemble, reduce the object code
            of our Platforms to source code form or create (or attempt to
            create) derivative works based on the whole or any part of our
            Platforms, except to the extent permitted by applicable law, and
            provided that the information obtained by you during such
            activities:
            <S.PointList>
              <li>
                is not disclosed or communicated without our prior written
                consent to any third party to whom it is not necessary to
                disclose or communicate it to in accordance with applicable law;
                and
              </li>
              <li>
                is not used to create any software that is substantially similar
                in its expression to our Platforms.
              </li>
              <li>is kept secured; and</li>
              <li>is used only in accordance with applicable law;</li>
            </S.PointList>
          </li>
          <li>
            c) distribute, sublicense, assign, share, timeshare, sell, rent,
            lease, grant a security interest in, use for service bureau
            purposes, or otherwise transfer our Platforms or your right to use
            our Platforms;
          </li>
          <li>
            d) incorporate our Platforms into another service or website or make
            it available via framing or mirrors;
          </li>
          <li>
            e) extract any data or metadata from our Platforms nor create any
            index or database incorporating any part of it;
          </li>
          <li>
            f) use or distribute unauthorized software programs or tools, such
            as “auto” software programs, “macro” software programs, “cheat
            utility” software program or applications, exploits, cheats, or any
            other hacking, altering or cheating software or tool;
          </li>
          <li>
            g) do anything that may cause damage to our Platforms or carry out
            any harmful or illegal activities using our Platforms;
          </li>
          <li>
            h) use any robot, spider or other automated device or process to
            access Platforms for any purpose or copy any material;
          </li>
          <li>
            i) use our Platforms in any manner not expressly authorized by these
            Terms and for any commercial purpose or revenue-generating endeavor;
          </li>
          <li>
            j) publish, post, upload or distribute user content (it may be any
            material, information, data, or other content that is generated,
            created, uploaded, submitted, or otherwise provided by users. This
            content can include text, images, videos, audio recordings, files,
            comments, messages, and any other form of digital or multimedia
            content that users contribute to our Platforms hereinafter referred
            to as “User Content”) or content that is unlawful, harmful,
            threatening, abusive, harassing, tortious, excessively violent,
            defamatory, vulgar, obscene, pornographic, libelous, invasive of
            another’s privacy, hateful racially, ethnically, or otherwise
            objectionable, or in our sole judgment, is objectionable or which
            restricts or inhibits any other person from using or enjoying the
            Platforms, or which may expose us or our users to any harm or
            liability of any type and/or publish, post, upload, distribute or in
            any other way use content, the use of which or the content itself
            violates the intellectual and/or other rights of third parties;
          </li>
          <li>
            k) remove, modify, block, disable, obscure or impair any copyright,
            trademark, or other proprietary notices, material or advertising
            belonging to us, our licensors or other third parties contained
            within our Platforms or infringe any rights (including intellectual
            property rights) belonging to us or any third party in relation to
            your use of our Platforms.
          </li>
        </S.NestedLatinList>
        <S.H3>LIMITED LICENSE TO THE PLATFORMS</S.H3>
        <p>
          We grant you a personal, revocable, non-transferable,
          non-sublicensable, and non-exclusive right and license to access and
          use the Platforms, provided that you do not (and do not allow any
          third party to) copy, store, modify, distribute, transmit, perform,
          reproduce, publish, license, create derivative works from, reverse
          engineer, reverse assemble or otherwise attempt to discover any source
          code, sell, assign, sublicense, grant a security interest in, or
          otherwise transfer any right in the Platforms, except where permitted
          by applicable law. You agree not to access the Platforms by any means
          other than provided by us. Your use of the Platforms is limited to
          your personal, non-commercial use only.
        </p>
        <p>
          All rights, title, and interest in and to the Platforms, encompassing
          text, images, photos, audio, video, location data, software, code, and
          all other forms of data or communication provided by the Company
          within the Platforms, including visual interfaces, interactive
          features, graphics, design, compilation of User Content, are
          exclusively reserved by the Company. Any utilization of the Company’s
          software, title, trade name, trademark, service mark, logo, domain
          name, or any other identification with notable brand features or
          content owned by the Company requires prior written permission from
          the Company. Permission requests may be sent via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          . The App Content is solely permitted for use within our Apps and may
          not be enjoyed independently. Apart from the rights expressly granted
          in these Terms, no express or implied rights are conferred to you, and
          all rights to the Platforms remain retained by us.
        </p>
        <p>
          Unless otherwise expressly declared, any communications you send to us
          or publish in Google Play or App Store are deemed to be submitted on a
          non-confidential basis. You agree that we may decide to publicize such
          communications at our own discretion. You agree to authorize us to
          make use of such communications for free, and revise, modify, adjust
          and change it contextually, or make any other changes, in each case as
          we deem appropriate.
        </p>
        <S.H3>LICENSE TO USER CONTENT</S.H3>
        <p>
          You retain all rights to such User Content that you post, share, or
          log in the App. By providing your User Content to the Platforms, you
          grant the Company a non-exclusive, transferable, sublicensable,
          worldwide, royalty-free license to use, copy, exploit, modify,
          publicly display, publicly perform, create derivative works from,
          incorporate it into other works, change, reformat, and distribute User
          Content in connection with providing and operating the Platforms and
          related services and/or for the Company’s promotional purposes (for
          example, by displaying on our Site, within the Platforms, in social
          media, on any website or platform on the internet as we may deem
          appropriate), subject to the Privacy Policy.
        </p>
        <S.H3>WARRANTY DISCLAIMER</S.H3>
        <p>
          THE PLATFORMS ARE PROVIDED “AS IS”, “AS AVAILABLE” AND IS PROVIDED
          WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
          PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR
          USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, SAVE TO THE
          EXTENT REQUIRED BY LAW. NEITHER THE COMPANY, NOR ANY OF ITS OFFICERS,
          DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, REPRESENTATIVES, SUPPLIERS,
          PARTNERS, ADVERTISERS OR CONTENT PROVIDERS WARRANTS, AND EACH OF THEM
          HEREBY EXPRESSLY DISCLAIMS, THAT: (A) THE PLATFORMS WILL BE SECURE OR
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR
          ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR
          THROUGH THE PLATFORMS IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;
          (D) THE RESULTS OF USING THE PLATFORMS WILL MEET YOUR REQUIREMENTS. OR
          (E) THE ACCURACY, RELIABILITY, OR COMPLETENESS OF THE CONTENT, TEXT,
          IMAGES, SOFTWARE, GRAPHICS, OR COMMUNICATIONS PROVIDED BY THIRD
          PARTIES ON OR THROUGH THE APP, INCLUDING IN SECRET CHATS. YOUR USE OF
          THE APP IS SOLELY AT YOUR OWN RISK. THERE ARE SOME LOCATIONS WHERE
          APPS WITH PAID FOR IN-APP PURCHASING PRODUCTS ARE NOT PERMITTED AND
          THEREFORE IN THOSE LOCATIONS OUR APPS WILL NOT BE VISIBLE TO APP STORE
          USERS REGISTERED IN THOSE LOCATIONS. SOME STATES, COUNTRIES DO NOT
          ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO SOME OR ALL OF THE ABOVE
          LIMITATIONS MAY NOT APPLY TO YOU.
        </p>
        <S.H3>LIMITATION OF LIABILITY</S.H3>
        <p>
          UNDER NO CIRCUMSTANCES SHALL THE COMPANY, ITS OFFICERS, DIRECTORS,
          AGENTS, AFFILIATES, EMPLOYEES, REPRESENTATIVES, SUPPLIERS, PARTNERS,
          ADVERTISERS, OR DATA PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES ARISING FROM
          THE USE OR MISUSE OF THE PLATFORMS. THIS INCLUDES, BUT IS NOT LIMITED
          TO, LOSS OF USE, PROFITS, OR DATA, REGARDLESS OF WHETHER THE CLAIM IS
          BASED ON CONTRACT, TORT, EQUITY, OR OTHER LEGAL THEORIES. THE TOTAL
          LIABILITY OF THE COMPANY UNDER THESE TERMS OR RELATED TO THE USE OF
          THE PLATFORMS SHALL NOT EXCEED THE AMOUNTS PAID BY YOU FOR THE
          PLATFORMS USE, OR ONE HUNDRED DOLLARS ($100) IF NO PAYMENT OBLIGATIONS
          EXIST. WE WILL NOT BE LIABLE FOR DAMAGE THAT YOU COULD HAVE AVOIDED BY
          FOLLOWING OUR ADVICE TO APPLY AN UPDATE OFFERED TO YOU FREE OF CHARGE,
          OR FOR DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY FOLLOW
          INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE TECHNICAL
          REQUIREMENTS ADVISED BY US. IT’S IMPORTANT TO NOTE THAT SOME
          JURISDICTIONS MAY NOT PERMIT THE EXCLUSION OR LIMITATION OF LIABILITY,
          THUS THE AFOREMENTIONED LIMITATIONS MAY NOT APPLY TO YOU. FURTHERMORE,
          NEITHER THE COMPANY NOR ANY OF ITS OFFICERS, DIRECTORS, AGENTS,
          AFFILIATES, EMPLOYEES, REPRESENTATIVES, SUPPLIERS, PARTNERS,
          ADVERTISERS, OR CONTENT PROVIDERS SHALL BE LIABLE FOR ANY PERSONAL
          INJURY, INCLUDING DEATH, RESULTING FROM YOUR USE OR MISUSE OF THE
          PLATFORMS. VARIOUS STATES OR COUNTRIES MAY IMPOSE CONSTRAINTS ON THE
          LIMITATION OF LIABILITY. THIS AGREEMENT DOES NOT INTEND TO EXCLUDE OR
          LIMIT OUR LIABILITY WHERE IT WOULD BE UNLAWFUL TO DO SO. ANY PROVISION
          THAT ATTEMPTS TO EXCLUDE OR LIMIT LIABILITY BEYOND WHAT IS PERMITTED
          BY APPLICABLE LAW SHALL BE CONSTRUED TO LIMIT OUR LIABILITY TO THE
          MAXIMUM EXTENT ALLOWED BY LAW.
        </p>
        <S.H3>INDEMNITY</S.H3>
        <p>
          To the extent permitted by applicable law, you will indemnify, defend,
          and hold us, our subsidiaries, affiliates, related parties,
          successors, officers, agents, representatives, employees, contractors,
          partners, and licensors (“Indemnified Parties”) harmless from any
          claim or demand, including reasonable attorneys’ fees, made by any
          third party due to or arising out of:
        </p>
        <S.PointList>
          <li>your use or misuse of the Platforms;</li>
          <li>
            your violation of these Terms and/or other policies that may apply
            to you;
          </li>
          <li>your violation of applicable law;</li>
          <li>
            any misrepresentation made by you to any of the Indemnified Parties;
          </li>
          <li>your violation of the rights of any third party; and/or</li>
          <li>any disputes or issues between you and any third party.</li>
        </S.PointList>
        <p>
          We reserve the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us
          under these Terms, and you agree to cooperate with our defense of
          these claims. You agree not to settle any matter without our prior
          written consent. We will use reasonable efforts to notify you of any
          such claim, action, or proceeding upon becoming aware of it.
        </p>
        <S.H3>THIRD-PARTY WEBSITES</S.H3>
        <p>
          We sometimes link to third-party services (whether by way of
          advertisements or otherwise), e.g., when you register in our Platforms
          through social media etc. A few things to bear in mind:
        </p>
        <S.PointList>
          <li>we don’t endorse the websites and the services we link to;</li>
          <li>
            we’re not responsible for their content or liable for anything that
            happens to you if you use them;
          </li>
          <li>
            if you or anyone else shares something containing a link, we’re not
            responsible for anything on the site it links to;
          </li>
          <li>
            external websites and services usually have their own terms of use
            and privacy policies.
          </li>
        </S.PointList>
        <S.H3>YOUR PRIVACY</S.H3>
        <p>
          All personal data that we process in connection with your use of our
          Platforms is collected, stored, used, disclosed, and otherwise
          processed by us following our Privacy Policy. Cookie Policy describes
          the information we collect through the use of cookies and similar
          technologies, how and why we use cookies and similar technologies, how
          our third-party business partners use cookies and similar
          technologies, and ways you can opt out of using cookies and similar
          technologies.
        </p>
        <S.H3>SUPPORT</S.H3>
        <p>
          If you have any concerns regarding our Platforms, please contact us
          using the details provided in “ABOUT US” paragraph. If we have to
          contact you, we will do so by email, using the contact details you
          have provided to us. Providing a valid email address is the sole
          responsibility of the user. If an incorrect email address is
          specified, the user may be limited in access to the application, as
          well as in receiving response from Company’s support for objective
          reasons.
        </p>
        <p>
          We will use reasonable endeavors to ensure that our Platforms are
          available for download and use at all times. However, our Platforms
          are provided over the internet and mobile networks and so their
          operation and availability may be affected by factors outside of our
          control at any time for any reason. We do not guarantee that use of or
          access to our Platforms will always be available and/or uninterrupted.
        </p>
        <S.H3>ADVERTISING AND MONETISATION</S.H3>
        <p>
          You acknowledge that our Platforms may be supported by advertising
          revenues, and we may place advertising, promotions or sponsored
          content on our Platforms or in conjunction with the App Content. You
          acknowledge that we may not always identify advertising, promotions
          and sponsored content and the manner, mode and extent of such
          advertising, promotions and sponsored content is subject to change
          without notice to you. We may at our sole discretion provide the
          ability to pay to remove advertisements via the settings menu within
          our Platforms.
        </p>
        <S.H3>CHANGES TO OUR PLATFORMS</S.H3>
        <p>
          From time to time, we may automatically update our Platforms to
          improve performance, enhance functionality, reflect changes to the
          operating system or address security issues. Such updates may cause
          temporary interruptions. We do not guarantee proper performance and
          full functionality of the Platforms, if you turn off automatic
          updates. We cannot assume any liability or obligation for any
          interruption, modification, failure, delay, loss of Content or
          discontinuance within the Platforms due to such updates. Updates to
          our Apps will maintain compatibility with the operating system
          versions available on the App Store from which you initially
          downloaded the App.
        </p>
        <S.H3>CHANGES TO THESE TERMS</S.H3>
        <p>
          These Terms may only be modified with our prior written consent. We
          may alter or amend these Terms, including introducing new terms, that
          are:
        </p>
        <S.LatinList2>
          <li>the result of a change in applicable law or our business;</li>
          <li>necessary for the provision of our Platforms; or</li>
          <li>the result of any improvements to our Platforms.</li>
        </S.LatinList2>
        <p>
          If we make any changes (including any changes to our policies), we
          will give advance written notice to you via electronic communication
          within our Apps. If you notify us in writing within the thirty (30)
          days that you do not accept the change, these Terms will terminate
          immediately.
        </p>
        <p>
          However, if you continue to use our Platforms after providing such
          notice or after the thirty (30) day period, you are accepting these
          Terms as updated.
        </p>
        <p>
          We are under no obligation to notify you of any changes to these Terms
          that result in minor adjustments or corrections to these Terms.
        </p>
        <S.H3>NOTICE AND TAKEDOWN PROCEDURES</S.H3>
        <p>
          We are committed to responding to any alleged copyright violations,
          should they occur.
        </p>
        <p>
          If you believe that your content has been copied in a way that
          constitutes copyright infringement, you or your agent may submit a
          Digital Millennium Copyright Act («DMCA») notification to us. If we
          find that there has been an alleged or actual infringement, we will
          take appropriate action under the DMCA and other intellectual property
          laws.
        </p>
        <p>
          Alternatively, you can send the notice to us via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          &nbsp; or contact us by mail or post.
        </p>
        <p>
          Such notice must be in writing and contain the following information:
        </p>
        <S.PointList>
          <li>your address, telephone number, and e-mail address;</li>
          <li>
            a description of the copyrighted work or other intellectual property
            that you claim has been infringed (including infringing and source
            URL);
          </li>
          <li>
            information sufficient to permit us to locate the alleged infringing
            work. If possible, please provide links to the exact location so
            that we may quickly and more easily locate the material or link;
          </li>
          <li>
            include the following statement: «I have a good faith belief that
            use of the copyrighted materials described above as allegedly
            infringing is not authorized by the copyright owner, its agent, or
            the law»;
          </li>
          <li>
            include the following statement: «I swear, under penalty of perjury,
            that the information in the notification is accurate and that I am
            the copyright owner or am authorized to act on behalf of the owner
            of an exclusive right that is allegedly infringed»;
          </li>
          <li>
            an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright.
          </li>
        </S.PointList>
        <p>
          In case of any material breach, we may remove the content from
          Platforms, prevent access to it, terminate or block access for those
          responsible for such content, and/or any other action deemed
          appropriate.
        </p>
        <S.Text>Counter-Notice</S.Text>
        <p>
          If you believe that your content has been removed (or accessed is
          disabled or blocked) is not infringing, or that you have been
          permitted to upload and use such content by the copyright owner (or
          his agent), or as required by law, you may send a Counter-Notice,
          including the following information to us.
        </p>
        <S.PointList>
          <li>your name, address, telephone number, and e-mail address;</li>
          <li>
            description of the content that has been removed or to which access
            has been disabled;
          </li>
          <li>
            location at which the content appeared before it was removed or
            disabled;
          </li>
          <li>
            statement that you have a good faith belief that the content was
            removed or disabled as a result of a mistake or a misidentification
            of the content;
          </li>
          <li>
            statement that you consent to the jurisdiction of the federal court
            located within your place of residence, and a statement that you
            will accept service of process from the person who provided
            notification of the alleged infringement;
          </li>
          <li>your physical or electronic signature.</li>
        </S.PointList>
        <p>
          Once we receive a Counter-Notice, it will notify the original
          complaining party that it may replace the removed content or cease
          disabling it in 14 business days. We will replace your content or
          restore access to it within 10 to 14 business days at our sole
          discretion unless the copyright owner files an action seeking a court
          order.
        </p>
        <p>
          At our sole discretion, we may limit access to the Platforms as well
          as terminate the subscription or access of any user who infringes any
          intellectual property rights of us and our licensors or other users,
          if they are deemed as repeat infringers.
        </p>
        <S.H3>TERMINATION</S.H3>
        <p>
          These Terms apply from when you start to use our Platforms (the “
          <strong>Effective Date</strong>”) and will remain in full force and
          effect while you use our Platforms until terminated in accordance with
          this paragraph.
        </p>
        <p>
          We may terminate these Terms and your use of our Platforms, or suspend
          your use of our Platforms, immediately by written notice to you if:
        </p>
        <S.LatinList3>
          <li>
            we consider that you have used our Platforms in violation of these
            Terms;
          </li>
          <li>
            we believe there has been unauthorized access to our Platforms;
          </li>
          <li>
            we, at our sole discretion, decide to withdraw our Platforms
            (whether in whole or in part);
          </li>
          <li>
            we have a legal or regulatory obligation imposed on us, which
            impacts our ability to provide our Platforms;
          </li>
          <li>you requested the deletion of your Account / personal data;</li>
          <li>
            for any other reason provided that we have given you advance written
            notice by email, SMS or via an electronic communication within our
            Platforms.
          </li>
        </S.LatinList3>
        <p>
          We may discontinue licensing any of the App Content at any time at our
          sole discretion. In this instance you will be able to continue to use
          our Apps with the App Content, but it will no longer be available on
          app stores and will no longer be supported by us.
        </p>
        <p>
          You can terminate these Terms by ceasing to use our Platforms. If you
          purchased a subscription as specified in paragraph “SUBSCRIPTIONS”,
          you should also cancel your subscription directly.
        </p>
        <S.H3>CANCELLATION</S.H3>
        <S.H3>WEB CANCELLATION</S.H3>
        <p>
          Your subscription renews automatically at the end of each period until
          you cancel. You must cancel your subscription before it renews to
          avoid the billing of the fees for the next subscription period. If you
          purchased a subscription or enabled trial on our Site:
        </p>
        <S.PointList>
          <li>
            cancel your subscription purchased on our Site in your account:
            <S.NumericList>
              <li>
                Open the “
                <S.InnerLink to="https://account.meowz.com/login">
                  Web Profile
                </S.InnerLink>
                ”;
              </li>
              <li>Log into your Account;</li>
              <li>Navigate to the Subscriptions tab;</li>
              <li>Tap “Turn off auto-renewal”.</li>
            </S.NumericList>
          </li>
          <li>
            cancel your subscription purchased on our Site via sending a
            cancellation request via&nbsp;
            <S.InnerLink
              to={contactFormLink}
              aria-label="Link to Contact Form"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact form
            </S.InnerLink>
            .
          </li>
        </S.PointList>
        <S.H3>IN-APP CANCELLATION</S.H3>
        <p>
          Your subscription renews automatically at the end of each period until
          you cancel. You must cancel your Subscription before it renews to
          avoid the billing of the fees for the next subscription period.
        </p>
        <S.PointList>
          <li>
            if you purchased the subscription or enabled trial on the App Store,
            please, change subscription settings of your Account. To avoid being
            charged, cancel the subscription in your account settings at least
            24 hours before the end of the trial or the current subscription
            period. Learn more about managing subscriptions (and how to cancel
            them) on the&nbsp;
            <S.GreyLink
              href="https://support.apple.com/en-ca/HT202039"
              aria-label="Apple support page link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple support page
            </S.GreyLink>
            .
          </li>
          <li>
            if you purchased the subscription or enabled trial on Google Play,
            please, change subscription settings of your Account. To avoid being
            charged, cancel the subscription in your account settings at least
            24 hours before the end of the trial or the current subscription
            period. Learn more about managing subscriptions (and how to cancel
            them) on&nbsp;
            <S.GreyLink
              href="https://support.google.com/googleplay/answer/2479637?hl=en"
              aria-label="Google's support page link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google’s support page
            </S.GreyLink>
            .
          </li>
        </S.PointList>
        <p>
          IF YOU UNINSTALL THE APP, YOUR SUBSCRIPTION WILL NOT STOP
          AUTOMATICALLY. YOU MUST CANCEL THE AUTOMATIC RENEWAL OF YOUR
          SUBSCRIPTION IN ORDER NOT TO BE CHARGED WITH THE COST OF THE NEW
          SUBSCRIPTION
        </p>
        <S.H3>WITHDRAWAL</S.H3>
        <p>
          If you are a consumer based in the EEA or Switzerland, you have a
          legal right to withdraw from contracts for online purchases of
          services. When you purchase digital content online, even just one
          item, you’re agreeing that it’s available to you immediately. Because
          of this, you lose your right of withdrawal and will not be eligible
          for a refund. Similarly, if you subscribe to use our Platforms, which
          are available continuously, you’re agreeing that your use of our
          Platforms begins immediately upon purchase of the subscription.
        </p>
        <p>
          To exercise their right to withdraw, you must send us unequivocal
          statement of their intention to withdraw from the contract -{' '}
          <strong>NOVE8 LTD</strong>, Prodromos, 75, ONEWORLD PARKVIEW HOUSE,
          Floor 4, 2063, Nicosia, Cyprus, via&nbsp;
          <S.InnerLink
            to={contactFormLink}
            aria-label="Link to Contact Form"
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactFormLink}
          </S.InnerLink>
          &nbsp;in writing (e.g. by post or email). In order to comply with the
          withdrawal period, you must send your statement of withdrawal to us no
          less than 24 hours before the end of the withdrawal period. Unless you
          have lost your right of withdrawal, the withdrawal period will expire
          14 days after the day on which you entered into this contract. You may
          use the below model cancellation form, but it is not mandatory.
        </p>
        <p>
          “I/we* hereby withdraw from the contract concluded by me/us* for the
          purchase of the following goods*/the provision of the following
          service*
        </p>
        <p>Ordered on*/received on*</p>
        <p>Name of the consumer(s)</p>
        <p>Address of the consumer(s)</p>
        <p>Date</p>
        <p>*Delete as applicable.</p>
        <p>
          If you exercise your right of withdrawal, we will deduct from the
          amount refunded to you an amount proportionates to the amount consumed
          by you in connection with the use of our Platforms provided before you
          notified us of your withdrawal. Unless otherwise agreed with you,
          reimbursements will be made using the same means of payment as used to
          process the initial transaction.
        </p>
        <S.H3>SURVIVAL</S.H3>
        <p>
          Upon termination of these Terms, all provisions of this Agreement that
          by their nature, should survive termination, shall survive
          termination, including, without limitation, the provisions on dispute
          resolution and arbitration, all ownership provisions, warranty
          disclaimers, limitations of liability and indemnity.
        </p>
        <S.H3>OTHER PROVISIONS</S.H3>
        <p>
          No one other than a party to these Terms (namely, you or us) has any
          right to enforce any provision thereof.
        </p>
        <p>
          We may transfer our rights and obligations under these Terms to
          another company. We will always tell you in writing if this happens,
          and we will ensure that the transfer will not affect your rights under
          these Terms. You may only transfer your rights or your obligations
          under these Terms to another person if we agree in writing.
        </p>
        <p>
          These Terms ensure the benefit of the parties, including any of our
          successors in interest.
        </p>
        <p>
          If for any reason an arbitrator, an arbitral or other tribunal, or a
          court of competent jurisdiction finds any provision of these Terms, or
          any portion thereof, to be unenforceable, that provision shall be
          enforced to the maximum extent permissible so as to affect the intent
          of these Terms, and the remainder of these Terms shall continue in
          full force and effect. A printed version of these Terms shall be
          admissible in arbitral, judicial or administrative proceedings.
        </p>
        <p>
          Company and you shall attempt to resolve any dispute arising out of or
          relating to these Terms through negotiations. If the matter is not
          resolved by negotiation within 30 (thirty) days of receipt of a
          written ’invitation to negotiate’, the parties may attempt to resolve
          the dispute in good faith through an agreed Alternative Dispute
          Resolution (ADR) procedure, or in default of agreement, through an ADR
          procedure. If the matter has not been resolved by an ADR procedure
          within 60 (sixty) days of the initiation of that procedure, or if any
          party will not participate in an ADR procedure, the dispute may be
          referred to arbitration by any party. The European Commission has
          established an online platform for alternative dispute resolution
          (ODR) related to online sales and service contracts. Consequently, any
          European consumer or consumer based in Norway, Iceland, or
          Liechtenstein can use this platform to resolve disputes arising from
          contracts concluded online. The platform is available at the&nbsp;
          <S.InnerLink to="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN">
            following link
          </S.InnerLink>
          .
        </p>
        <p>
          These Terms are governed by the laws of England and Wales, excluding
          its rules on conflicts of law.
        </p>
        <p>
          If you are a resident of any European country or Switzerland, Norway
          or Iceland, the governing law and forum shall be the laws of your
          usual place of residence. Both parties irrevocably agree to the
          personal jurisdiction and venue of the courts of England and Wales,
          and waive any objections to the venue or the inconvenience of the
          forum. Nothing in these Terms affects the protection granted to you by
          the mandatory consumer protection laws of your country of residence.
          The Company will also bring any disputes to the competent court in
          your country of residence.
        </p>
        <p>
          NO CLASS OR REPRESENTATIVE ACTIONS OR PROCEEDINGS: TO THE FULLEST
          EXTENT PERMITTED UNDER APPLICABLE LAW, YOU AND THE COMPANY AGREE THAT
          EACH MAY BRING DISPUTES AGAINST THE OTHER ONLY IN YOUR OR ITS
          INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
        </p>
        <p>
          You recognize and agree that certain regions may periodically impose
          limitations on interactions with specific territories, governments, or
          individuals perceived to jeopardize global peace and stability or are
          subject to targeted measures. By using the Platforms, you affirm that
          you do not reside in, nor are situated within, any territory currently
          subjected to embargo by the United Nations, the European Union, the
          United Kingdom, or the United States, and that you are not otherwise
          prohibited from accessing the Platforms. You acknowledge and consent
          to our ability to adjust, restrict, or suspend your access to the
          Platforms at any time to ensure compliance with applicable
          regulations. This may involve limitations on Platforms availability in
          your country of residence. Furthermore, the Platform’s availability in
          your home country may cease due to the aforementioned regulatory
          constraints.
        </p>
      </S.Column>
    </S.Wrapper>
  )
}
