import styled from 'styled-components'

import { Color } from 'root-constants'

type TModalProps = {
  isVisible?: boolean
}

export const StyledAnalyzingAnswersModal = {
  Wrapper: styled.div<TModalProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: ${({ isVisible }) => (isVisible ? 2 : -1)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: 0.25s opacity;
    padding: 20px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: 320px;
    background: ${Color.BEIGE};
    border-radius: 20px;
    padding: 24px 20px 32px;
  `,
  Title: styled.h3`
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 32px;
    color: ${Color.PRIMARY_TEXT};
    font-style: normal;
    font-weight: 800;
  `,
  Option: styled.div`
    background: ${Color.WHITE};
    border-radius: 12px;
    padding: 15px;
    color: ${Color.PRIMARY_TEXT};
    width: 100%;
    border: 0;
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    input:checked + & {
      border: 2px solid ${Color.MEDIUM_GREEN};
      transition: 0.25s;
      pointer-events: none;
    }
  `,
}
