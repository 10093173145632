import styled from 'styled-components'
import { Button } from 'components/buttons/Button'
import { baseColumnStyles } from 'pages/styles'

export const StyledAccountCreationFailed = {
  Wrapper: styled.div`
    padding: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
  `,
  Container: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h2`
    margin: 24px 0 16px;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    max-width: 288px;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
