import React, { useCallback, useEffect } from 'react'
import promptSvg from 'assets/images/sprite/prompt.svg'
import promptPointer from 'assets/images/sprite/prompt-pointer.svg'
import { StyledModal as S } from './PaymentModal.styles'

type TProps = {
  isShown: boolean
  onClose: () => void
  topModalPosition?: string
}

export const PaymentModal: React.FC<TProps> = ({
  isShown,
  onClose,
  ...props
}) => {
  useEffect(() => {
    document.body.style.overflow = isShown ? 'hidden' : 'unset'
  }, [isShown])

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset'
    },
    [],
  )

  const handleOverlayClick = useCallback(
    (e) => {
      if (e.currentTarget === e.target) {
        onClose()
      }
    },
    [onClose],
  )

  return (
    <S.Wrapper
      isShown={isShown}
      onClick={(e) => {
        handleOverlayClick(e)
      }}
    >
      <S.Content {...props}>
        <S.SvgImage svg={promptSvg} />
        <S.Pointer svg={promptPointer} />
      </S.Content>
    </S.Wrapper>
  )
}
