import { Color } from 'root-constants'

export const MEOWZ_1_PROGRESS_BAR_CONFIG = [
  {
    title: 'progressBar.catPersonality',
    startIndex: 1,
    pages: 5,
    color: '#854EB3',
  },
  {
    title: 'progressBar.catBehavior',
    startIndex: 6,
    pages: 7,
    color: Color.MEDIUM_GREEN,
  },
  {
    title: 'progressBar.finishingTouches',
    startIndex: 13,
    pages: 5,
    color: '#4489F1',
  },
]

export const MEOWZ_2_PROGRESS_BAR_CONFIG = [
  {
    title: 'progressBar.catPersonality',
    startIndex: 2,
    pages: 4,
    color: '#854EB3',
  },
  {
    title: 'progressBar.catBehavior',
    startIndex: 6,
    pages: 7,
    color: Color.MEDIUM_GREEN,
  },
  {
    title: 'progressBar.finishingTouches',
    startIndex: 13,
    pages: 5,
    color: '#4489F1',
  },
]

export const COHORT_MAP = {
  meowz1: MEOWZ_1_PROGRESS_BAR_CONFIG,
  meowz2_1: MEOWZ_2_PROGRESS_BAR_CONFIG,
  meowz2_2: MEOWZ_1_PROGRESS_BAR_CONFIG,
}
