import { TDynamicPageComponent } from 'models/common.model'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IEmailPageProps } from 'modules/email/types'
import { PrivacyNote } from 'modules/email/pages/components/PrivacyNote'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { Input } from 'components/input/Input'
import { getFilteredEmailDomains } from 'modules/email/helpers'
import { selectIsFetching } from 'root-redux/common/common-selects'
import { generateRandomPlan } from 'helpers/generateRandomPlan'
import { StyledEmailVariant as S } from './Email.styles'
import { EmailWrapper } from '../EmailWrapper'
import { PageId } from '../../../constants/pages'

const EmailComponent: React.FC<IEmailPageProps> = ({
  email,
  onChange,
  onBlur,
  onSubmit,
}) => {
  const isFetching = useSelector(selectIsFetching)
  const { t } = useTranslation()
  const [emailHasFocus, setEmailHasFocus] = useState(false)
  const [isAutoCompleteShown, setIsAutoCompleteShown] = useState(false)
  const planNumber = useMemo(() => {
    const planFromStore = localStorage.getItem('planNumber')
    if (planFromStore) return planFromStore

    return generateRandomPlan()
  }, [])

  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isFetching,
    [email.isValid, email.value, isFetching],
  )

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(email.value),
    [email.value],
  )

  useEffect(() => {
    if (!localStorage.getItem('planNumber')) {
      localStorage.setItem('planNumber', planNumber.toString())
    }
  }, [planNumber])

  useEffect(() => {
    filteredEmailDomains.length && emailHasFocus
      ? setIsAutoCompleteShown(true)
      : setIsAutoCompleteShown(false)
  }, [email.isValid, email.value, emailHasFocus, filteredEmailDomains.length])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          Your training plan #MWZ-{planNumber}
          <br /> is ready
        </S.Title>
        <S.Text>Enter your email to get started</S.Text>
        <S.InputWrapper>
          <Input
            labelName={t`onboarding.email.email`}
            placeholder="goodboy@dogmail.com"
            value={email.value}
            isValid={email.isValid}
            validationText={email.validationText as string}
            name="email"
            type="email"
            autoComplete="off"
            onChange={onChange}
            onFocus={() => setEmailHasFocus(true)}
            onBlur={(e) => {
              onBlur(e)
            }}
          />
          {isAutoCompleteShown && (
            <S.AutoCompleteWrapper>
              {filteredEmailDomains.map((value) => (
                <S.AutoCompleteButton
                  type="button"
                  key={value}
                  value={value}
                  onClick={(e) => {
                    onChange({
                      target: {
                        value: (e.currentTarget as HTMLButtonElement).value,
                      },
                    })
                    setEmailHasFocus(false)
                  }}
                >
                  <S.AutoCompleteButtonTextWrapper>
                    {value}
                  </S.AutoCompleteButtonTextWrapper>
                </S.AutoCompleteButton>
              ))}
            </S.AutoCompleteWrapper>
          )}
        </S.InputWrapper>
        <S.Description>
          Your information is 100% secure. Meowz does not share your personal
          information with third parties.
        </S.Description>
        <S.StickyBlockWrapper>
          <GreenArrowButton
            isButtonDisabled={isButtonDisabled}
            onClick={onSubmit}
            text="Get My Plan"
          />
          <S.PrivacyNoteWrapper>
            <PrivacyNote />
          </S.PrivacyNoteWrapper>
        </S.StickyBlockWrapper>
      </S.Content>
    </S.Wrapper>
  )
}

const ComponentVariant: TDynamicPageComponent = () => (
  <EmailWrapper>{(props) => <EmailComponent {...props} />}</EmailWrapper>
)

ComponentVariant.hasHeader = true
ComponentVariant.progressBar = 'controlled'
ComponentVariant.id = PageId.EMAIL_MEOWZ
ComponentVariant.skipStepBack = true

export const EmailPage = ComponentVariant
