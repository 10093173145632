import React from 'react'
import { Option, OptionType } from 'components/option/Option'
import { StyledAnalyzingAnswersModal as S } from './AnalyzingAnswersModal.styles'
import { useTranslation } from 'react-i18next'

export const enum UserAnswer {
  VERY_INACTIVE = 'veryInactive',
  SOMEWHAT_ACTIVE = 'somewhatActive',
  VERY_ACTIVE = 'veryActive',
}

type TAnalyzingAnswersModal = {
  isModalVisible: boolean
  onChange?: (value: string) => void
  answers?: string[]
  setAnswers?: () => void
  children?: React.ReactNode
}

export const AnalyzingAnswersModal: React.FC<TAnalyzingAnswersModal> = ({
  isModalVisible,
  onChange,
  children,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper isVisible={isModalVisible}>
      <S.Content>
        <S.Title>{children}</S.Title>
        <Option
          type={OptionType.RADIO}
          value={UserAnswer.VERY_INACTIVE}
          {...rest}
          onChange={() => onChange && onChange(UserAnswer.VERY_INACTIVE)}
        >
          <S.Option>
            😴️&nbsp;{t`onboarding.prepareFlow.answers.inactive`}
          </S.Option>
        </Option>
        <Option
          type={OptionType.RADIO}
          value={UserAnswer.SOMEWHAT_ACTIVE}
          {...rest}
          onChange={() => onChange && onChange(UserAnswer.SOMEWHAT_ACTIVE)}
        >
          <S.Option>
            🤏🏽&nbsp;{t`onboarding.prepareFlow.answers.somewhatActive`}
          </S.Option>
        </Option>
        <Option
          type={OptionType.RADIO}
          value={UserAnswer.VERY_ACTIVE}
          {...rest}
          onChange={() => onChange && onChange(UserAnswer.VERY_ACTIVE)}
        >
          <S.Option>
            ⚡&nbsp;{t`onboarding.prepareFlow.answers.active`}
          </S.Option>
        </Option>
      </S.Content>
    </S.Wrapper>
  )
}
