import { IEventLogger, IEventLogParams } from 'models/events.model'
import { EventLoggerInstanceName, PlatformOS } from 'root-constants'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import dayjs from 'dayjs'
import { eventsApi } from 'api'

export class UserFlowTracker implements IEventLogger {
  public readonly name: EventLoggerInstanceName
  private readonly userId: string
  private cohort = ''
  private deviceType: PlatformOS = PlatformOS.UNKNOWN

  constructor({
    userId,
    instanceName,
  }: {
    userId: string
    instanceName: EventLoggerInstanceName
  }) {
    this.userId = userId
    this.name = instanceName
  }

  configure({ cohort }: { cohort: string }): void {
    this.cohort = cohort
    this.deviceType = getMobileOperatingSystem()
  }

  log({ event, eventProperty }: IEventLogParams): void {
    const timestampInSec = dayjs().unix()

    const mergedEventProperty = {
      cohort: this.cohort,
      user_id: this.userId,
      device_type: this.deviceType,
      timestamp: timestampInSec,
      ...eventProperty,
    }

    eventsApi.sendEvent(event, mergedEventProperty)
  }
}
