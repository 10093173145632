import firebase from 'firebase/compat/app'
import { useEffect } from 'react'

export const useGetRedirectResult = (
  callback: (token: string) => void,
  callbackIsDataLoading: (isLoading: boolean) => void,
  onError: (error: string) => void,
): void => {
  useEffect(() => {
    callbackIsDataLoading(true)

    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          return result.user.getIdToken()
        }
        return null
      })
      .then((token) => token && callback(token))
      .catch((error) => onError(error))
      .finally(() => callbackIsDataLoading(false))
  }, [callback, callbackIsDataLoading, onError])
}
