import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import adolescent from 'assets/images/sprite/adolescent-cat.svg'
import adult from 'assets/images/sprite/adult-cat.svg'
import kitten from 'assets/images/sprite/kitten.svg'
import senior from 'assets/images/sprite/senior-cat.svg'
import { PageId } from 'src/constants/pages'
import { saveUserAnswer } from 'root-redux/user/user-actions'
import { StyledQuestion as S } from './Questions.styles'
import { AGE_OPTIONS_VALUES } from '../../constants/options-constants'

const QUESTION = 'Choose your cat`s age'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        saveUserAnswer({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )
      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>Choose your cat&apos;s age</S.Title>
        <S.OptionsContainer data-is-multiple>
          <S.Option {...optionProps} value={AGE_OPTIONS_VALUES.KITTEN}>
            <S.QuestionButtonWithImg>
              <S.CatAgeImage svg={kitten} />
              <S.CatAgeText>Kitten</S.CatAgeText>
              <S.OptionSubText>Younger then 6 month</S.OptionSubText>
            </S.QuestionButtonWithImg>
          </S.Option>
          <S.Option {...optionProps} value={AGE_OPTIONS_VALUES.ADOLESCENT}>
            <S.QuestionButtonWithImg>
              <S.CatAgeImage svg={adolescent} />
              <S.CatAgeText>Adolescent Cat</S.CatAgeText>
              <S.OptionSubText>6-12 month</S.OptionSubText>
            </S.QuestionButtonWithImg>
          </S.Option>
          <S.Option {...optionProps} value={AGE_OPTIONS_VALUES.ADULT}>
            <S.QuestionButtonWithImg>
              <S.CatAgeImage svg={adult} />
              <S.CatAgeText>Adult Cat</S.CatAgeText>
              <S.OptionSubText>1-7 years</S.OptionSubText>
            </S.QuestionButtonWithImg>
          </S.Option>
          <S.Option {...optionProps} value={AGE_OPTIONS_VALUES.SENIOR}>
            <S.QuestionButtonWithImg>
              <S.CatAgeImage svg={senior} />
              <S.CatAgeText>Senior Cat</S.CatAgeText>
              <S.OptionSubText>More than 7 years</S.OptionSubText>
            </S.QuestionButtonWithImg>
          </S.Option>
        </S.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.CAT_AGE

export const AgeQuestion = Component
