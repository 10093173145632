import { AnyAction } from 'redux'

import { ISubscription } from 'models/suscriptions.model'
import { IPaymentConfig } from 'models/payment.model'
import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_IS_PAYMENT_FLOWS_SHOWN,
  SET_PAYMENT_CONFIG,
  SET_PAYMENT_INFO,
  SET_PAYMENT_SYSTEM,
  SET_PLANS_WAS_LOADED,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_PLANS,
  SET_SUCCESSFUL_PAYPAL_PAYMENT,
  SET_SUPPLY_TITLE,
} from './payment-actions'

export interface IPaymentState {
  selectedSubscription: ISubscription | null
  subscriptions: ISubscription[]
  subscriptionId: string
  subscriptionsPlansWasLoaded: boolean
  threeDSecureIframeURL: string
  isPaymentFlowsShown: boolean
  paymentConfig: IPaymentConfig | null
  paymentClientSecret: string
  paymentMethod: string
  trialPeriodDays: string
  successfulPaymentWithPaypal: boolean
  supplyTitle: string
}

const initState: IPaymentState = {
  selectedSubscription: null,
  subscriptions: [],
  subscriptionsPlansWasLoaded: false,
  threeDSecureIframeURL: '',
  isPaymentFlowsShown: false,
  paymentConfig: null,
  paymentClientSecret: '',
  subscriptionId: '',
  paymentMethod: '',
  trialPeriodDays: '',
  successfulPaymentWithPaypal: false,
  supplyTitle: '',
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscription: payload,
      }
    }
    case SET_SUBSCRIPTION_PLANS: {
      return {
        ...state,
        subscriptions: payload,
        subscriptionsPlansWasLoaded: true,
      }
    }
    case SET_PLANS_WAS_LOADED: {
      return {
        ...state,
        subscriptionsPlansWasLoaded: payload,
      }
    }
    case SET_IS_PAYMENT_FLOWS_SHOWN: {
      return {
        ...state,
        isPaymentFlowsShown: payload,
      }
    }
    case SET_PAYMENT_CONFIG: {
      return {
        ...state,
        paymentConfig: payload,
      }
    }
    case SET_PAYMENT_SYSTEM: {
      return {
        ...state,
        paymentMethod: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case SET_PAYMENT_INFO: {
      return {
        ...state,
        ...payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_SUCCESSFUL_PAYPAL_PAYMENT: {
      return {
        ...state,
        successfulPaymentWithPaypal: true,
      }
    }
    case SET_SUPPLY_TITLE: {
      return {
        ...state,
        supplyTitle: payload,
      }
    }
    default:
      return state
  }
}
